#publicationGridAg {
  height: calc(90% - 50px);
}

/* adds the border to the cell */
.ag-theme-balham .ag-cell {
  border-right: 1px solid lightgrey !important;
}

/* aligns the checkbox to center */
.ag-theme-balham .ag-selection-checkbox span {
  left: 10px;
}

/* added for picture packet pagination */
.preview-container .pagination {
  position: relative;
  bottom: 40px;
}