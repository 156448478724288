.left {
  float: left !important;
}
.right {
  float: right !important;
}
.jqx-widget-light {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #555;
}
.jqx-widget-content {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  border-color: #e9e9e9;
  z-index:0;
}

/* suraj add po date height */
.AddPODetails .jqx-max-size input {
  height:20px;
}

.topFilterPos {
  top: 60px !important;
}

.pd-hl {
  position: relative;
  width: 150px;
  display: none;
  z-index: 1001;
  background: #bfeaeb;
  padding: 0.5rem 0;
}

.jqx-enableselect.jqx-widget-content {
  border: none;
}
.jqx-widget-header-light {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  background: #fff;
  color: #555;
}
/*Rounded Corners*/
/*top-left rounded Corners*/
.jqx-rc-tl-light {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
}
/*top-right rounded Corners*/
.jqx-rc-tr-light {
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}
/*bottom-left rounded Corners*/
.jqx-rc-bl-light {
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
/*bottom-right rounded Corners*/
.jqx-rc-br-light {
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
/*top rounded Corners*/
.jqx-rc-t-light {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}
/*bottom rounded Corners*/
.jqx-rc-b-light {
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
/*right rounded Corners*/
.jqx-rc-r-light {
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
/*left rounded Corners*/
.jqx-rc-l-light {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
/*all rounded Corners*/
.jqx-rc-all-light {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.jqx-widget-header {
  background: #e1eeee;
}
.jqx-grid-cell-pinned {
  background-color: #ffffff;
}
.jqx-widget-light,
.jqx-widget-header-light,
.jqx-fill-state-normal-light,
.jqx-widget-content-light,
.jqx-fill-state-hover-light,
.jqx-fill-state-pressed-light {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
}
.jqx-widget-content-light {
  background-color: #ffffff;
  border-color: #dddddd;
}
.jqx-widget-header-light {
  color: #525e61;
  background-color: #e0eded;
  border-color: #dddddd;
  *zoom: 1;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.jqx-widget-header-light span {
  padding: 0rem 1rem;
}
.jqx-widget-header-light span span {
  padding: 0;
}
.jqx-button-light,
.jqx-fill-state-normal-light {
  color: #555;
  background: #fafafa;
  border-color: #dddddd;
  border-radius: 0;
  *zoom: 1;
}
.jqx-fill-state-normal-light {
  text-align: center;
}
.jqx-tree-grid-indent {
  vertical-align: middle !important;
}
.jqx-fill-state-hover {
  color: #1ca8dd;
  border-color: #b2b2b2;
  border-color: rgba(0, 0, 0, 0.3);
  background-color: #e1eeee;
}
.jqx-fill-state-pressed {
  background-color: #99daf3;
  border-color: #99daf3;
  *background-color: #fff;
}
.grid-cell-selected {
  background-color: #f7cc7f;
}
.editedRow {
  color: #b90f0f !important;
  font-style: italic;
}
.jqx-fill-state-hover-light,
.jqx-fill-state-focus-light {
  color: #333333;
  text-decoration: none;
}
.jqx-button-light {
  color: #555;
  border-color: #ddd;
  *zoom: 1;
}
.jqx-button-light.jqx-fill-state-hover {
  *zoom: 1;
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.jqx-button-light.jqx-fill-state-pressed {
  color: #fff;
  background: #1ca8dd;
  border-color: #1ca8dd;
  *zoom: 1;
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.jqx-dropdownlist-state-normal-light,
.jqx-dropdownlist-state-hover-light,
.jqx-dropdownlist-state-selected-light,
.jqx-scrollbar-button-state-hover,
.jqx-scrollbar-button-state-normal,
.jqx-scrollbar-button-state-pressed,
.jqx-scrollbar-thumb-state-normal-horizontal,
.jqx-scrollbar-thumb-state-hover-horizontal,
.jqx-scrollbar-thumb-state-pressed-horizontal,
.jqx-scrollbar-thumb-state-normal,
.jqx-scrollbar-thumb-state-pressed,
.jqx-tree-item-hover-light,
.jqx-tree-item-selected-light,
.jqx-tree-item-light,
.jqx-menu-item-light,
.jqx-menu-item-hover-light,
.jqx-menu-item-selected-light,
.jqx-menu-item-top-light,
.jqx-menu-item-top-hover-light,
.jqx-menu-item-top-selected-light,
.jqx-slider-button-light,
.jqx-slider-slider-light {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.jqx-primary-light {
  color: #1ca8dd  !important;
  background: #fff  !important;
  border-color: #1ca8dd!important;
  text-shadow: none !important;
}
.jqx-primary-light.jqx-dropdownlist-state-normal-light,
.jqx-primary-light.jqx-slider-button-light,
.jqx-primary-light.jqx-slider-slider-light,
.jqx-primary-light.jqx-combobox-arrow-normal-light,
.jqx-primary-light.jqx-combobox-arrow-hover-light,
.jqx-primary-light.jqx-action-button-light,
.jqx-primary-light:hover,
.jqx-primary-light:focus,
.jqx-primary-light:active,
.jqx-primary-light.active,
.jqx-primary-light.disabled,
.jqx-primary-light[disabled] {
  color: #fff  !important;
  background: #1ca8dd  !important;
  border-color: #1ca8dd !important;
  text-shadow: none !important;
}
.jqx-fill-state-pressed-light.jqx-primary-light,
.jqx-primary-light:active,
.jqx-primary-light.active {
  color: #fff  !important;
  background-color: #1ca8dd  !important;
  border-color: #1ca8dd!important;
  text-shadow: none !important;
}
.jqx-success-light {
  color: #5cb85c  !important;
  background: #fff  !important;
  border-color: #5cb85c!important;
  text-shadow: none !important;
}
.jqx-success-light.jqx-dropdownlist-state-normal-light,
.jqx-success-light.jqx-slider-button-light,
.jqx-success-light.jqx-slider-slider-light,
.jqx-success-light.jqx-combobox-arrow-normal-light,
.jqx-success-light.jqx-combobox-arrow-hover-light,
.jqx-success-light.jqx-action-button-light,
.jqx-success-light:hover,
.jqx-success-light:focus,
.jqx-success-light:active,
.jqx-success-light.active,
.jqx-success-light.disabled,
.jqx-success-light[disabled] {
  color: #fff  !important;
  background: #5cb85c  !important;
  border-color: #5cb85c!important;
  text-shadow: none !important;
}
.jqx-fill-state-pressed-light.jqx-success-light,
.jqx-success-light:active,
.jqx-success-light.active {
  text-shadow: none !important;
  color: #fff  !important;
  background: #5cb85c  !important;
  border-color: #5cb85c!important;
}
.jqx-inverse-light {
  text-shadow: none !important;
  color: #666  !important;
  background: #fff  !important;
  border-color: #cccccc!important;
}
.jqx-inverse-light.jqx-dropdownlist-state-normal-light,
.jqx-inverse-light.jqx-slider-button-light,
.jqx-inverse-light.jqx-slider-slider-light,
.jqx-inverse-light.jqx-combobox-arrow-hover-light,
.jqx-inverse-light.jqx-combobox-arrow-normal-light,
.jqx-inverse-light.jqx-action-button-light,
.jqx-inverse-light:hover,
.jqx-inverse-light:focus,
.jqx-inverse-light:active,
.jqx-inverse-light.active,
.jqx-inverse-light.disabled,
.jqx-inverse-light[disabled] {
  text-shadow: none !important;
  color: #666  !important;
  background: #cccccc  !important;
  border-color: #cccccc!important;
}
.jqx-fill-state-pressed-light.jqx-inverse-light,
.jqx-inverse-light:active,
.jqx-inverse-light.active {
  text-shadow: none !important;
  color: #666  !important;
  background: #cccccc  !important;
  border-color: #cccccc!important;
}
.jqx-danger-light {
  text-shadow: none !important;
  color: #d9534f  !important;
  background: #fff  !important;
  border-color: #d9534f!important;
}
.jqx-danger-light.jqx-dropdownlist-state-normal-light,
.jqx-danger-light.jqx-slider-button-light,
.jqx-danger-light.jqx-slider-slider-light,
.jqx-danger-light.jqx-combobox-arrow-hover-light,
.jqx-danger-light.jqx-combobox-arrow-normal-light,
.jqx-danger-light.jqx-action-button-light,
.jqx-danger-light:hover,
.jqx-danger-light:focus,
.jqx-danger-light:active,
.jqx-danger-light.active,
.jqx-danger-light.disabled,
.jqx-danger-light[disabled] {
  text-shadow: none !important;
  color: #fff  !important;
  background: #d9534f  !important;
  border-color: #d9534f!important;
}
.jqx-fill-state-pressed-light.jqx-danger-light,
.jqx-danger-light:active,
.jqx-danger-light.active {
  text-shadow: none !important;
  color: #fff  !important;
  background: #d9534f  !important;
  border-color: #d9534f!important;
}
.jqx-warning-light {
  text-shadow: none !important;
  color: #f0ad4e  !important;
  background: #fff  !important;
  border-color: #f0ad4e!important;
}
.jqx-warning-light.jqx-dropdownlist-state-normal-light,
.jqx-warning-light.jqx-slider-button-light,
.jqx-warning-light.jqx-slider-slider-light,
.jqx-warning-light.jqx-combobox-arrow-hover-light,
.jqx-warning-light.jqx-combobox-arrow-normal-light,
.jqx-warning-light.jqx-action-button-light,
.jqx-warning-light:hover,
.jqx-warning-light:focus,
.jqx-warning-light:active,
.jqx-warning-light.active,
.jqx-warning-light.disabled,
.jqx-warning-light[disabled] {
  text-shadow: none !important;
  color: #fff  !important;
  background: #f0ad4e  !important;
  border-color: #f0ad4e!important;
}
.jqx-fill-state-pressed-light.jqx-warning-light,
.jqx-warning-light:active,
.jqx-warning-light.active {
  text-shadow: none !important;
  color: #fff  !important;
  background: #f0ad4e  !important;
  border-color: #f0ad4e!important;
}
.jqx-info-light {
  text-shadow: none !important;
  color: #5bc0de  !important;
  background: #fff  !important;
  border-color: #5bc0de!important;
}
.jqx-info-light.jqx-dropdownlist-state-normal-light,
.jqx-info-light.jqx-slider-button-light,
.jqx-info-light.jqx-slider-slider-light,
.jqx-info-light.jqx-combobox-arrow-hover-light,
.jqx-info-light.jqx-combobox-arrow-normal-light,
.jqx-info-light.jqx-action-button-light,
.jqx-info-light:hover,
.jqx-info-light:focus,
.jqx-info-light:active,
.jqx-info-light.active,
.jqx-info-light.disabled,
.jqx-info-light[disabled] {
  color: #fff  !important;
  background: #5bc0de  !important;
  border-color: #5bc0de!important;
  text-shadow: none !important;
}
.jqx-fill-state-pressed-light.jqx-info-light,
.jqx-info-light:active,
.jqx-info-light.active {
  text-shadow: none !important;
  color: #fff  !important;
  background: #5bc0de  !important;
  border-color: #5bc0de!important;
}
.jqx-fill-state-pressed-light {
  background-image: none;
  outline: 0;
}
.jqx-grid-cell-pinned-light {
  background: #f1fafd;
}
.jqx-grid-cell-light {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0.8rem 1.2rem;
  color: #525e61;
  box-sizing: border-box;
}
.jqx-grid-column-menubutton-light {
  background-color: transparent;
  border-color: #ddd !important;
}
.jqx-cell-light {
  font-size: 14px;
}
.jqx-calendar-row-header-light,
.jqx-calendar-top-left-header-light {
  background-color: #f0f0f0;
  border: 0px solid #f2f2f2;
}
.jqx-calendar-column-header-light {
  background-color: #FFF;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #e9e9e9;
}
.jqx-expander-header-light {
  padding-top: 10px;
  padding-bottom: 10px;
}
.jqx-ribbon-header-vertical-light,
.jqx-widget-header-vertical-light {
  background: #f0f0f0;
  background: -ms-linear-gradient(left, #f8f8f8 0%, #eaeaea 100%);
  background: linear-gradient(left, #f8f8f8 0%, #eaeaea 100%);
}
.jqx-scrollbar-state-normal {
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
}
.jqx-scrollbar-thumb-state-normal,
.jqx-scrollbar-thumb-state-normal-horizontal {
  background: #f5f5f5;
  border-color: #b3b3b3;
}
.jqx-scrollbar-thumb-state-hover,
.jqx-scrollbar-thumb-state-hover-horizontal {
  background: #e6e6e6;
  border-color: #b3b3b3;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.jqx-progressbar-light {
  background: #f7f7f7 !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.jqx-progressbar-value-light,
.jqx-splitter-collapse-button-horizontal-light {
  background: #1ca8dd;
}
.jqx-splitter-collapse-button-vertical-light,
.jqx-progressbar-value-vertical-light {
  background: #1ca8dd;
}
.jqx-scrollbar-thumb-state-pressed,
.jqx-splitter-splitbar-vertical,
.jqx-splitter-splitbar-horizontal,
.jqx-scrollbar-thumb-state-pressed-horizontal,
.jqx-scrollbar-button-state-pressed {
  background: #d9d9d9;
  border-color: #b3b3b3;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.jqx-grid-column-sortdescbutton-light,
jqx-grid-column-filterbutton-light,
.jqx-grid-column-sortascbutton-light {
  background-color: transparent;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #ddd;
}
.jqx-menu-vertical-light {
  background: #ffffff;
  filter: none;
}
.jqx-checkbox-check-checked-light {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAcklEQVQY02NgwA/YoJgoEA/Es4DYgJBCJSBeD8SboRinBiYg7kZS2IosyQ/Eakh8LySFq4FYHFlxGRBvBOJYqMRqJMU+yApNkSRAeC0Sux3dfSCTetE0wKyXxOWhMKhTYIr9CAUXyJMzgLgBagBBgDPGAI2LGdNt0T1AAAAAAElFTkSuQmCC');
}
.jqx-checkbox-hover {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.jqx-combobox-content-light {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.25);
}
.jqx-grid-bottomright-light,
.jqx-panel-bottomright-light,
.jqx-listbox-bottomright-light {
  background-color: #f8f8f8;
}
.jqx-window-light,
.jqx-tooltip-light {
  box-shadow: 0 4px 23px 5px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0, 0, 0, 0.15);
}
.jqx-docking-light .jqx-window-light {
  box-shadow: none;
}
.jqx-docking-panel-light .jqx-window-light {
  box-shadow: none;
}
.jqx-radiobutton-light {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  background-repeat: no-repeat;
  background: none;
}
.jqx-radiobutton-light-light,
.jqx-radiobutton-hover-light {
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background-repeat: no-repeat;
}
.jqx-radiobutton-check-checked-light {
  filter: none;
  background: #1ca8dd;
  background-repeat: no-repeat;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.jqx-radiobutton-check-indeterminate-light {
  filter: none;
  background: #999;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.jqx-radiobutton-check-indeterminate-disabled-light {
  filter: none;
  background: #999;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.jqx-slider-track-horizontal-light,
.jqx-slider-track-vertical-light {
  border-color: #ddd;
  background: #f0f0f0;
}
.jqx-slider-button-light {
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
/*applied to a list item when the item is selected.*/
.jqx-listitem-state-hover-light,
.jqx-menu-item-hover-light,
.jqx-tree-item-hover-light,
.jqx-calendar-cell-hover-light,
.jqx-grid-cell-hover-light,
.jqx-menu-vertical-light .jqx-menu-item-top-hover-light,
.jqx-input-popup-light .jqx-fill-state-hover-light,
.jqx-input-popup-light .jqx-fill-state-pressed-light {
  color: #525e61 !important;
  border-color: #e3f5fb;
  text-decoration: none;
  background-color: #f8f9f9;
  background-repeat: repeat-x;
  outline: 0;
  background: #f8f9f9;
  /* Old browsers */
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-position: 0 0;
}
.jqx-scheduler-cell-hover-light {
  border-color: #e3f5fb !important;
  background: #f0f0f0 !important;
}
.jqx-grid-table-dark {
  font-size: 14px;
}
.jqx-listitem-state-selected-light,
.jqx-menu-item-selected-light,
.jqx-tree-item-selected-light,
.jqx-calendar-cell-selected-light,
.jqx-grid-cell-selected-light,
.jqx-menu-vertical-light .jqx-menu-item-top-selected-light,
.jqx-grid-selectionarea-light,
.jqx-input-button-header-light,
.jqx-input-button-innerHeader-light {
  color: #000 !important;
  background-color: #eff2f3;
  *background-color: #eff2f3;
  background-repeat: repeat-x;
  border-color: #e9e9e9 !important;
  background: #eff2f3;
  /* Old browsers */
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.jqx-scheduler-cell-selected-light {
  border-color: #1ca8dd !important;
  background: #1ca8dd !important;
}
.jqx-grid-cell-light .jqx-button-light,
.jqx-grid-cell-light .jqx-button-light.jqx-fill-state-hover-light,
.jqx-grid-cell-light .jqx-button-light.jqx-fill-state-pressed-light {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}
.jqx-popup-light {
  border: 1px solid #ddd;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}
.jqx-grid-column-sortascbutton-light,
.jqx-expander-arrow-bottom-light,
.jqx-window-collapse-button-light,
.jqx-menu-item-arrow-up-light,
.jqx-menu-item-arrow-up-selected-light,
.jqx-menu-item-arrow-top-up-light,
.jqx-icon-arrow-up-light,
.jqx-icon-arrow-up-hover-light,
.jqx-icon-arrow-up-selected-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-widget-light .jqx-grid-group-expand-light,
.jqx-grid-group-expand-light,
.jqx-grid-column-menubutton-light,
.jqx-grid-column-sortdescbutton-light,
.jqx-expander-arrow-top-light,
.jqx-window-collapse-button-collapsed-light,
.jqx-menu-item-arrow-down-light,
.jqx-menu-item-arrow-down-selected-light,
.jqx-menu-item-arrow-down-light,
.jqx-icon-arrow-down-light,
.jqx-icon-arrow-down-hover-light,
.jqx-icon-arrow-down-selected-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-tabs-arrow-left-light,
.jqx-menu-item-arrow-left-selected-light,
.jqx-menu-item-arrow-top-left,
.jqx-icon-arrow-left-light,
.jqx-icon-arrow-down-left-light,
.jqx-icon-arrow-left-selected-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-widget-light .jqx-grid-group-collapse-light,
.jqx-grid-group-collapse-light,
.jqx-tabs-arrow-right-light,
.jqx-menu-item-arrow-right-selected-light,
.jqx-menu-item-arrow-top-right-light,
.jqx-icon-arrow-right-light,
.jqx-icon-arrow-right-hover-light,
.jqx-icon-arrow-right-selected-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-menu-item-arrow-left-selected-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-menu-item-arrow-right-selected-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-input-button-content-light {
  font-size: 10px;
}
.jqx-widget .jqx-grid-column-header {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.jqx-widget .jqx-grid-cell,
.jqx-widget .jqx-grid-column-header,
.jqx-widget .jqx-grid-group-cell {
  border-color: #e9e9e9;
  font-size: 14px;
}
.jqx-widget .jqx-grid-cell {
  border: 1px solid #e9e9e9;
}
.jqx-grid-cell .jqx-combobox-content-light,
.jqx-grid-cell .jqx-input-light {
  border-color: #e9e9e9;
  color: #555555;
  background-color: #ffffff;
  padding: 0.8rem 1rem;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.jqx-combobox-content-light,
.jqx-combobox-light,
.jqx-combobox-state-normal-light {
  border-color: #ddd;
}
.jqx-combobox-content-focus-light,
.jqx-combobox-state-focus-light,
.jqx-fill-state-focus-light,
.jqx-numberinput-focus-light {
  outline: none;
  border-color: #959595;
}
input[type="text"].jqx-input-light,
input[type="password"].jqx-input-light,
input[type="text"].jqx-widget-content-light,
input[type="textarea"].jqx-widget-content-light,
textarea.jqx-input-light {
  font-size: 14px;
  padding: 0px;
  padding-left: 3px;
  padding-right: 3px;
  resize: none;
}
input[type="text"].jqx-input-light:-moz-placeholder,
input[type="text"].jqx-widget-content-light:-moz-placeholder,
input[type="textarea"].jqx-widget-content-light:-moz-placeholder,
textarea.jqx-input-light:-moz-placeholder {
  color: #999999;
}
input[type="text"].jqx-input-light:-webkit-input-placeholder,
input[type="text"].jqx-widget-content-light:-webkit-input-placeholder,
input[type="textarea"].jqx-widget-content-light:-webkit-input-placeholder,
textarea.jqx-input-light:-webkit-input-placeholder {
  color: #999999;
}
input[type="text"].jqx-input-light:-ms-input-placeholder,
input[type="text"].jqx-widget-content-light:-ms-input-placeholder,
input[type="textarea"].jqx-widget-content-light:-ms-input-placeholder,
textarea.jqx-input-light:-ms-input-placeholder {
  color: #999999;
}
.jqx-grid-cell .jqx-combobox-content-light,
.jqx-grid-cell .jqx-input-light {
  border-color: #ddd;
  color: #555555;
  background-color: #ffffff;
  width: 100% !important;
  height: 100% !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.jqx-combobox-content-light,
.jqx-combobox-light,
.jqx-combobox-state-normal-light {
  border-color: #ddd;
}
.jqx-combobox-content-focus-light,
.jqx-combobox-state-focus-light,
.jqx-fill-state-focus-light,
.jqx-numberinput-focus-light {
  outline: none;
  border-color: #1ca8dd;
}
.jqx-popup-light.jqx-fill-state-focus-light {
  outline: none;
  border-color: #ddd !important;
}
.jqx-datetimeinput-content,
.jqx-datetimeinput-container {
  overflow: visible !important;
}
.jqx-slider-rangebar-light {
  border-color: #1ca8dd;
  background: #1ca8dd;
}
.jqx-grid-cell-light.jqx-grid-cell-selected-light > .jqx-grid-group-expand-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-grid-cell-light.jqx-grid-cell-selected-light > .jqx-grid-group-collapse-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-grid-cell-light.jqx-grid-cell-selected-light > .jqx-grid-group-collapse-rtl-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-grid-cell-light.jqx-grid-cell-selected-light > .jqx-grid-group-expand-rtl-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-tabs-title-selected-top-light,
.jqx-tabs-selection-tracker-top-light {
  border-color: #ddd;
  border-bottom: 1px solid #fff;
  filter: none;
  background: #fff;
  color: #333 !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.jqx-tabs-title-selected-bottom-light,
.jqx-tabs-selection-tracker-bottom-light {
  border-color: #ddd;
  border-top: 1px solid #fff;
  filter: none;
  background: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.jqx-tabs-title-hover-top-light,
.jqx-tabs-title-hover-bottom-light,
.jqx-tabs-header-light {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.jqx-window-collapse-button-light {
  margin-top: 0px;
}
.jqx-window-collapse-button-collapsed-light {
  margin-top: 0px;
}
.jqx-layout-light {
  background-color: #cccccc;
}
.jqx-kanban-column-header-collapsed-light {
  background: -moz-linear-gradient(0deg, #f8f8f8 0%, #eaeaea 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #f8f8f8), color-stop(100%, #eaeaea));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(0deg, #f8f8f8 0%, #eaeaea 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(0deg, #f8f8f8 0%, #eaeaea 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(0deg, #f8f8f8 0%, #eaeaea 100%);
  /* ie10+ */
  background: linear-gradient(90deg, #f8f8f8 0%, #eaeaea 100%);
  /* w3c */
}
.jqx-calendar-light td {
  font-size: 14px;
}
.jqx-grid-cell-light.jqx-grid-cell-selected-light > .jqx-grid-group-expand-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-grid-cell-light.jqx-grid-cell-selected-light > .jqx-grid-group-collapse-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-grid-cell-light.jqx-grid-cell-selected-light > .jqx-grid-group-collapse-rtl-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-grid-cell-light.jqx-grid-cell-selected-light > .jqx-grid-group-expand-rtl-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-grid-group-collapse-light {
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.jqx-grid-group-collapse-rtl-light {
  padding-right: 0px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.jqx-grid-group-expand-light,
.jqx-grid-group-expand-rtl-light {
  padding-right: 0px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.jqx-icon-arrow-first-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-icon-arrow-last-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-icon-arrow-first-hover-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-icon-arrow-last-hover-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-icon-arrow-first-selected-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-icon-arrow-last-selected-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-tree-grid-collapse-button-light {
  margin-top: 1px;
}
.jqx-primary .jqx-icon-arrow-right-hover-light,
.jqx-warning .jqx-icon-arrow-right-hover-light,
.jqx-danger .jqx-icon-arrow-right-hover-light,
.jqx-success .jqx-icon-arrow-right-hover-light,
.jqx-info .jqx-icon-arrow-right-hover-light {
  box-sizing: border-box;
}
.productJqxTreeGrid .jqx-grid-group-collapse-light {
  margin-left: -3px;
}
.product-treegrid .jqx-grid .jqx-enableselect.jqx-widget-content {
  /*z-index: 998 !important;*/
  /*z-index: 9 !important;*/

}
.product-treegrid .jqx-grid .jqx-enableselect .jqx-widget-content {
  border: none;
}
.ui-datepicker {
  /*z-index: 9995 !important;*/
  /*z-index: 10 !important;*/
}
.footer-button {
  display: inline-block;
  color: #555;
  padding: 0.6rem 1.5rem;
  border: 1px solid #555;
  border-radius: 5px;
  background: transparent;
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.footer-button:disabled {
  background: #D7DCDD;
  color: #eee;
  border: 1px solid #D7DCDD;
}
.footer-button:not(:disabled):not(.active):hover {
  background: rgba(85, 85, 85, 0.5);
  color: #eee;
}
.footer-button.active {
  background: #555;
  color: #fff;
}
.record-creation-holder {
  position: relative;
  display: inline-block;
  padding: 1rem 4rem;
}
.record-creation-holder .record-action {
  cursor: pointer;
  user-select: none;
  position: relative;
}
.record-creation-holder .record-action:not(.open) + .record-options {
  display: none;
}
.record-creation-holder .record-action:after {
  content: '';
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #555555;
  position: absolute;
  top: 12px;
  right: 2px;
}
.record-creation-holder .record-action.open:after {
  content: '';
  border-top: 0px;
  border-bottom: 5px solid #ffffff;
}
.record-creation-holder .record-options {
  position: absolute;
  bottom: 100%;
  /*z-index: 999;*/
  /*z-index: 9;*/
  background: #fff;
  -webkit-box-shadow: 0 0 5px #525E61;
  -moz-box-shadow: 0 0 5px #525E61;
  box-shadow: 0 0 5px #525E61;
}
.record-creation-holder .record-options ul {
  width: 250px;
}
.record-creation-holder .record-options ul li {
  padding: 0.5rem 1rem;
  color: #525E61;
  cursor: pointer;
}
.record-delete-holder {
  margin-right: 2rem;
}
.total-row-count {
  display: inline-block;
  color: #166A8F;
}
.total-row-count .total-rows {
  font-weight: 400;
}
.total-row-count .selected-rows {
  font-weight: 300;
}
.error {
  display: none;
  color: red;
}
.error.invalid {
  display: block;
}
.modal-container .modal-content.add-record-files fieldset {
  padding: 1rem;
}
.modal-container .modal-content .modal-footer {
  text-align: center;
  margin: 1.5rem 0 0;
}
.modal-container .modal-content .modal-footer [type="button"],
.modal-container .modal-content .modal-footer [type="submit"] {
  font-size: 1.4rem;
  text-transform: uppercase;
  border-radius: 4px;
  border: solid 1px #555555;
  background-color: #fff;
  cursor: pointer;
  padding: 1rem 1.2rem;
  margin-left: 1.5rem;
}
.modal-container .modal-content .modal-footer [type="button"]:not(:disabled):hover,
.modal-container .modal-content .modal-footer [type="submit"]:not(:disabled):hover {
  background-color: #555555;
  color: #fff;
}
/*----------------------- Start: Vertical Image Viewer------------------------*/
.rowHolder {
  display:flex;
  flex-direction:row;
  text-align: center;
}
.vertical-image-holder-for-product .rowHolder .thumbnail-holder {
  display: inline-block;
  /*vertical-align: top;*/
  /*width: 20%;*/
  /*max-height: 300px;*/
  /*overflow: auto;*/
}
.vertical-image-holder-for-product .rowHolder .thumbnail-holder ul {
  margin: 0;
}
.vertical-image-holder-for-product .rowHolder .thumbnail-holder ul li {
  cursor: pointer;
  /*width: 60px;*/
  max-height: 60px;
  margin-bottom: 10px;
}
.vertical-image-holder-for-product .rowHolder .thumbnail-holder ul li img {
  /*max-width: 60px;*/
  /*max-height: 60px;*/
  height: 50px;
  width: 50px;
  border: 1px solid #cccccc;
}

.vertical-image-holder-for-product .rowHolder .main-image {
  display: inline-block;
  /*vertical-align: top;*/
  width: 70%;
  height: auto;
}


.upload-holder .upload-input {
  position: relative;
  height: 60px;
  padding-top: 1rem;
  text-align: center;
}
.upload-holder .upload-input .icon-holder {
  width: 50px;
  height: 50px;
  background: #F15A29;
  position: absolute;
  left: calc(50% - 100px);
  border-radius: 100%;
  top: 15px;
}
.upload-holder .upload-input .icon-holder:before {
  content: '';
  position: absolute;
  height: 23px;
  width: 2px;
  background: #fff;
  left: 24px;
  top: 13px;
}
.upload-holder .upload-input .icon-holder:after {
  content: '';
  width: 23px;
  height: 2px;
  background: #fff;
  display: inline-block;
  position: absolute;
  top: 24px;
  left: 13px;
}
.upload-holder .upload-input .icon-holder + input {
  position: absolute;
  /*z-index: 2;*/
  opacity: 0;
  width: 50px;
  height: 50px;
  left: calc(50% - 25px);
  top: 10px;
}
.upload-holder label.block {
  margin-top: 10px;
}
.upload-holder .upload-footer {
  padding-top: 2rem;
}
.upload-holder .upload-footer button {
  padding: 1rem 3rem;
}
/*----------------------- End: Vertical Image Viewer------------------------*/
/*--------------------------- Start : ViewPopup  ---------------------------*/
.modal-container.add-view-popup .modal-fields > label {
  text-transform: capitalize;
  display: block;
}
.modal-container.add-view-popup .modal-fields > input {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
}
.modal-container.add-view-popup .view-selection {
  padding: 2rem;
}
.modal-container.add-view-popup .view-selection > label {
  padding-right: 2rem;
}
.modal-container.add-view-popup .modal-footer {
  padding-top: 2rem;
}
/*---------------------------- End : ViewPopup  ---------------------------*/
/** ADD Copy Popup CSS **/
.addCopyDetails {
  /*.form-control{
    &.approved{
      background: lightgreen;
    }
    &.rejected{
      background:indianred;
    }
    &.notempty{
      background:lightgray;
    }
    &.submitted{
      background: lightyellow;
    }
  }
  .description{
    &.approved{
      .jqx-editor-toolbar{
        background: lightgreen;
      }
    }
    &.rejected{
      .jqx-editor-toolbar{
        background:indianred;
      }
    }
    &.notempty{
      .jqx-editor-toolbar{
        background:lightgray;
      }
    }
    &.submitted{
      .jqx-editor-toolbar{
        background: lightyellow;
      }
    } 
  }*/
}
.addCopyDetails .prodName {
  width: 80%;
  float: left;
  font-size: 16px;
  line-height: 1.80;
  text-align: left;
  color: #299da3;
}
.addCopyDetails .gray-button {
  text-transform: uppercase;
  margin: 0 5px;
  /*&.submitBtn:not([disabled]){
      background: #f55418;
      color: #fff;
    }*/
}
.addCopyDetails .gray-button.submitBtn:not([disabled]):not(.active):hover {
  background: #f55418;
  color: #fff;
}
.addCopyDetails h3 {
  margin: 0;
}
.addCopyDetails div.status {
  line-height: 1.80;
  text-align: right;
  font-size: 14px;
}
.addCopyDetails div.status.approved {
  color: #2dbd5f;
}
.addCopyDetails div.status.rejected {
  color: #f00000;
}
.addCopyDetails .addcopy-buyer-action {
  text-align: center;
  margin-top: 10px;
}
.addCopyDetails .addcopy-buyer-action input:first-child {
  margin-right: 10px;
}
.SampleModalPopup .modal-holder {
  width: 75% !important;
}
@media only screen and (min-width: 1224px) {
  .SampleModalPopup .modal-holder {
    width: 75% !important;
  }
}
@media only screen and (min-width: 1367px) {
  .SampleModalPopup .modal-holder {
    width: 65% !important;
  }
}
@media only screen and (min-width: 1523px) {
  .SampleModalPopup .modal-holder {
    width: 59% !important;
  }
}
.SampleModalPopup .ConfirmSubmitModal .modal-holder {
  width: 30% !important;
}
.SampleModalPopup .Sample-Modal-Container {
  position: relative;
}
.SampleModalPopup .Sample-Modal-Container .Sample-SimpleModal {
  position: absolute;
  /*z-index: 2000;*/
  /*z-index: 9;*/

  width: auto;
  bottom: 90px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 1%;
  left: 25%;
  box-shadow: 2px 2px 5px #555;
  display: none;
}
.SampleModalPopup .Sample-Modal-Container .Sample-SimpleModal.movesample {
  left: 58%;
}
.SampleModalPopup .Sample-Modal-Container .Sample-SimpleModal .field-data {
  margin: 10px 0 0;
  text-align: center;
  border-top: 1px solid #999;
  padding-top: 10px;
}
.SampleModalPopup .Sample-Modal-Container .Sample-SimpleModal h3 {
  color: #555;
}
.SampleModalPopup .Sample-Modal-Container .Sample-SimpleModal .cancel {
  text-decoration: underline;
  cursor: pointer;
}
.SampleModalPopup .Sample-Modal-Container .Sample-SimpleModal .sample-btn {
  word-wrap: break-word;
  background: #555;
  text-decoration: none;
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
  font-size: 13px;
  display: inline-block;
  font-size: 15px;
  text-align: center;
  border-radius: 10px;
  color: #fff;
}
.SampleModalPopup .Sample-Modal-Container .Sample-SimpleModal .sample-btn.generate-card {
  width: 120px;
}
.SampleModalPopup .Sample-Modal-Container .Sample-SimpleModal input {
  width: 50px;
}
.SampleModalPopup .Sample-Modal-Container .Sample-SimpleModal.open {
  display: block;
}
.SampleModalPopup .Sample-Modal-Container .Sample-SimpleModal select {
  padding: 5px;
  width: 90% !important;
}
.ConfirmSubmitModal .gray-button {
  margin: 0 5px;
}
.ConfirmSubmitModal .modal-holder {
  position: relative;
  top: 40%;
  width: 450px !important;
  -webkit-transform: translateY(-40%);
  -moz-transform: translateY(-40%);
  -ms-transform: translateY(-40%);
  -o-transform: translateY(-40%);
  transform: translateY(-40%);
}
.ConfirmSubmitModal .modal-footer {
  margin: 0 !important;
}
.AddPODetails select {
  width: 99% !important;
}
.AddPODetails .gray-button {
  margin: 5px;
}
.ProductGridPopup > .modal-holder {
  width: 95% !important;
}
.ProductGridPopup .productTreeGridPopup {
  width: 88%;
  display: inline-block;
  padding-left: 1%;
}
.ProductGridPopup .jqx-listbox.jqx-fill-state-focus {
  border-color: #e0eded;
}
/*End Css*/
.campaign-modal-container .modal-content .prod-info {
  margin: 0 0 10px 0;
  word-wrap: break-word;
}
.campaign-modal-container .modal-content .associated-events .event-list,
.campaign-modal-container .modal-content .add-events .event-list {
  max-height: 150px;
  overflow-y: auto;
}
.campaign-modal-container .modal-content .associated-events .event-list ul,
.campaign-modal-container .modal-content .add-events .event-list ul {
  margin: 0;
}
.campaign-modal-container .modal-content .associated-events .event-list ul li,
.campaign-modal-container .modal-content .add-events .event-list ul li {
  padding: 6px;
}
.campaign-modal-container .modal-content .associated-events .event-list {
  border: 1px solid #D7DCDD;
}
.campaign-modal-container .modal-content .associated-events .event-list .li-event {
  padding-left: 22px;
  position: relative;
}
.campaign-modal-container .modal-content .associated-events .event-list .li-event .close-icon {
  position: absolute;
  left: 3px;
  top: 5px;
  width: 15px;
  height: 15px;
  background: #2FACB2;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  cursor: pointer;
}
.campaign-modal-container .modal-content .associated-events .event-list .li-event .close-icon:after {
  content: '\D7';
  color: #fff;
}
.campaign-modal-container .modal-content .associated-events .event-list .li-event .close-icon.disabled {
  cursor: not-allowed;
}
.campaign-modal-container .modal-content .list-viewer-holder {
  position: static;
  width: 100%;
  height: auto;
  border-radius: unset;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 1px solid #D7DCDD;
}
.campaign-modal-container .modal-content .list-viewer-holder .search-field-holder {
  margin: 0;
  padding: 10px;
}
.campaign-modal-container .modal-content .list-viewer-holder .blue-checkbox.wt {
  padding-left: 20px;
  font-size: 13px;
}
.campaign-modal-container .modal-content .list-viewer-holder .blue-checkbox.wt:disabled {
  cursor: not-allowed;
}
.campaign-modal-container.view-mode .modal-content .associated-events .event-list,
.campaign-modal-container.view-mode .modal-content .add-events .event-list {
  background-color: #f1f1f1;
  cursor: not-allowed;
}
#options-tab .options-container {
  display: block;
  padding: 1rem;
  max-height: 167px;
  overflow-y: scroll;
}
#options-tab .options-list {
  margin-top: 1rem;
}
.grid-view-dropdown {
  position: relative;
  display: inline-block;
}
.grid-view-dropdown .selected-view,
.grid-view-dropdown .selected-presentation {
  user-select: none;
  cursor: pointer;
  padding-right: 4rem;
}
.grid-view-dropdown .selected-view:not(.open) + .availabel-list,
.grid-view-dropdown .selected-presentation:not(.open) + .availabel-list {
  display: none;
}
.grid-view-dropdown .selected-view:after,
.grid-view-dropdown .selected-presentation:after {
  content: '';
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #2FACB2;
  position: absolute;
  top: 0.6rem;
  right: 0;
}
.grid-view-dropdown .selected-view.open:after,
.grid-view-dropdown .selected-presentation.open:after {
  content: '';
  border-top: 0px;
  border-bottom: 5px solid #2FACB2;
}
.grid-view-dropdown .availabel-list {
  position: absolute;
  width: 200px;
  top: calc(100% + 2rem);
  background: #fff;
  border-radius: 8px;
  /*z-index: 9999; */
  /*z-index: 10; */

  left: -2rem;
  -webkit-box-shadow: 0 2px 4px 0 #c0c0c0;
  -moz-box-shadow: 0 2px 4px 0 #c0c0c0;
  box-shadow: 0 2px 4px 0 #c0c0c0;
}
.grid-view-dropdown .availabel-list ul {
  margin: 0;
  max-height: 400px;
  overflow: auto;
}
.grid-view-dropdown .availabel-list ul li {
  position: relative;
  border-bottom: 1px solid #f4f6f7;
  padding: 1rem 2.3rem 1rem 2rem;
  cursor: pointer;
  word-break: break-all;
}
.grid-view-dropdown .availabel-list ul li.new-view {
  color: #15567c;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
}
.grid-view-dropdown .availabel-list ul li.personal-view {
  background-color: #e8ecec;
}
.grid-view-dropdown .availabel-list ul li:hover .icon-close {
  display: block;
}
.grid-view-dropdown .availabel-list ul li .icon-close {
  display: inline-block;
  width: 11px;
  height: 11px;
  position: relative;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  right: 1.5rem;
  background: transparent;
  top: 35%;
  display: none;
}
.grid-view-dropdown .availabel-list ul li .icon-close:before {
  content: '';
  width: 11px;
  height: 1px;
  background: #525e61;
  display: inline-block;
  position: absolute;
  top: 5px;
  left: 0;
}
.grid-view-dropdown .availabel-list ul li .icon-close:after {
  content: '';
  position: absolute;
  height: 11px;
  width: 1px;
  background: #525e61;
  left: 5px;
  top: 0;
}
.grid-view-dropdown .availabel-list ul li.selected {
  cursor: default;
  opacity: 0.7;
}
.grid-view-dropdown .availabel-list ul li:last-child {
  border-bottom: 0px;
}
.filter-strip-holder {
  height: 42px;
  background-color: #e7eaea;
  overflow-y: auto;
  overflow-x: overlay;
  width: 100%;
}
.filter-strip-holder::-webkit-scrollbar {
  height: 5px !important;
}
.filter-strip-holder .filter-strip {
  margin: 0;
  padding: 0 1.5rem;
  white-space: nowrap;
}
.filter-strip-holder .filter-strip li {
  position: relative;
  display: inline-block;
  padding: 0.3rem 3rem 0.3rem 1rem;
  background-color: #e6ebeb;
  border: solid 1px #c7cecf;
  border-radius: 100px;
  color: #525E61;
  margin: 5px;
}
.filter-strip-holder .filter-strip li .remove {
  display: inline-block;
  width: 11px;
  height: 11px;
  position: relative;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}
.filter-strip-holder .filter-strip li .remove:before {
  content: '';
  width: 11px;
  height: 1px;
  background: #525E61;
  display: inline-block;
  position: absolute;
  top: 5px;
  left: 0;
}
.filter-strip-holder .filter-strip li .remove:after {
  content: '';
  position: absolute;
  height: 11px;
  width: 1px;
  background: #525E61;
  left: 5px;
  top: 0;
}
.highlight-text {
  background-color: yellow;
}
.highlight-text.jqx-grid-cell-selected {
  background-color: orange;
}
.highlight-text-focus {
  background-color: orange;
}
.find-section {
  position: absolute;
  right: 95px;
  display: none;
  top: 50px;
  z-index: 999;
  width: 250px;
}
.find-section .find-box {
  float: left;
  background-color: #bfeaeb;
  padding: 10px;
  width: 100%;
}
.find-section .find-box .find-content {
  float: left;
  width: 70%;
  position: relative;
}
.find-section .find-box .find-content input {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 12px;
  height: 30px;
  border: solid 1px #D7DCDD;
  box-shadow: 0 2px 3px 0 rgba(217, 217, 217, 0.5);
  border-radius: 2px;
  padding: 7px 23px 7px 7px;
}
.find-section .find-box .find-content label {
  position: absolute;
  right: 2px;
  top: 7px;
}
.find-section .find-box .find-controls {
  width: 30%;
  float: right;
  text-align: right;
  padding-top: 4px;
}
.find-section .find-box .find-controls span {
  float: left;
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
}
.find-section .find-box .find-controls span .up {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}
.find-section .find-box .find-controls span .down {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}
.find-section.open {
  display: block;
}
.find-section .suggestion {
  clear: both;
  background: #ffffff;
  padding: 0 5px;
  border: 1px solid #cccccc;
  max-height: 250px;
  overflow: auto;
}
.find-section .suggestion li {
  padding: 5px 10px;
  line-height: 23px;
  font-size: 12px;
  cursor: pointer;
}
.find-section .suggestion li:hover {
  background: #cccccc;
}
.header-checkbox {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #ddd;
  padding: 0 !important;
  position: absolute;
  margin-left: -11px;
  left:15%;
  /*z-index: 4;*/
}
.header-checkbox.checked {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAcklEQVQY02NgwA/YoJgoEA/Es4DYgJBCJSBeD8SboRinBiYg7kZS2IosyQ/Eakh8LySFq4FYHFlxGRBvBOJYqMRqJMU+yApNkSRAeC0Sux3dfSCTetE0wKyXxOWhMKhTYIr9CAUXyJMzgLgBagBBgDPGAI2LGdNt0T1AAAAAAElFTkSuQmCC);
  background-position: center;
  float: left;
  padding: 0px;
  outline: none;
  background-repeat: no-repeat;
}
.col-header-grey {
  color: #999;
}
.product-image {
  text-align: center;
}
.product-image img {
  /*width: 30px;*/
  height: auto;
  vertical-align: middle;
}
/*------------------------ Start: header-dropdowns --------------------------*/
/*-------------------------- End: header-dropdowns --------------------------*/
.grid-empty-message {
  color: #525E61;
  text-align: center;
  margin: 15% 0;
  font-size: 30px;
}
.product-image.empty {
  width: 120px;
  height: 20px;
  background-color: white;
}
.addPO {
  cursor: pointer;
  text-decoration: underline;
}
.product-treegrid {
  width: 100%;
  float: left;
  position: relative;
}
.product-treegrid .jqx-grid-pager {
  display: none;
}
.product-treegrid .jqx-grid-cell.approved {
  background: #2FACB2;
}
.product-treegrid .jqx-grid-cell.rejected {
  background: #f9806f;
}
.product-treegrid .jqx-grid-cell.notempty {
  background: #dddddd;
}
.product-treegrid .jqx-grid-cell.submitted {
  background: #f8e984;
}
.product-treegrid .nonedit {
  background: #f15a29;
}
.product-treegrid .disabledReadyToOrder + label {
  opacity: 0.5;
}
.product-treegrid .productJqxTreeGrid {
  width: 99.5%;
}
.product-treegrid .jqx-input {
  padding: 2px;
}
#gridmenujqxgrid {
  display: none !important;
}
/** Filter By Css Start **/
.filter-by-container {
  width: 150px;
  position: absolute;
  top: 80px;
  /*z-index: 9999;*/
  /*z-index: 10;*/

  background: #e9e9e9;
  padding: 5px;
  border: 1px solid #cccccc;
}
.filter-by-container input[type="text"] {
  width: 100%;
}
.filter-by-container input[type="checkbox"] {
  padding-right: 5px;
}
.filter-by-container ul {
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
}
.filter-by-container ul li {
  list-style: none;
}
/** Image Approval **/
.product-pictures .thumbnail-holder {
  width: 17%;
  margin-right: 4%;
  float: left;
  max-height: 350px;
  overflow-y: auto;
}
.product-pictures .thumbnail-holder ul {
  margin: 0;
}
.product-pictures .thumbnail-holder ul li {
  margin-bottom: 1rem;
}
.product-pictures .thumbnail-holder ul li .thumbnail-image {
  width: 80px;
}
.product-pictures .thumbnail-holder ul li .thumbnail-image.dam {
  width: auto;
  max-width: 50px;
}
.product-pictures .thumbnail-holder ul li img {
  /*height: auto;*/
}
.product-pictures .thumbnail-holder ul li .image-checkbox {
  position: relative;
  margin-left: -12px;
  float: right;
}
.product-pictures .main-image {
  display: inline-block;
  margin: 0;
  position: relative;
  width: 78%;
}
.product-pictures .main-image .image-container {
  text-align: center;
}
.product-pictures .main-image .image-container img {
  width: 90%;
}
.product-pictures .main-image .image-container .image-checkbox {
  float: right;
}
.product-pictures .main-image .images-action {
  text-align: center;
}
.product-pictures .main-image .images-action input[type="button"] {
  margin-right: 3rem;
}
.product-pictures .main-image .comment-section {
  width: 90%;
}
.product-pictures .main-image .comment-section .texrarea-fld {
  height: 60px !important;
}
.productImageContainer {
  position: relative;
  height: auto;
  text-align: center;
  min-height: 200px;
  min-width: 200px;
}
.productImageContainer .closeIcon {
  position: absolute;
  right: -7px;
  top: -10px;
  color: #808080;
  font-weight: bold;
  cursor: pointer;
  width: 20px;
  background-size: 20px;
}
.productImageContainer img {
  height: 250px;
  width: 270px;
  object-fit: contain;
}

.ag-react-container img{
  object-fit: contain;
}
.addCopyDetails .description .jqte_editor {
  line-height: 1.5;
}
.addCopyDetails .description .jqte_editor div,
.addCopyDetails .description .jqte_editor p {
  margin-bottom: 0;
}
.modal-form .form-container.no-scrollbar-bottom {
  overflow: hidden;
}
.jqx-scrollbar-button-state-normal {
  /*z-index: 999;*/
  /*z-index: 9;*/

}
.product-treegrid .jqx-clear.jqx-position-absolute.jqx-border-reset.jqx-grid-bottomright.jqx-scrollbar-state-normal {
  display: none !important;
}
.jqte b,
.jqte strong {
  font-weight: 700;
}
.left {
  float: left !important;
}
.right {
  float: right !important;
}
.packet-details .record-creation-holder {
  position: relative;
  display: inline-block;
  padding: 1rem 11.1rem;
}
.packet-details .record-creation-holder .record-action {
  cursor: pointer;
  user-select: none;
  position: relative;
  padding: 8px 44px 8px 45px;
}
.packet-details .record-creation-holder .record-action:not(.open) + .record-options {
  display: none;
}
.packet-details .record-creation-holder .record-action:after {
  content: '';
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #555555;
  position: absolute;
  top: 12px;
  right: 2px;
}
.packet-details .record-creation-holder .record-action.open:after {
  content: '';
  border-top: 0px;
  border-bottom: 5px solid #ffffff;
}
.packet-details .record-creation-holder .record-options {
  position: absolute;
  bottom: 100%;
  /*z-index: 999;*/
  /*z-index: 9;*/

  background: #fff;
  -webkit-box-shadow: 0 0 5px #525E61;
  -moz-box-shadow: 0 0 5px #525E61;
  box-shadow: 0 0 5px #525E61;
}
.packet-details .record-creation-holder .record-options ul {
  width: 250px;
}
.packet-details .record-creation-holder .record-options ul li {
  padding: 0.5rem 1rem;
  color: #525E61;
  cursor: pointer;
}
.packet-details textarea {
  outline: none;
}
.preview-container .preview-holder {
  position: relative;
  height: 100%;
  overflow-y: hidden;
}
.preview-container .preview-holder .preview {
  height: 100%;
}
.preview-container .pagination li {
  float: left;
  margin-right: 10px;
}
.preview-container .pagination li a {
  cursor: pointer;
  outline: none;
}
.preview-container .pagination li.active a {
  font-weight: bold;
  outline: none;
}
.preview-container .pagination li.disabled {
  font-weight: normal !important;
}
.preview-container .pagination li.previous {
  font-weight: bold;
}
.preview-container .pagination li.next {
  font-weight: bold;
}
.error-message.text-left {
  text-align: left !important;
}
.packet-preview .confirm.open .modal-holder {
  max-width: 600px;
}

.grid-view-dropdown {
  position: relative;
  display: inline-block;
}
.grid-view-dropdown .selected-view,
.grid-view-dropdown .selected-presentation {
  user-select: none;
  cursor: pointer;
  padding-right: 4rem;
}
.grid-view-dropdown .selected-view:not(.open) + .availabel-list,
.grid-view-dropdown .selected-presentation:not(.open) + .availabel-list {
  display: none;
}
.grid-view-dropdown .selected-view:after,
.grid-view-dropdown .selected-presentation:after {
  content: '';
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #2FACB2;
  position: absolute;
  top: 0.6rem;
  right: 0;
}
.grid-view-dropdown .selected-view.open:after,
.grid-view-dropdown .selected-presentation.open:after {
  content: '';
  border-top: 0px;
  border-bottom: 5px solid #2FACB2;
}
.grid-view-dropdown .availabel-list {
  position: absolute;
  width: 200px;
  top: calc(100% + 2rem);
  background: #fff;
  border-radius: 8px;
  /*z-index: 9999;*/
  /*z-index: 10;*/

  left: -2rem;
  -webkit-box-shadow: 0 2px 4px 0 #c0c0c0;
  -moz-box-shadow: 0 2px 4px 0 #c0c0c0;
  box-shadow: 0 2px 4px 0 #c0c0c0;
}
.grid-view-dropdown .availabel-list ul {
  margin: 0;
  max-height: 400px;
  overflow: auto;
}
.grid-view-dropdown .availabel-list ul li {
  position: relative;
  border-bottom: 1px solid #f4f6f7;
  padding: 1rem 2.3rem 1rem 2rem;
  cursor: pointer;
  word-break: break-all;
}
.grid-view-dropdown .availabel-list ul li.new-view {
  color: #15567c;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
}
.grid-view-dropdown .availabel-list ul li.personal-view {
  background-color: #e8ecec;
}
.grid-view-dropdown .availabel-list ul li:hover .icon-close {
  display: block;
}
.grid-view-dropdown .availabel-list ul li .icon-close {
  display: inline-block;
  width: 11px;
  height: 11px;
  position: relative;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  right: 1.5rem;
  background: transparent;
  top: 35%;
  display: none;
}
.grid-view-dropdown .availabel-list ul li .icon-close:before {
  content: '';
  width: 11px;
  height: 1px;
  background: #525e61;
  display: inline-block;
  position: absolute;
  top: 5px;
  left: 0;
}
.grid-view-dropdown .availabel-list ul li .icon-close:after {
  content: '';
  position: absolute;
  height: 11px;
  width: 1px;
  background: #525e61;
  left: 5px;
  top: 0;
}
.grid-view-dropdown .availabel-list ul li.selected {
  cursor: default;
  opacity: 0.7;
}
.grid-view-dropdown .availabel-list ul li:last-child {
  border-bottom: 0px;
}

/* sets the product grid to z-index 0 vinay */
#columntablejqxgrid {
  z-index: 0;
}

#horizontalScrollBarjqxgrid {
  visibility: visible;
  height: 13px;
  top: 0px;
  left: 0px;
  width: 1860px;  
}

/*
.right-content .right-content-container .drawer.disabled {
  background-color: #D7DCDD;
  cursor: not-allowed;
}
.right-content .right-content-container .drawer.disabled .puller {
  background-color: #D7DCDD;
  cursor: not-allowed;
}
.right-content .right-content-container .drawer.disabled .puller .pane-arrow {
  opacity: 0.4;
}
.right-content .right-content-container .drawer.presentation-drawer {
  right: 0;
  width: 15px;
  background: #fff;
  z-index: 999;
}
.right-content .right-content-container .drawer.presentation-drawer .puller {
  left: 0;
  right: auto;
}
.right-content .right-content-container .drawer.presentation-drawer .puller .pane-arrow {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  left: 0;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content {
  display: none;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content {
  padding: 0rem 2rem 1rem;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content a.save,
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content a.clear {
  text-transform: uppercase;
  color: #555;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content a.save:hover,
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content a.clear:hover {
  color: #000;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .saved-list {
  margin-bottom: 1.5rem;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .saved-list .grid-view-dropdown {
  display: block;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .saved-list .grid-view-dropdown .selected-presentation {
  word-break: break-all;
  padding: 6px 10px;
  padding-right: 2.5rem;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 1.5rem;
  position: relative;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .saved-list .grid-view-dropdown .selected-presentation:after {
  right: 1rem;
  top: 1.1rem;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .saved-list .grid-view-dropdown .availabel-list {
  width: 100%;
  top: 3rem;
  left: 0;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .saved-list-actions label {
  font-size: 1.2rem;
  text-transform: uppercase;
  float: left;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .saved-list-actions .save {
  font-size: 1.2rem;
  float: right;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container {
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  margin: 1rem 0;
  padding-top: 1rem;
  position: relative;
  min-height: 250px;
}
@media only screen and (min-height: 800px) {
  .right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container {
    min-height: 400px;
  }
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .clear {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 1.2rem;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .element-list {
  list-style: none;
  max-height: 205px;
  overflow-y: auto;
  margin: 0;
}
@media only screen and (min-height: 800px) {
  .right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .element-list {
    min-height: 350px;
    max-height: 350px;
  }
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .element-list .list-item {
  padding: 1rem;
  font-size: 1.3rem;
  text-transform: uppercase;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .footer {
  margin: 1rem 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: auto;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .footer .selected,
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .footer .total {
  text-align: center;
  width: 50%;
  font-size: 1.2rem;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .footer .selected {
  display: block;
  width: 100%;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .footer .total {
  float: right;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-actions button {
  display: block;
  margin: 0 auto;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .panel-bottom {
  position: absolute;
  left: 2rem;
  right: 2rem;
  bottom: 1.5rem;
  max-height: 300px;
  overflow-y: auto;
  z-index: 10;
  background-color: #f7f7f7;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .panel-bottom .panel-accordions {
  margin-bottom: 1.5rem;
  max-height: 180px;
  overflow-y: auto;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .panel-bottom .panel-accordions .panel {
  margin: 0;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .panel-bottom .panel-accordions .panel .panel-content {
  padding-top: 0;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .panel-bottom button {
  display: block;
  margin: 0 auto;
}
.right-content .right-content-container .drawer.presentation-drawer-open {
  width: 300px;
}
.right-content .right-content-container .drawer.presentation-drawer-open .puller .pane-arrow {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.right-content .right-content-container .drawer.presentation-drawer-open .presentation-panel-content {
  display: block;
}
.right-content .right-content-container .content-wrapper {
  margin-right: 15px;
  width: calc(100% - 15px - 15px);
}
.right-content .right-content-container .content-wrapper.presentation-drawer-open {
  width: calc(100% - 15px - 300px);
  margin-right: 300px;
}
.right-content .right-content-container .content-wrapper.presentation-drawer-open .footer {
  width: calc(100% - 100px - 15px - 300px);
}
.right-content .right-content-container .content-wrapper.publication .publication-treegrid {
  float: left;
  width: 100%;
}
.right-content .right-content-container .content-wrapper.publication .publication-treegrid .header-checkbox {
  margin: 0;
}
.right-content .right-content-container .content-wrapper.publication .publication-treegrid .product-image img {
  max-height: 30px;
}
.right-content .right-content-container .content-wrapper.publication .publication-treegrid .product-pictures .thumbnail-holder {
  max-height: 280px;
}
.right-content .right-content-container .content-wrapper.publication .product-footer .footer-content .gray-button {
  text-transform: capitalize;
  margin-right: 1.5rem;
  font-size: 1.4rem;
  padding: 0.8rem 1.5rem;
}
.right-content .right-content-container .content-wrapper.publication .product-footer .footer-content .orange-button {
  text-transform: uppercase;
  font-size: 1.4rem;
  padding: 0.8rem 1.5rem;
}
.right-content .right-content-container .content-wrapper.publication .product-footer .total-row-count {
  line-height: 50px;
  padding-left: 3rem;
}
.right-content .right-content-container .content-wrapper.publication .picture-packet-modal .modal-container .modal-holder {
  width: 70%;
  z-index: 1000;
}
.right-content .right-content-container .content-wrapper.publication .picture-packet-modal .modal-container .modal-content .packet-details {
  width: 40%;
  display: inline-block;
  vertical-align: top;
}
.right-content .right-content-container .content-wrapper.publication .picture-packet-modal .modal-container .modal-content .packet-details .form-group {
  margin: 1.5rem 0;
}
.right-content .right-content-container .content-wrapper.publication .picture-packet-modal .modal-container .modal-content .packet-details .form-group label {
  display: block;
  margin-bottom: 0.5rem;
}
.right-content .right-content-container .content-wrapper.publication .picture-packet-modal .modal-container .modal-content .packet-details .form-group .form-control {
  width: 100%;
  margin: 0;
}
.right-content .right-content-container .content-wrapper.publication .picture-packet-modal .modal-container .modal-content .packet-preview {
  width: 60%;
  display: inline-block;
  vertical-align: top;
  padding-left: 2rem;
}*/


.right-content .right-content-container .content-wrapper .picture-packet-modal .modal-container .modal-holder {
  width: 70%;
  z-index: 1000;
}

.right-content .right-content-container .content-wrapper .picture-packet-modal .modal-container .modal-content .packet-details {
  width: 40%;
  display: inline-block;
  vertical-align: top;
}
.right-content .right-content-container .content-wrapper .picture-packet-modal .modal-container .modal-content .packet-details .form-group {
  margin: 1.5rem 0;
}
.right-content .right-content-container .content-wrapper .picture-packet-modal .modal-container .modal-content .packet-details .form-group label {
  display: block;
  margin-bottom: 0.5rem;
}
.right-content .right-content-container .content-wrapper .picture-packet-modal .modal-container .modal-content .packet-details .form-group .form-control {
  width: 100%;
  margin: 0;
}
.right-content .right-content-container .content-wrapper .picture-packet-modal .modal-container .modal-content .packet-preview {
  width: 60%;
  height: 500px;
  display: inline-block;
  vertical-align: top;
  padding-left: 2rem;
}