/* 20-9-19 - Guna */
/* after css to less change */
.filter-strip {
	.reset-filters {
		color: #fff;
		margin: 10px;
		cursor: pointer;
		padding: 2px 10px;
		border-radius: 30px;
		background-color: #1aa3b1;
		border: 1px solid #1a8e9a;
	}
	.reset-filters:hover {
		background-color: #1a8e9a;
		border: 1px solid #16717b;
	}
}

.popup {
    position: fixed;
    width: 300px;
    height: 300px;
    top: 50px;
    background-color: rgb(247, 252, 255);
  }

input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}

.ag-theme-balham .ag-row.ag-row-level-1.child-row {
//	padding-top: 10px;
	background-color: #efefef;
//	span.ag-icon.ag-icon-expanded {
//		margin-left: 10px;
//	}
}
.ag-theme-balham .ag-row.ag-row-level-2.child-row {
//	padding-top: 10px;
	background-color: #ddd;
//	span.ag-icon.ag-icon-expanded {
//		margin-left: 20px;
//	}
}

/* added to stop the left scrollbar from showing up */
.ag-pinned-left-cols-viewport {
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden !important;
}

/* The Modal (background) */
.ag-modal-container {
    position: fixed; /* Stay in place */
    z-index: 0; /* Sit on top */
    padding-top: 90px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.ag-modal-holder {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 462px;
    border-radius: 16px 16px 16px 16px;
}

.ag-modal-container .ag-modal-holder select {
    pointer-events: visible;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
    height: 30px;
    border: solid 1px #d7dcdd;
    box-shadow: 0 0 3px 0 rgba(217, 217, 217, 0.5);
    width: 42%;
}


.ag-modal-container .ag-modal-holder .modal-header {
    padding: 1.5rem 3rem;
    font-size: 1.9rem;
    background: #2FACB2;
    color: #fff;
    border-radius: 14px 14px 0 0;
    position: relative;
}

.ag-modal-container .ag-modal-holder .modal-header .close {
    position: absolute;
    right: 2rem;
    cursor: pointer;
}

.ag-modal-container .ag-modal-holder .modal-content {
    padding-top: 1rem;
    padding-right: 3rem;
    padding-bottom: 1rem;
    padding-left: 3rem;
}

.ag-modal-container .ag-modal-holder .modal-footer {
    margin: 3.3rem 0 1rem;
    text-align: center;
}


/* Modal Content for copywrite*/
.ag-modal-holder-copywrite {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 70%;
    border-radius: 16px 16px 16px 16px;
}

.ag-modal-container .ag-modal-holder-copywrite select {
    pointer-events: visible;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
    height: 30px;
    border: solid 1px #d7dcdd;
    box-shadow: 0 0 3px 0 rgba(217, 217, 217, 0.5);
    width: 42%;
}


.ag-modal-container .ag-modal-holder-copywrite .modal-header {
    padding: 1.5rem 3rem;
    font-size: 1.9rem;
    background: #2FACB2;
    color: #fff;
    border-radius: 14px 14px 0 0;
    position: relative;
}

.ag-modal-container .ag-modal-holder-copywrite .modal-header .close {
    position: absolute;
    right: 2rem;
    cursor: pointer;
}

.ag-modal-container .ag-modal-holder-copywrite .modal-content {
    padding-top: 1rem;
    padding-right: 3rem;
    padding-bottom: 1rem;
    padding-left: 3rem;
}

.ag-modal-container .ag-modal-holder-copywrite .modal-footer {
    margin: 3.3rem 0 1rem;
    text-align: center;
}

/* Modal Content for copywrite*/
/*
.filter-strip-holder .filter-strip li .remove2::after {
    content: ' \2717';
    margin-top: -5px ;
    -webkit-transform: rotate(30deg);
    -moz-transform: rotate(30deg);
    -o-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
} */


.ag-check-box-container {
    text-align: center;
    vertical-align: middle;
    margin-top: 7;
}

.ag-body-viewport-wrapper {
	.ag-layout-normal.ag-body-viewport {
		height: calc(100% - 10px);
	}
}
.ag-pinned-right-header {
	background-color: #e1eeee;
	.ag-header-row {
		border-left: 1px solid #BDC3C7;
//		margin-left: 12px;
	}
}
/* CSS for scrollbars always showing */
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0,0,0,.5);
    box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
/* CSS for scrollbars always showing */


.ag-status-bar {
    display: none !important;
}

/* Down arrow for dropdown both .arrow and .downpos */
.arrow {
    border: solid #91a0a1;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
    position: absolute;
    left: 85%;
    top: 7px;
}

.downPos {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

/* for channel pop up*/
.ag-popupIndicator img{
    float: right;
    padding-top: 5px;
}



/* CSS for Samples Column */

.samplesWidth{
    width: 62% !important;
}

.sampleGrid {
    width: 100%;
    overflow: scroll;

		.blue-checkbox {
		position: relative;
		display: block;
		width: 14px;
		height: 14px;
		margin: auto;
		padding-top: 6px;
	}
}

.Sample-SimpleModal {
    position: absolute;
    /*z-index: 2000;*/
    width: auto;
    bottom: 160px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 16px;
    padding: 1%;
    left: 37%;
    box-shadow: 2px 2px 5px #555;
    display: none;
  }
  .Sample-SimpleModal.movesample {
    left: 28%;
    width: 350px;
  }
  .Sample-SimpleModal .field-data {
    margin: 10px 0 0;
    text-align: center;
    border-top: 1px solid #999;
    padding-top: 10px;
  }
  .Sample-SimpleModal h3 {
    color: #555;
  }
  .Sample-SimpleModal .cancel {
    text-decoration: underline;
    cursor: pointer;
  }
  .Sample-SimpleModal .sample-btn {
    word-wrap: break-word;
    background: #555;
    text-decoration: none;
    padding: 5px;
    cursor: pointer;
    margin-left: 10px;
    font-size: 13px;
    display: inline-block;
    font-size: 15px;
    text-align: center;
    border-radius: 10px;
    color: #fff;
  }

  .Sample-SimpleModal .sample-btn.generate-card {
    width: 120px;
  }

  .Sample-SimpleModal input {
    width: 50px;
  }

  .Sample-SimpleModal.open {
    display: block;
  }

  .Sample-SimpleModal select {
    padding: 5px;
  }

  .sampleMgmtPopup .modal-holder {
      width: 50% !important;
  }
/* CSS for Samples Column */

/* CSS for JqDatePicker */
.jqx-datetimeinput .jqx-widget-content {
    height: auto !important;
}
/* CSS for JqDatePicker */

/* this is for read only cells */
.read-only-cells {
    color: #aaabab;
}

/* / for po confirm modal fix / */

.ConfirmSubmitModal .modal-holder {
    top: 20% !important;
}

.ConfirmSubmitModal p {
    font-size: 18px;
    color: #454a4b;
    text-align: center;
}

/* samples grid cell height */

.sampleGrid .jqx-grid-cell {
    height: 30px !important;
}

/* grey disbaled cell color for size and color dd */
.grey-cell-text {
   color: #d3d3d3;
}

/* add po popup style */
.addpo-content button {
    background-color: #e1eeee;
    margin-top: 15px;
}

.addpo-content {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    background-color: #e1eeee;
    color: #000;
    padding: 15px;
    font-size: 14px;
    text-align: center;
    line-height: 1.4;
    word-wrap: break-word;
    width: 450px;
    margin: 0 auto 0 auto;
}

.notifi-iro-container {
position: relative;
bottom: 300px;
}

/* samples is swatch popup fix */

.ConfirmSubmitModalSamples .modal-holder {
    width: 400px !important;
    position: relative;
    top: 15%;
}

.ConfirmSubmitModalSamples p {
    font-size: 16px;
    text-align: center;
}

.swatchReferenceContainer {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    height: 100%;
}

.Sample-SimpleModal {
    width: 250px;
    margin: 150px auto;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 16px;
    padding: 1%;
    box-shadow: 2px 2px 5px #555;
    display: none;
    position: initial !important;
}


/* dropdownCss */

.agCustomDropdown {
    margin: 0;
    overflow-y: auto;
    max-height: 200px;
    height: auto;
    white-space: nowrap;
}

.agCustomDropdownItems:hover {
    background: #f2f2f2;
}

.agCustomDropdownSelected {
    background: #cccccc;
    border-radius: 1px;
}

.agCustomDropdownItems {
    padding: 5px 0px 5px 10px;
    text-transform: capitalize;
    font-size: 12px;
}

.ag-DropdownSearchInput{
    width: 100%;
    height: 28px;
    border-radius: 1px;
}

.create-variant {
    position: absolute;
    top: 228px;
    padding: 3px;
    text-align: center;
    background-color: #33adb2;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.create-variant:hover {
    position: absolute;
    padding: 3px;
    text-align: center;
    width: 100%;
    background-color: #33adb2;
    color: #fff;
    font-size: 16px;
}

/* added to remove blue border on user click
.ag-popup-editor {
    outline: none
} */

/* checkbox dropdown */

.agCustomDropdownItemsCheckbox {
    padding: 0;
    text-transform: capitalize;
    font-size: 14px;
    white-space: nowrap;
}


/* remove extra spacing in header cell and other cells - this is an override for the ag-grid css */
.ag-HeaderCustomStyle {
    padding-left: 4px !important;
    padding-right: 4px !important;
}

.ag-HeaderCustomStyle .ag-icon-menu {
    width: 8px !important;
}

.ag-HeaderCustomStyle .ag-header-cell-label {
    width: calc(100% - 8px) !important;
}

.ag-theme-balham .ag-cell {
    padding-left: 4px !important;
    padding-right: 4px !important;
}


/* sweft theme color override for Column Headers */
.ag-HeaderCustomStyle {
    background: #e1eeee;
}

/* used to align int numbers on the right */
.ag-NumberAlignClass {
    text-align: right;
}

/* center align the checkbox in cell - bringing back the old checkboxes */
.ag-check-box-container {
    text-align: center;
    margin: 7px auto;
    display: block;
}

/* scroll bar increase width and add arrows */
.ag-check-box-container input:checked + label:after {
    content: '\2713';
    font-size: 12px;
    color: #fff;
    top: -6px;
    position: absolute;
    left: 2px;
  }


.ag-body-viewport::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    border: 0.5px solid darkgrey;
}

.ag-body-viewport::-webkit-scrollbar-thumb {
    background-color: grey;
}

.ag-body-viewport::-webkit-scrollbar-track {
    background-color: lightgrey;
}

.ag-body-viewport::-webkit-scrollbar-button:single-button {
    background-color: lightgrey;
    display: block;
    border-style: solid;
    height: 16px;
    width: 16px;

}


.ag-body-viewport::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #555555 transparent;
    border: 10px solid transparent;
}

.ag-body-viewport::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    border-color: transparent transparent #777777 transparent;
    border: 10px solid transparent;
}

.ag-body-viewport::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 8px 8px 0 8px;
    border-color: #555555 transparent transparent transparent;
    border: 10px solid transparent;
}

.ag-body-viewport::-webkit-scrollbar-button:single-button:vertical:increment:hover {
    border-color: #777777 transparent transparent transparent;
    border: 10px solid transparent;
}

.ag-body-viewport::-webkit-scrollbar-button:horizontal {
    border: 10px solid transparent;
    /* border-image: url('') 50 round; */
}

/* Image Popup No Image Text Align */
.noImgFix {
    width: 63% !important;
    font-size: 16px;
    text-transform: UPPERCASE;
}

/* added to remove breaking of checkboxes and remainder of the grid */
.ag-pinned-left-cols-viewport-wrapper {
    height: 95% !important;
}
.ag-body-viewport-wrapper {
	.ag-pinned-right-cols-viewport {
		height: calc(100% - 27px);
	}
}

/* added for view by arrow */

.viewByArrow {
    display: inline-block;
    position: relative;
}

.viewByArrow .record-action:after {
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #555555;
    position: absolute;
    top: 14px;
    right: 15px;
  }
  .viewByArrow .record-action.open:after {
    content: '';
    border-top: 0px;
    border-bottom: 5px solid #ffffff;
  }

  .viewByArrowPadding {
      padding-right: 2rem !important;
  }


.record-creation-holder2 {
  position: relative;
  display: inline-block;
  padding: 0;
}

.record-creation-holder2 .record-options {
    position: absolute;
    bottom: 100%;
    z-index: 999;
    background: #fff;
    -webkit-box-shadow: 0 0 5px #525E61;
    -moz-box-shadow: 0 0 5px #525E61;
    box-shadow: 0 0 5px #525E61;
    width: 120px;
    right: 10px;
}

.record-creation-holder2 .record-action {
    cursor: pointer;
    user-select: none;
    position: relative;
}

.record-creation-holder2 .record-action:after {
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #555555;
    position: absolute;
    top: 12px;
    right: 2px;
}

.record-creation-holder2 .record-action:not(.open) + .record-options {
    display: none;
}

.record-creation-holder2 .record-options ul li {
    padding: 0.5rem 1rem;
    color: #525E61;
    cursor: pointer;
}

.bulkupdate-table {
	.table-wrap {
		.table-head {
			.table-detail {
				background-color: #f5f7f7;
				font-size: 13px;
				font-weight: bold;
				border-top: 1px solid #555;
			}
		}
		.table-row {
			.table-detail {
				width: 30%;
				padding: 5px;
                float: left;
                height: 25px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				border-right: 1px solid #555;
				border-bottom: 1px solid #555;
			}
			.product-name.table-detail {
				width: 70%;
				border-left: 1px solid #555;
			}
		}
	}
	.table-wrap.changed-data {
		.table-row {
			.table-detail {
				width: 50%;
				min-height: 25px;
			}
		}
	}
	input[type="button"] {
		padding: 10px 20px;
		text-transform: uppercase;
		background-color: #1aa3b1;
		color: #fff;
		border: none;
		border-radius: 4px;
		font-family: Roboto,sans-serif;
		letter-spacing: 1px;
		cursor: pointer;
	}
}
.bulkupdate-actions {
	input[type="button"] {
		padding: 10px 20px;
		text-transform: uppercase;
		font-family: Roboto,sans-serif;
		letter-spacing: 1px;
		cursor: pointer;
		border-radius: 4px;
		border: solid 1px #555555;
		background-color: #fff;
	}
	input[type="button"][value="Update"] {
		background-color: #1aa3b1;
		border: none;
		color: #fff;
	}
	input[type="button"][value="Update"][disabled] {
		opacity: 0.5;
	}
}

.customHeader-wrap {
	display: inline-block;
    width: 100%;
	.custom-header-item {
		float: left;
	}
	.customHeaderLabel {
		width: calc(100% - 27px);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.customHeaderMenuButton {
		visibility: hidden;
		width: 15px;
		padding: 8px 4px;
    	cursor: pointer;
	}
	.custom-header-icon {
		width: 12px;
		margin: 8px 0;
    	cursor: pointer;
		.ag-icon-asc,.ag-icon-desc {
			width: 6px;
		}
	}
}
.customHeader-wrap:hover {
	.customHeaderMenuButton {
		visibility: visible;
	}
}

// adding css for copywrite image section

.imageContainer {
    width: 40%;
    height: 31em;
    float: left;
    margin-right: 20px;
    overflow: hidden;
}

.imageThumbs {
    width: 20%;
    height: 100%;
    float: left;
    margin-right: 20px;

    img {
        margin-bottom: 10px;
        cursor: pointer;
        max-width: 75px;
        max-height: 75px;
    }
}

.imageBig {
    width: 55%;
    float: left;

    img {
        max-width: 250px;
        max-height: 300px;
    }
}

.noImageText {
    text-align: center;
    margin-top: 50%;
    font-weight: bold;
    font-size: medium;
}

.addCopyDetails .form-container {
    max-height: 70%;
}


// styles for message component
.message-container-render {
    color: #33adb4;
    width: 100%;
    text-align: center;

    .msg-img {
        background: url('../common/icons/mGrid.png') no-repeat;
        background-size: 30px;
        background-position: center;
        width: 100%;
        height: 100%;
    }

    .message-count {
        position: relative;
        bottom: 36px;
    }
    
}

.message-editor-container {
    width: 330px;
    height: 260px;
    position: absolute;
    background-color: #fff;
    border: 1px solid #33adb4;
    left: 55px;
    border-radius: 10px;
    transition:all 0.5s linear;
    z-index: 999;	

    &:before {
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        position: fixed;
        z-index: -1;
    }

    .message-editor-header, .message-editor-footer {
        height: 45px;
        padding: 10px;
        display: flex;
        justify-content: space-around;
    }

    .message-editor-header {
        flex-flow: column;

        .comment-count {
            font-weight: bold;
        }

        .sku-info {
            display: flex;

            span:nth-child(1) {
                flex: 1 0 25%;
                color: #a2a8b5;
            }

            span:nth-child(2) {
                flex: 1 0 24%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            span:nth-child(3) {
                flex: 1 0 50%;
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            span:nth-child(4) {
                flex: 1 0 1%;
                text-align: right;
                font-weight: bold;
                cursor: pointer;
            }
        }

    }


    .message-editor-body {
        height: 175px;
        overflow: hidden;
        overflow-y: auto;

        h3 {
            margin-left: 10px;
        }
        .message-section {
            height: auto;
            border: 1px solid lightgrey;
            padding: 0px 5px 0px 5px;

            .to-message-error {
                position: relative;
                top: -10px;
                left: 2px;
                color: red;
            }

            .section-sender span, .section-date span {
                width: 50%;
                display: inline-block;
            }

            .section-comment {
                height: auto;
            }

            .section-date {
                span:nth-child(1) {
                    color: #7db0de;
                }

                span:nth-child(2) button {
                    position: relative;
                    left: 90px;
                    bottom: 3px;
                }

                .read-message {
                    font-style: italic;
                    text-align: right;
                    color: #b8b8b8;
                }

            }

        }

        .use-input-section {
            height: 85px !important;

            .section-sender {
                padding: 5px 0;

                .to-user-error {
                    font-size: 11px;
                    width: 100% !important;
                    color: red;
                    position: relative;
                    left: 0px;
                    bottom: 2px;
                    padding-bottom: 6px;
                    height: 0px;
                }

            }

            .input-message {
                width: 100%;
                height: 50px;
                resize: none;
            }

            .section-comment {
                height: 45px;
            }

            .dropdownClick {
                display: inline-block;
            }

            .ag-DropdownSearchContainer {
                position: absolute;
                top: 40px;
                left: 25px;
                .ag-DropdownSearchInput {
                    width: 100%;
                    height: 18px;
                    border-radius: 1px;
                }
                .agCustomDropdown {
                    background-color: #fff;
                    cursor: pointer;
                    border: 1px solid;
                }
            }
        }
    }

    .message-button {
        width: 60px;
        height: 20px;
        border-radius: 10px;
        font-weight: bold;
        transition-duration: 0.5s;
        cursor: pointer;

        &:hover:not([disabled]) {
            color: #fff;
            background-color: #33adb4;
        }

        &:focus {
            outline: none;
        }
    }

    .new, .send {
        color: #33adb4;
        border: 1px solid;
        &[disabled] {	
            color: #f7e6e6;	
        }
    }

    .cancel {
        color: #a78b8b;
        border: 1px solid;
    }
}


// single view popup
.single-view-container {
    height: 360px;
    .static-section {
        height: 85px;
        border-bottom: 1px solid #c5c5c5;
        display: flex;
        color:  #8c8d8e;

        .image {
            width: 20%;
            height: 100%;
            text-align: center;
            line-height: 80px;

            .block {
                display: none;
            }

            img {
                width: 100%;
                height: 100%;
            }

            .images-upload {
                .upload-input {
                    position: relative;
                    height: 23px;
                    padding-top: 1rem;
                    text-align: center;
                    input {
                        height: 25px;
                        left: calc(50% - 25px);
                    }

                    .icon-holder {
                        width: 25px;
                        height: 25px;
                        background: #F15A29;
                        position: absolute;
                        left: calc(50% - 15px);
                        border-radius: 100%;
                        top: 8px;
                    }
                    .icon-holder:before {
                        content: '';
                        position: absolute;
                        height: 15px;
                        width: 2px;
                        background: #fff;
                        left: 12px;
                        top: 5px;
                    }
                    .icon-holder:after {
                        content: '';
                        width: 15px;
                        height: 2px;
                        background: #fff;
                        display: inline-block;
                        position: absolute;
                        top: 12px;
                        left: 5px;
                    }
                }

                .orange-button {
                    padding: 0px;
                    width: 5em;
                    height: 2em;
                }
            }
        }
        .prod-desc {
            width: 80%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 2px 5px;

            .section-sweft-id {
                width: 50%;
                order: 1;
                input {
                    width: 95%;
                    border: solid 1px #D7DCDD;
                }
            }
            .section-style-number {
                width: 50%;
                order: 2;
                input {
                    width: 95%;
                    border: solid 1px #D7DCDD;
                }
            }
            .section-prod-name {
                width: 100%;
                order: 3;
                height: 50px;
                padding-top: 5px;
                label {
                    width: 27%;
                    display: inline-block;
                    line-height: 20px;
                    height: 100%;
                    vertical-align: top;
                }
                textarea {
                    display: inline-block;
                    width: 70%;
                    height: 40px;
                    border: solid 1px #D7DCDD;
                    resize: none;
                }
            }
        }
    }
    .fields-section {
        height: 240px;
        overflow: scroll;

        .input-container {
            padding: 0.5rem 1.5rem;

            label {
                width: 35%;
                display: inline-block;
            }
            input {
                width: 65%;
                border: solid 1px #D7DCDD;
                display: inline-block;
            }
            .component-container {
                display: inline-block;
                width: 65%;
                
                .checkbox-error-msg {
                    width: 90%;
                    float: right;
                    color: #e60a0a;
                }

                .blue-checkbox{
                    input {
                        position: absolute;
                        left: 0;
                        width: 14px;
                        height: 14px;
                        z-index: 4;
                        opacity: 0;
                    }
                    input:not(:checked) + label {
                        position: absolute;
                        left: 0;
                        width: 14px;
                        height: 14px;
                        z-index: 3;
                        border: 1px solid #2FACB2;
                        border-radius: 3px;
                    }
                    input:checked + label {
                        position: absolute;
                        left: 0;
                        width: 14px;
                        height: 14px;
                        z-index: 3;
                        border: 1px solid #2FACB2;
                        border-radius: 3px;
                        background: #2FACB2;
                    }
                }
            }
        }
    }
    .close-section {
        height: 10%;
        .button-container {
            width: 150px;
            margin: auto;
        }
    }


}

// styles for excel upload popup

.import-from-excel {

    background-color: transparent;
    top: 180px;

    .ag-modal-holder {
        width: 770px;
    }

    .modal-header {
        text-align: center;
    }

    .modal-content {

        .main-container {
            display: flex;

            .main-section {
                width: 50%;
                display: flex;

                #uploadCSV {	
                    overflow: hidden;	
                }
    
                fieldset:nth-child(2) {
                    text-align: right;
                    margin-top: 10px;
                }
    
                .sections {
                    width: 33%;
                    border-right: 1px solid #818181;

                    .headings {
                        font-size: 12px;
                        text-align: center;
                        margin-top: 8px;
                        color: #818181;
                        font-weight: 400;
                    }
                }

                .sections:last-child {
                    border-right: none;
                }

                .load-icon {
                    display: inline-block;
                    -webkit-animation:spin 4s linear infinite;
                    -moz-animation:spin 4s linear infinite;
                    animation:spin 4s linear infinite;
                    font-size: 20px;
                    color: #2facb1;
                }

                @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
                @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
                @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
            }

            .main-section:nth-child(2) {
                border: 1px solid #818181;
            }
        }


        .files-history {
            .heading {
                font-size: 12px;
                text-align: left;
                margin-top: 8px;
                color: #818181;
                font-weight: 400;
            }

            .files-info {
                border: 1px solid #818181;
                height: 130px;
                overflow: scroll;
                padding-left: 5px;

                .info-headings {
                    display: flex;
                    flex-flow: wrap;

                    div {
                        font-size: 12px;
                        text-align: left;
                        margin-top: 8px;
                        color: #818181;
                        font-weight: bold;
                    }

                    a {
                        font-size: 12px;
                        text-align: left;
                        margin-top: 8px;
                        font-weight: bold;
                        padding-left: 5px;
                    }
    
                    .success, .errors, .fixed {
                        width: 10%;
                        text-align: center;
                    }
    
                    .source-file, .error-file {
                        width: 25%;
                    }
    
                    .date {
                        width: 20%;
                    }
                }

                .info-headings:not(:first-child) {
                    div:not(:first-child), a {
                        border-left: 1px solid #818181;
                        padding-left: 5px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

            }
        }

    }



}


// styles for set detail column popup
.set-detail-col {
    left: -205px;
    top: 50px;
    width: 500px;

    .message-editor-header {
        background-color: #2FACB2;
        color: #fff;
        font-size: 18px;
        display: flex;
        flex-wrap: wrap;

        .close {
            text-align: right;
            cursor: pointer;
        }
    }

    .message-editor-body {
        // overflow-y: hidden;

        .message-error {
            color: #e60a0a;
        }
    }

    .message-editor-footer {
        justify-content: normal;
        padding: 8px;

        .add-row {
            width: 35%;
            display: flex;
            justify-content: space-evenly;

            .message-button {
                width: 70px;
            }
        }

        .show-total {
            width: 65%;
            display: flex;
            justify-content: space-around;
            margin-right: 25px;
            font-weight: bold;
        }
    }

}


// styles for quantity breaks column popup
.qty-breaks-col, .general-file-upload {
    left: -135px;
    width: 280px;
    top: 50px;
    height: 220px;

    .message-editor-header {
        background-color: #2FACB2;
        color: #fff;
        font-size: 18px;
        display: flex;
        flex-wrap: wrap;

        .close {
            text-align: right;
            cursor: pointer;
        }
    }

    .message-editor-body {
        height: 140px;
        overflow: hidden;

        .ag-root {
            border: none !important;
        }

        .ag-body-viewport {
            overflow: hidden;
        }

        .upload-section {

            .top-sec {
                height: 50%;
                display: flex;
                justify-content: space-between;

            }

            .bottom-sec {
                height: 50%;
            }

            .custom-file-upload {
                width: 100px;
                height: 27px;
                border: 1px solid #000;
                cursor: pointer;
                display: block;
                border-radius: 5px;
                text-align: center;
                line-height: 25px;
                margin: 10px 10px 0px 10px;
            }

            .grey {
                border: 1px solid gray !important;
            }

            .selected-file {
                margin-left: 10px;
                color: grey;
            }

            .uploaded-file {
                margin-left: 10px;
                position: absolute;
                width: 100%;
                overflow: hidden;
            }
        }
    }

}

// added for ul items in notifications

#req-items {

    width: 260px;
    margin: auto;
    padding-left: 60px;
    height: 90px;
    max-height: 90px;
    overflow: auto;

    ul {
        list-style-type: disc;
        text-align: left;
    }
}


// added for images popup items
.image-name div {
    text-align: left;
    white-space: nowrap;
}


.thumbnail-holder input {
    position: relative;
    left: -34px;
    top: 12px;
}


.upload-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 60px;

    .image-icon-container {
        width: 40px;
        height: 40px;
        background: #f15a29;
        border-radius: 50%;

        .fas {
            font-size: 20px;
            line-height: 40px;
            color: #fff;
        }

        input {
            display: none;
        }
    }

    button {
        margin: 0 20px;
        width: 50px;
        height: 30px;
        padding: 0 !important;
    }
}

// custom style for clear button datepicker on the grid
.DayPicker-TodayButton {
    width: 50px;
    padding: 3px 8px;
    font-size: 12px !important;
    border-radius: 20px;
    background-color: #33adb2 !important;
    color: #fff !important;
    margin-left: auto;
    display: block;
    margin: auto;
}

// CSS TO MAKE CHECKBOX VISIBLE AFTER AG UPGRADE
.ag-label-align-right > * {
    width: 40px;
}

// Addeds the missing border on grid headers
.ag-theme-balham .ag-header-cell-resize {
    right: -4px !important;
}

// expands column headers to fit width
.ag-react-container {
    width: 100%;
}

// sets the size of the filter icons
.ag-tabs-header .ag-icon {
    background-size: 22px 22px !important;
    height: 22px !important;
    width: 22px !important;
}

// hides default icons causing overlap
.ag-icon-menu, .ag-icon-filter, .ag-icon-columns, .ag-icon-pin, 
.ag-icon-small-right, .ag-icon-tick, .ag-icon-tree-closed, .ag-icon-tree-open,
.ag-icon-desc, .ag-icon-asc, .ag-icon-loading {
    background: none !important;
}

// center checkbox inside cells
.ag-checkboxContainer {
    text-align: center;
}

// align checkbox tick inside box
#myGrid .blue-checkbox input:checked + label:after {
    content: '\2713';
    font-size: 12px;
    color: #fff;
    top: -6px;
    position: absolute;
    left: 2px;
}

// centers selection checkbox inside cell
.ag-theme-balham .ag-ltr .ag-row-drag,
.ag-theme-balham .ag-ltr .ag-selection-checkbox,
.ag-theme-balham .ag-ltr .ag-group-expanded,
.ag-theme-balham .ag-ltr .ag-group-contracted {
    margin: 0 auto;
}

//multiple file upload 

.multiple-file-upload {
    left: -135px;
    width: 35vw;
    top: 50px;
    height: 210px;

    .message-editor-header {
        background-color: #2FACB2;
        color: #fff;
        font-size: 18px;
        display: flex;
        flex-wrap: wrap;

        .close {
            text-align: right;
            cursor: pointer;
        }
    }

    .message-editor-body {
        height: 165px;

        .left-sec {
            width: 21%;
            float: left;
            border-right: 1px solid #1aa3b1;
            height: 100%;

            .custom-file-upload {
                width: 100px;
                height: 27px;
                border: 1px solid #000;
                cursor: pointer;
                display: block;
                border-radius: 5px;
                text-align: center;
                line-height: 25px;
                margin: 10px 10px 0px 10px;
            }

            .grey {
                border: 1px solid gray !important;
            }

            .selected-file {
                margin-left: 10px;
                color: grey;
            }

            .uploaded-file {
                margin-left: 10px;
                position: absolute;
                width: 100%;
                overflow: hidden;
            }
        }

        .right-sec {
            width: 79%;
            float: left;
            height: 100%;

            .selected-files, .uploaded-files {
                width: 50%;
                height: 88%;
                overflow: scroll;
                float: left;
            }

            .heading {
                text-align: center;
                font-weight: bold;
            }

            ol {
                margin: 0;
                padding: 0 10px;
            }

            li {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                input {
                    margin-right: 5px;
                }
            }
        }

    }
}
