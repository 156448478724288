/* added to remove grouped value from grid */
#myGrid .ag-group-value {
    display: none;
}

.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell {
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.ag-creative-parent-dropdown-container {
    width: 220px;
    height: auto;
    display: block;
    margin: auto;
}

.ag-creative-parent-dropdown-container .agCustomDropdown {
    margin: 0;
    overflow-y: scroll;
    max-height: 120px;
    height: 120px;
    white-space: nowrap;
}

.ag-creative-parent-dropdown-container .ag-DropdownSearchInput {
    width: 100%;
    height: 28px;
    border-radius: 1px;
    margin: 10px 0;
}

.ag-creative-parent-dropdown-container .agCustomDropdown .selectedVal {
    background: #f2f2f2;
}

.ag-creative-parent-dropdown-container .agCustomDropdownItems {
    cursor: pointer;
}

.ag-creative-parent-dropdown-footer {
    margin: 10px 0px;
}

.ag-creative-parent-dropdown-footer .gray-button {
    margin: 0 15px;
}

.ag-creative-parent-dropdown-footer .gray-button:hover {
    background-color: ;
}

.ag-creative-parent-dropdown-footer .gray-button:first-child {
    background-color: #1aa3b1;
    color: #f2f2f2;
}

.creative-row-count {
    color: #166A8F;
    font-weight: 400;
}

/*
Overrides for defualt AG grid styling - child record color, hover and selected record styles
*/
.ag-row-level-1 {
    background-color: #eae6e6 !important;
}

.ag-theme-balham .ag-row-selected {
    background-color: #b7e4ff !important;
}

.ag-theme-balham .ag-row-hover {
    background-color: #b7f5ff !important;
}

/*
Overrides for approved shots - show difference when search is made
*/
.shot-approved {
    background-color: #ddd !important;
}

.image-approved {
    color: #01b050;
    font-weight: bold;
}

.image-rejected {
    font-weight: bold;
    color: #6b6b6b;
}

.rejected-container {
    height: 70px;
    overflow: scroll;
}

.rejected-container :not(.image-rejected) {
    text-align: left;
    padding-left: 20px;
}

.photo-direction-render {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
