.seasons-modal-container {
    position: fixed; /* Stay in place */
    z-index: 4 ; /* Sit on top */
    padding-top: 90px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.seasons-modal-holder {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 70%;
    height: 60%;
    border-radius: 16px 16px 16px 16px;
}

/* Modal Content */
.seasons-modal-holder-disabled {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 800px;
    border-radius: 16px 16px 16px 16px;
    pointer-events: none;
    opacity: 0.4;
}

.seasons-modal-container .seasons-modal-holder .modal-header, .seasons-modal-container .seasons-modal-holder-disabled .modal-header {
    padding: 1.5rem 3rem;
    font-size: 1.9rem;
    background: #2FACB2;
    color: #fff;
    border-radius: 14px 14px 0 0;
    position: relative;
}

.seasons-modal-container .seasons-modal-holder .modal-header .close, .seasons-modal-container .seasons-modal-holder-disabled .modal-header .close {
    position: absolute;
    right: 2rem;
    cursor: pointer;
}

.seasons-modal-container .seasons-modal-holder .modal-content, .seasons-modal-container .seasons-modal-holder-disabled .modal-content  {
    padding-top: 1rem;
    padding-right: 3rem;
    padding-bottom: 1rem;
    padding-left: 3rem;
    height: 80%;
}