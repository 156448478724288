.content-wrapper {
  transition: all .3s ease;
}
.content-wrapper header {
  top: 0;
  height: 64px;
  z-index: 10000;
  background-color: #edefef;
  transition: all .3s ease;
}
.content-wrapper header + * {
  position: relative;
  top: -8px;
}
.content-wrapper header .page-header-logo {
  float: left;
  display: none;
}
.content-wrapper header .page-title {
  float: left;
  margin: 1.8rem 1.8rem;
}
.content-wrapper header .page-title h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #18b9c2;
  text-transform: uppercase;
  font-size: 2.4rem;
  margin: 0;
}
.content-wrapper header .view-recent-messages {
  float: left;
  margin: 24px 0;
}
.content-wrapper header .view-recent-messages a {
  color: #afb6b8;
}
.content-wrapper header .view-recent-messages a:visited {
  color: #afb6b8;
}
.content-wrapper header .view-recent-messages .recentmessage-dropdown {
  background: #ffffff;
  position: absolute;
  padding: 10px;
  border: 1px solid #cccccc;
  max-height: 300px;
  max-width: 400px;
  overflow-y: scroll;
  z-index: 10000;
  display: none;
}
.content-wrapper header .view-recent-messages .recentmessage-dropdown.open {
  display: block;
  z-index: 10000;
}
.content-wrapper header .view-recent-messages .recentmessage-dropdown ul {
  list-style: none;
  margin: 0;
  line-height: 22px;
}
.content-wrapper header .view-recent-messages .recentmessage-dropdown ul li {
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
}
.content-wrapper header .view-recent-messages .recentmessage-dropdown ul li:last-child {
  border: none;
}
.content-wrapper header .navbar-collapse {
  float: right;
  margin: 1rem 0.8rem;
}
.content-wrapper header .navbar-collapse > div {
  float: left;
}
.content-wrapper header .navbar-collapse > ul {
  float: right;
  margin: 0;
  display: inline-flex;
}
.content-wrapper header .navbar-collapse > ul li {
  padding: 10px 17px;
}
.content-wrapper header .navbar-collapse > ul li a {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  color: #fff;
}
.content-wrapper header .navbar-collapse > ul li a .notification-count {
  min-width: 15px;
  height: 15px;
  background-color: #f15a29;
  position: absolute;
  right: 0px;
  border-radius: 50%;
  padding: 0.2rem;
  text-align: center;
  display: block;
}

.pageHeaderProductViewFields {
  cursor:pointer;
}

/* footer fix */
.footerExpandClassFix {
  width: 100% !important;
  left: 0px !important;
}

/* added to fix when drawer is opened in fullscreen mode */
.footerExpandClassFix-PulledDrawer {
  width: calc(100% - 100px - 200px) !important;
  left: 300px !important;
}

.footerExpandClassFix-PulledDrawerRight {
  width: calc(100% - 100px - 200px) !important;
  left: 15px !important;
}

.page-header-logo img {
  width: 12rem;
  margin-top: 15px;
  margin-left: 10px;
}

#stopSpamming {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 7px;
  right: 30px;
  z-index: 9999999;
}

.hide {
  display: none;
}
