.box-shadow (@string) {
	-webkit-box-shadow: @string;
	-moz-box-shadow:    @string;
	box-shadow:         @string;
}
.box-shadow-x2 (@string, @string2) {
	-webkit-box-shadow: @string, @string2;
	-moz-box-shadow:    @string, @string2;
	box-shadow:         @string, @string2;
}
.box-shadow-x3 (@string, @string2, @string3) {
	-webkit-box-shadow: @string, @string2, @string3;
	-moz-box-shadow:    @string, @string2, @string3;
	box-shadow:         @string, @string2, @string3;
}
.box-shadow-x4 (@string, @string2, @string3, @string4) {
	-webkit-box-shadow: @string, @string2, @string3, @string4;
	-moz-box-shadow:    @string, @string2, @string3, @string4;
	box-shadow:         @string, @string2, @string3, @string4;
}
.drop-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
	-webkit-box-shadow:	@x @y @blur @spread rgba(0, 0, 0, @alpha);
	-moz-box-shadow:	@x @y @blur @spread rgba(0, 0, 0, @alpha);
	box-shadow:		@x @y @blur @spread rgba(0, 0, 0, @alpha);
}
.inner-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
	-webkit-box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
	-moz-box-shadow:    inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
	box-shadow:         inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
}

.box-sizing (@type: border-box) {
	-webkit-box-sizing: @type;
	-moz-box-sizing:    @type;
	box-sizing:         @type;
}

.border-radius (@radius: 5px) {
	-webkit-border-radius: @radius;
	-moz-border-radius:    @radius;
	border-radius:         @radius;

	-moz-background-clip:    padding;
}
.border-radiuses (@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
	-webkit-border-top-right-radius:    @topright;
	-webkit-border-bottom-right-radius: @bottomright;
	-webkit-border-bottom-left-radius:  @bottomleft;
	-webkit-border-top-left-radius:     @topleft;

	-moz-border-radius-topright:        @topright;
	-moz-border-radius-bottomright:     @bottomright;
	-moz-border-radius-bottomleft:      @bottomleft;
	-moz-border-radius-topleft:         @topleft;

	border-top-right-radius:            @topright;
	border-bottom-right-radius:         @bottomright;
	border-bottom-left-radius:          @bottomleft;
	border-top-left-radius:             @topleft;

	-moz-background-clip:    padding;
	-webkit-background-clip: padding-box;
	background-clip:         padding-box;
}

.opacity (@opacity: 0.5) {
	-webkit-opacity: 	@opacity;
	-moz-opacity: 		@opacity;
	opacity: 		@opacity;
}

.gradient (@startColor: #eee , @endColor: white) {
	background-color: @startColor;
	background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
	background: -webkit-linear-gradient(top, @startColor, @endColor);
	background: -moz-linear-gradient(top, @startColor, @endColor);
	background: -ms-linear-gradient(top, @startColor, @endColor);
	background: -o-linear-gradient(top, @startColor, @endColor);
}
.radial-gradient (@startColor: white, @endColor: rgba(255,255,255,0)) {
	background: -moz-radial-gradient(center, ellipse cover,  @startColor 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,@startColor), color-stop(50%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,0)));
	background: -webkit-radial-gradient(center, ellipse cover,  @startColor 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,0) 100%);
	background: -o-radial-gradient(center, ellipse cover,  @startColor 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,0) 100%);
	background: -ms-radial-gradient(center, ellipse cover,  @startColor 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,0) 100%);
	background: radial-gradient(ellipse at center,  @startColor 0%,rgba(255,255,255,0) 50%,rgba(255,255,255,0) 100%);
}

.animation (@name, @duration: 300ms, @delay: 0, @ease: ease, @direction: normal, @iteration-count: 1, @fill-mode: none, @play-state: running) {
  animation:         @name @duration @ease @delay @iteration-count @direction @fill-mode @play-state;
	-webkit-animation: @name @duration @ease @delay @iteration-count @direction @fill-mode @play-state;
  -moz-animation:    @name @duration @ease @delay @iteration-count @direction @fill-mode @play-state;
  -ms-animation:     @name @duration @ease @delay @iteration-count @direction @fill-mode @play-state;
}

.transition (@transition) {
	-webkit-transition: @transition;
	-moz-transition:    @transition;
	-ms-transition:     @transition;
	-o-transition:      @transition;
    transition:         @transition;
}
.transition-delay (...) {
    -webkit-transition-delay: @arguments;
	-moz-transition-delay:    @arguments;
	-ms-transition-delay:     @arguments;
	-o-transition-delay:      @arguments;
    transition-delay:         @arguments;
}
.transition-timing-function (...) {
    -webkit-transition-timing-function: @arguments;
	-moz-transition-timing-function:    @arguments;
	-ms-transition-timing-function:     @arguments;
	-o-transition-timing-function:      @arguments;
}
.transition-duration (...) {
    -webkit-transition-duration: @arguments;
	-moz-transition-duration:    @arguments;
	-ms-transition-duration:     @arguments;
	-o-transition-duration:      @arguments;
}
.transition-property (...) {
    -webkit-transition-property: @arguments;
	-moz-transition-property:    @arguments;
	-ms-transition-property:     @arguments;
	-o-transition-property:      @arguments;
}
.transform(@string){
	-webkit-transform: @string;
	-moz-transform: 	 @string;
	-ms-transform: 		 @string;
	-o-transform: 		 @string;
    transform:           @string;
}
.scale (@factor) {
	-webkit-transform: scale(@factor);
	-moz-transform: 	 scale(@factor);
	-ms-transform: 		 scale(@factor);
	-o-transform: 		 scale(@factor);
}
.rotate (@deg) {
	-webkit-transform: rotate(@deg);
	-moz-transform: 	 rotate(@deg);
	-ms-transform: 		 rotate(@deg);
	-o-transform: 		 rotate(@deg);
}
.skew (@deg, @deg2) {
	-webkit-transform:       skew(@deg, @deg2);
	-moz-transform: 	 skew(@deg, @deg2);
	-ms-transform: 		 skew(@deg, @deg2);
	-o-transform: 		 skew(@deg, @deg2);
}
.translate (@x, @y:0) {
	-webkit-transform:       translate(@x, @y);
	-moz-transform: 	 translate(@x, @y);
	-ms-transform: 		 translate(@x, @y);
	-o-transform: 		 translate(@x, @y);
}
.translate3d (@x, @y: 0, @z: 0) {
	-webkit-transform:       translate3d(@x, @y, @z);
	-moz-transform: 	 translate3d(@x, @y, @z);
	-ms-transform: 		 translate3d(@x, @y, @z);
	-o-transform: 		 translate3d(@x, @y, @z);
}
.perspective (@value: 1000) {
	-webkit-perspective: 	@value;
	-moz-perspective: 	@value;
	-ms-perspective: 	@value;
	perspective: 		@value;
}
.transform-origin (@x:center, @y:center) {
	-webkit-transform-origin: @x @y;
	-moz-transform-origin:    @x @y;
	-ms-transform-origin:     @x @y;
	-o-transform-origin:      @x @y;
}
.placeholder(@rules) {

    &::-webkit-input-placeholder {
        @rules();
    }
    &:-moz-placeholder {
        @rules();
    }
    &::-moz-placeholder {
        @rules();
    }
    &:-ms-input-placeholder {
        @rules();
    }
}

.left {
	float: left !important;
}

.right {
	float: right !important;
}
// Button backgrounds
// ------------------
.buttonBackground(@startColor, @endColor) {
  // gradientBar will set the background to a pleasing blend of these, to support IE<=9
  .gradientBar(@startColor, @endColor);
//   *background-color: @endColor; /* Darken IE7 buttons by default so they stand out more given they won't have borders */
//   .reset-filter();

  // in these cases the gradient won't cover the background, so we override
  &:hover, &:active, &.active, &.disabled, &[disabled] {
    background-color: @endColor;
    *background-color: darken(@endColor, 5%);
  }

  &.disabled, &[disabled] {
	  opacity: 0.6;
  }
  // IE 7 + 8 can't handle box-shadow to show active, so we darken a bit ourselves
  &:active,
  &.active {
    background-color: darken(@endColor, 10%) e("\9");
  }
}

// Webkit-style focus
// ------------------
.tab-focus() {
  // Default
  outline: thin dotted #333;
  // Webkit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

// Gradient Bar Colors for buttons and alerts
.gradientBar(@primaryColor, @secondaryColor) {
  #gradient > .vertical(@primaryColor, @secondaryColor);
  border-color: @secondaryColor @secondaryColor darken(@secondaryColor, 15%);
  border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) fadein(rgba(0,0,0,.1), 15%);
}

// Gradients
#gradient {
  .horizontal(@startColor: #555, @endColor: #333) {
    background-color: @endColor;
    background-image: -moz-linear-gradient(left, @startColor, @endColor); // FF 3.6+
    background-image: -ms-linear-gradient(left, @startColor, @endColor); // IE10
    background-image: -webkit-gradient(linear, 0 0, 100% 0, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(left, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(left, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(left, @startColor, @endColor); // Le standard
    background-repeat: repeat-x;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",@startColor,@endColor)); // IE9 and down
  }
  .vertical(@startColor: #555, @endColor: #333) {
    background-color: mix(@startColor, @endColor, 60%);
    background-image: -moz-linear-gradient(top, @startColor, @endColor); // FF 3.6+
    background-image: -ms-linear-gradient(top, @startColor, @endColor); // IE10
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(top, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(top, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(top, @startColor, @endColor); // The standard
    background-repeat: repeat-x;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",@startColor,@endColor)); // IE9 and down
  }
  .directional(@startColor: #555, @endColor: #333, @deg: 45deg) {
    background-color: @endColor;
    background-repeat: repeat-x;
    background-image: -moz-linear-gradient(@deg, @startColor, @endColor); // FF 3.6+
    background-image: -ms-linear-gradient(@deg, @startColor, @endColor); // IE10
    background-image: -webkit-linear-gradient(@deg, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(@deg, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(@deg, @startColor, @endColor); // The standard
  }
  .vertical-three-colors(@startColor: #00b3ee, @midColor: #7a43b6, @colorStop: 50%, @endColor: #c3325f) {
    background-color: mix(@midColor, @endColor, 80%);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), color-stop(@colorStop, @midColor), to(@endColor));
    background-image: -webkit-linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-image: -moz-linear-gradient(top, @startColor, @midColor @colorStop, @endColor);
    background-image: -ms-linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-image: -o-linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-image: linear-gradient(@startColor, @midColor @colorStop, @endColor);
    background-repeat: no-repeat;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",@startColor,@endColor)); // IE9 and down, gets no color-stop at all for proper fallback
  }
  .radial(@innerColor: #555, @outerColor: #333)  {
    background-color: @outerColor;
    background-image: -webkit-gradient(radial, center center, 0, center center, 460, from(@innerColor), to(@outerColor));
    background-image: -webkit-radial-gradient(circle, @innerColor, @outerColor);
    background-image: -moz-radial-gradient(circle, @innerColor, @outerColor);
    background-image: -ms-radial-gradient(circle, @innerColor, @outerColor);
    background-image: -o-radial-gradient(circle, @innerColor, @outerColor);
    background-repeat: no-repeat;
  }
  .striped(@color, @angle: -45deg) {
    background-color: @color;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255,255,255,.15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255,255,255,.15)), color-stop(.75, rgba(255,255,255,.15)), color-stop(.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
    background-image: -ms-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  }
}

.icon-custom-plus(@width:11px,@length:1px,@top:5px,@left:0px,@color:#ccc){
	display: inline-block;
    width: @width;
    height: @width;
    position: relative;

	&:before{
		content: '';
		width: @width;
		height: @length;
		background: @color;
		display: inline-block;
		position: absolute;
    top: @top;
    left: @left
  }

  &:after{
    content: '';
    position: absolute;
    height: @width;
    width: @length;
    background: @color;
    left: @top;
    top: @left;
  }
}

.icon-custom-cross(@width:11px,@length:1px,@top:5px,@left:0,@color:#ccc){
  .icon-custom-plus(@width,@length,@top,@left,@color);
  .transform(rotate(45deg));
}
