.error-boundry-wrap {
	padding: 30px;
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    top: 0px;
	left: 0px;
	cursor: not-allowed;
    background-color: #00000085;
	.error-description {
		max-height: 450px;
		width: 100%;
		overflow: auto;
		padding: 10px;
		background-color: #eee;
		border: none;
		border-radius: 0px 0px 10px 10px;
	}
	button.error-reload-button {
		margin-left: 20px;
		font-size: 15px;
		border: 1px solid #555;
		background-color: #fff;
		padding: 5px 20px;
		border-radius: 5px;
	}
	button.error-copy-button {
		margin-left: 20px;
		font-size: 15px;
		background-color: #eee;
		padding: 5px 20px;
		border-radius: 5px;
		border: 1px solid #aaa;
	}
	.copy-msg {
		margin-left: 20px;
		font-size: 14px;
		color: #008300;
	}
	h2 {
		background-color: #fff;
		color: #1c98ad !important;
		text-align: center;
		font-weight: bold !important;
		border-radius: 10px;
		width: 50%;
		font-size: medium;
		height: 50%;
		padding-top: 3% !important;
		margin: auto !important;
	}
	i {
		color: gray;
	}
}
