.left-nav-content {
  display: inline-block;
  width: 100px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  border-right: solid 1px #9bd5ea;
  opacity: 0.99;
  z-index: 999;
  transition: all .3s ease;
}

ul.dropdown-list a {
  cursor: pointer;
}

.left-nav-content .text-center.scroll {
  height: 100%;
  overflow: auto;
}
.left-nav-content .footer-nav .footer-menuList.bottom {
  margin-bottom: 50px;
}
.left-nav-content .main-nav .logo,
.left-nav-content .footer-nav .logo {
  padding: 1.5rem;
}
.left-nav-content .main-nav .logo img,
.left-nav-content .footer-nav .logo img {
  width: 94%;
}
.left-nav-content .main-nav .user-details,
.left-nav-content .footer-nav .user-details {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}
.left-nav-content .main-nav .user-details p,
.left-nav-content .footer-nav .user-details p {
  margin: 6px 0 16px;
}
.left-nav-content .main-nav .user-details p a,
.left-nav-content .footer-nav .user-details p a {
  text-decoration: none;
  color: #555555;
  font-size: 13px;
}
.left-nav-content .main-nav .user-details p a span,
.left-nav-content .footer-nav .user-details p a span {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  text-decoration: underline;
}
.left-nav-content .main-nav .user-details:hover:before,
.left-nav-content .footer-nav .user-details:hover:before,
.left-nav-content .main-nav .user-details.active:before,
.left-nav-content .footer-nav .user-details.active:before {
  content: '';
  position: absolute;
  width: 50px;
  height: 100px;
  right: -20px;
  top: 50px;
  background: transparent;
}

.left-nav-content .main-nav .user-details:hover .dropdown-menu,
.left-nav-content .footer-nav .user-details:hover .dropdown-menu,
.left-nav-content .main-nav .user-details.active .dropdown-menu,
.left-nav-content .footer-nav .user-details.active .dropdown-menu {
  display: block !important;
}
.left-nav-content .main-nav ul,
.left-nav-content .footer-nav ul {
  display: block;
  margin-top: 19px;
}
.left-nav-content .main-nav ul.menuList li,
.left-nav-content .footer-nav ul.menuList li,
.left-nav-content .main-nav ul.footer-menuList li,
.left-nav-content .footer-nav ul.footer-menuList li {
  padding: 1.1rem;
}
.left-nav-content .main-nav ul.menuList li.active,
.left-nav-content .footer-nav ul.menuList li.active,
.left-nav-content .main-nav ul.footer-menuList li.active,
.left-nav-content .footer-nav ul.footer-menuList li.active {
  background-color: #2FACB2;
}

.left-nav-content .main-nav ul.menuListAdmin li.active {
  background-color: #2FACB2;
}

.left-nav-content .main-nav ul.menuList li.active a,
.left-nav-content .footer-nav ul.menuList li.active a,
.left-nav-content .main-nav ul.footer-menuList li.active a,
.left-nav-content .footer-nav ul.footer-menuList li.active a {
  color: #fff;
}

.left-nav-content .main-nav ul.menuListAdmin li.active a {
  color: #fff;
}

.left-nav-content .main-nav ul.menuListAdmin li a {
  text-decoration: none;
  color: #555;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.left-nav-content .main-nav ul.menuList li.active .icon-globe,
.left-nav-content .footer-nav ul.menuList li.active .icon-globe,
.left-nav-content .main-nav ul.footer-menuList li.active .icon-globe,
.left-nav-content .footer-nav ul.footer-menuList li.active .icon-globe {
  background: url('./images/GlobeIconWhite.svg') no-repeat 28px;
}
.left-nav-content .main-nav ul.menuList li.active .icon-product,
.left-nav-content .footer-nav ul.menuList li.active .icon-product,
.left-nav-content .main-nav ul.footer-menuList li.active .icon-product,
.left-nav-content .footer-nav ul.footer-menuList li.active .icon-product {
  background: url('./images/ProductIconWhite.svg') no-repeat 28px;
}
.left-nav-content .main-nav ul.menuList li.active .icon-product2,
.left-nav-content .footer-nav ul.menuList li.active .icon-product2,
.left-nav-content .main-nav ul.footer-menuList li.active .icon-product2,
.left-nav-content .footer-nav ul.footer-menuList li.active .icon-product2 {
  background: url('./images/ProductIconWhite2.svg') no-repeat 28px;
}

/* added for new Samples Icon */
.left-nav-content .main-nav ul.menuList li.active .icon-samples,
.left-nav-content .footer-nav ul.menuList li.active .icon-samples,
.left-nav-content .main-nav ul.footer-menuList li.active .icon-samples,
.left-nav-content .footer-nav ul.footer-menuList li.active .icon-samples {
  background: url('./images/samples-white.png') no-repeat 22px;
  background-size: 35px;
}
.left-nav-content .main-nav ul.menuList li.active:hover .icon-samples,
.left-nav-content .footer-nav ul.menuList li.active:hover .icon-samples,
.left-nav-content .main-nav ul.footer-menuList li.active:hover .icon-samples,
.left-nav-content .footer-nav ul.footer-menuList li.active:hover .icon-samples {
  background: url('./images/samples.png') no-repeat 22px;
  background-size: 35px;
}
.left-nav-content .main-nav ul.menuList li.active .icon-marketing,
.left-nav-content .footer-nav ul.menuList li.active .icon-marketing,
.left-nav-content .main-nav ul.footer-menuList li.active .icon-marketing,
.left-nav-content .footer-nav ul.footer-menuList li.active .icon-marketing {
  background: url('./images/MarketingIconWhite.svg') no-repeat 28px;
}
.left-nav-content .main-nav ul.menuList li.active .icon-creative,
.left-nav-content .footer-nav ul.menuList li.active .icon-creative,
.left-nav-content .main-nav ul.footer-menuList li.active .icon-creative,
.left-nav-content .footer-nav ul.footer-menuList li.active .icon-creative {
  background: url('./images/CreativeIconWhite.svg') no-repeat 28px;
}
.left-nav-content .main-nav ul.menuList li.active .icon-publication,
.left-nav-content .footer-nav ul.menuList li.active .icon-publication,
.left-nav-content .main-nav ul.footer-menuList li.active .icon-publication,
.left-nav-content .footer-nav ul.footer-menuList li.active .icon-publication {
  background: url('./images/PublicationIconWhite.svg') no-repeat 28px;
}
.left-nav-content .main-nav ul.menuList li.active .icon-admin,
.left-nav-content .footer-nav ul.menuList li.active .icon-admin,
.left-nav-content .main-nav ul.footer-menuList li.active .icon-admin,
.left-nav-content .footer-nav ul.footer-menuList li.active .icon-admin {
  background: url('./images/AdminIconWhite.svg') no-repeat 50%;
}
.left-nav-content .main-nav ul.menuList li.active:hover .icon-globe,
.left-nav-content .footer-nav ul.menuList li.active:hover .icon-globe,
.left-nav-content .main-nav ul.footer-menuList li.active:hover .icon-globe,
.left-nav-content .footer-nav ul.footer-menuList li.active:hover .icon-globe {
  background: url('./images/GlobeIcon.svg') no-repeat 28px;
}
.left-nav-content .main-nav ul.menuList li.active:hover .icon-product,
.left-nav-content .footer-nav ul.menuList li.active:hover .icon-product,
.left-nav-content .main-nav ul.footer-menuList li.active:hover .icon-product,
.left-nav-content .footer-nav ul.footer-menuList li.active:hover .icon-product {
  background: url('./images/ProductIcon.svg') no-repeat 28px;
}
.left-nav-content .main-nav ul.menuList li.active:hover .icon-product2,
.left-nav-content .footer-nav ul.menuList li.active:hover .icon-product2,
.left-nav-content .main-nav ul.footer-menuList li.active:hover .icon-product2,
.left-nav-content .footer-nav ul.footer-menuList li.active:hover .icon-product2 {
  background: url('./images/ProductIcon2.svg') no-repeat 28px;
}
.left-nav-content .main-nav ul.menuList li.active:hover .icon-marketing,
.left-nav-content .footer-nav ul.menuList li.active:hover .icon-marketing,
.left-nav-content .main-nav ul.footer-menuList li.active:hover .icon-marketing,
.left-nav-content .footer-nav ul.footer-menuList li.active:hover .icon-marketing {
  background: url('./images/MarketingIcon.svg') no-repeat 28px;
}
.left-nav-content .main-nav ul.menuList li.active:hover .icon-creative,
.left-nav-content .footer-nav ul.menuList li.active:hover .icon-creative,
.left-nav-content .main-nav ul.footer-menuList li.active:hover .icon-creative,
.left-nav-content .footer-nav ul.footer-menuList li.active:hover .icon-creative {
  background: url('./images/CreativeIcon.svg') no-repeat 28px;
}
.left-nav-content .main-nav ul.menuList li.active:hover .icon-publication,
.left-nav-content .footer-nav ul.menuList li.active:hover .icon-publication,
.left-nav-content .main-nav ul.footer-menuList li.active:hover .icon-publication,
.left-nav-content .footer-nav ul.footer-menuList li.active:hover .icon-publication {
  background: url('./images/PublicationIcon.svg') no-repeat 28px;
}
.left-nav-content .main-nav ul.menuList li.active:hover .icon-admin,
.left-nav-content .footer-nav ul.menuList li.active:hover .icon-admin,
.left-nav-content .main-nav ul.footer-menuList li.active:hover .icon-admin,
.left-nav-content .footer-nav ul.footer-menuList li.active:hover .icon-admin {
  background: url('./images/AdminIcon.svg') no-repeat 50%;
}
.left-nav-content .main-nav ul.menuList li a,
.left-nav-content .footer-nav ul.menuList li a,
.left-nav-content .main-nav ul.footer-menuList li a,
.left-nav-content .footer-nav ul.footer-menuList li a {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  color: #555;
}
.left-nav-content .footer-nav ul.footer-menuListAdmin li a {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  color: #555;
}

.left-nav-content .main-nav ul.menuList li a span,
.left-nav-content .footer-nav ul.menuList li a span,
.left-nav-content .main-nav ul.footer-menuList li a span,
.left-nav-content .footer-nav ul.footer-menuList li a span {
  position: relative;
  display: block;
  margin: 2px;
}

.left-nav-content .main-nav ul.menuListAdmin li a span {
  position: relative;
  display: block;
  margin: 2px;
}

.left-nav-content .main-nav ul.menuList li:hover,
.left-nav-content .footer-nav ul.menuList li:hover,
.left-nav-content .main-nav ul.footer-menuList li:hover,
.left-nav-content .footer-nav ul.footer-menuList li:hover {
  background-color: #d8fafc;
}

.left-nav-content .main-nav ul.menuListAdmin li:hover  {
  background-color: #d8fafc;
}

.left-nav-content .main-nav ul.menuList li:hover a,
.left-nav-content .footer-nav ul.menuList li:hover a,
.left-nav-content .main-nav ul.footer-menuList li:hover a,
.left-nav-content .footer-nav ul.footer-menuList li:hover a {
  color: #555;
}

.left-nav-content .main-nav ul.menuListAdmin li:hover a {
  color: #555;
}

.left-nav-content .main-nav ul.menuList li:last-child,
.left-nav-content .footer-nav ul.menuList li:last-child,
.left-nav-content .main-nav ul.footer-menuList li:last-child,
.left-nav-content .footer-nav ul.footer-menuList li:last-child {
  border-bottom: 1px solid #D7DCDD;
}
.left-nav-content .main-nav ul.menuList li:last-child a,
.left-nav-content .footer-nav ul.menuList li:last-child a,
.left-nav-content .main-nav ul.footer-menuList li:last-child a,
.left-nav-content .footer-nav ul.footer-menuList li:last-child a {
  margin-bottom: 20px;
}
.left-nav-content .main-nav ul.menuList li.admin-section,
.left-nav-content .footer-nav ul.menuList li.admin-section,
.left-nav-content .main-nav ul.footer-menuList li.admin-section,
.left-nav-content .footer-nav ul.footer-menuList li.admin-section {
  border: none !important;
}
.left-nav-content .main-nav ul.menuList li.admin-section a,
.left-nav-content .footer-nav ul.menuList li.admin-section a,
.left-nav-content .main-nav ul.footer-menuList li.admin-section a,
.left-nav-content .footer-nav ul.footer-menuList li.admin-section a {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}
.left-nav-content .main-nav ul.bottom,
.left-nav-content .footer-nav ul.bottom {
  margin-bottom: 7px;
}
.left-nav-content .footer-nav {
  position: static;
  bottom: 0;
}
.left-nav-content .footer-nav .leftnav-footer {
  bottom: 0;
  background: #fff;
  padding-bottom: 11.5px;
  margin-right: 0.6rem;
  position: absolute;
}
.left-nav-content .footer-nav .leftnav-footer img {
  width: 75%;
}
.left-nav-content .footer-nav .leftnav-footer imgpath {
  fill: #fff;
}

/* added for logo */
.leftNav-logoImage {
  width: 80px;
  height: auto;
}

.leftNav-logo {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.sidenav-popupFix {
  z-index: 1 !important;
}

.user-name p {
  width: 20%;
  margin: 0px !important;
  display: inline-block;
  padding: 0px;
  text-align: right;
}

#userName {
  width: 75% ;
  overflow: hidden ;
  display: inline-block ;
  white-space: nowrap ;
  text-overflow: ellipsis ;
  vertical-align: text-bottom ;
  padding-left: 5px;
  text-align: left;
}

/* new notification style - suraj */
.notifier-container .notification.userInform {
  color: #000;
  background-color: #e1eeee;
  border-color: #000;
}
.notifier-container .notification.userInform.overlay {
  margin: 25vh auto;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.user-overlay {
  background: transparent !important;
}

