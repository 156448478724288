.profile-modal .modal-content {
  padding: 2rem 3rem;
}
.profile-modal .modal-content .form-container {
  max-height: 400px;
  overflow-y: auto;
  padding: 0 .5rem;
}
.profile-modal .modal-header {
  border-radius: 16px 16px 0 0;
  text-align: left;
  padding: 1.5rem 3rem;
}
.profile-modal .modal-footer {
  margin: 3.3rem 0 1rem;
  text-align: center;
}
.profile-modal .modal-footer [type="button"],
.profile-modal .modal-footer [type="submit"] {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1.4rem;
  text-transform: uppercase;
  border-radius: 4px;
  border: solid 1px #555555;
  background-color: #fff;
  cursor: pointer;
  padding: 1rem 1.2rem;
  margin-left: 1.5rem;
}
.profile-modal .modal-footer [type="button"]:hover,
.profile-modal .modal-footer [type="submit"]:hover {
  background-color: #555555;
  color: #fff;
}
.profile-modal .user-profile-form .form-group {
  padding: 1.3rem 0;
}
.profile-modal .user-profile-form .form-group a {
  margin: .7rem 0;
}
.profile-modal .user-profile-form .form-group select {
  margin: 0;
}
.profile-modal .user-profile-form .form-group .form-label {
  display: inline-block;
  min-width: 30%;
  margin: .7rem 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  float: left;
  clear: both;
  text-align: left;
}
.profile-modal .user-profile-form .form-group select {
  pointer-events: visible;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  height: 30px;
  border: solid 1px #d7dcdd;
  box-shadow: 0 0 3px 0 rgba(217, 217, 217, 0.5);
  width: 42%;
  padding: .5rem 1rem;
}
.profile-modal .user-profile-form .form-group .form-control {
  pointer-events: none;
  border: none;
  width: 70%;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}
.profile-modal .user-profile-form .form-group .form-control-label {
  border: none;
  box-shadow: none;
  display: block;
  text-align: left;
  height: 16px;
  margin-top: .7rem;
}
.profile-modal .user-profile-form .form-group .form-control-label.speciality-label {
  width: 70%;
  display: inline-block;
  -ms-word-break: break-word;
  word-break: break-word;
}
.profile-modal .user-profile-form .form-group a {
  display: block;
  text-align: left;
}
.profile-modal .user-profile-form .form-edit .form-group a {
  margin: .7rem 0;
}
.profile-modal .user-profile-form .form-edit .form-group .form-label {
  margin: .7rem 0;
}
.profile-modal .user-profile-form .form-edit .form-group select {
  margin: 0;
}
.profile-modal .user-profile-form .form-edit .form-group .form-control {
  pointer-events: visible;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  height: 30px;
  border: solid 1px #d7dcdd;
  box-shadow: 0 0 3px 0 rgba(217, 217, 217, 0.5);
  border-radius: 2px;
  padding: .5rem 1rem;
  outline: none;
}
.profile-modal .user-profile-form .form-edit .form-group .form-control.invalid {
  border: solid 1px #ff0000 ;
}
.profile-modal .user-profile-form .form-edit .form-group .form-control-label {
  border: none;
  box-shadow: none;
  margin-top: .7rem;
  display: block;
  text-align: left;
}
.profile-modal .user-profile-form .form-edit .form-group .form-control-label.speciality-label {
  width: 70%;
  display: inline-block;
  -ms-word-break: break-word;
  word-break: break-word;
}
.profile-modal .user-change-password {
  display: block;
}
.profile-modal .user-change-password .form-group {
  width: 100%;
  margin-bottom: 1rem;
}
.profile-modal .user-change-password .form-group .form-label {
  width: 100%;
  text-align: left;
  position: relative;
  display: block;
  margin-bottom: 4px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
  line-height: 2;
}
.profile-modal .user-change-password .form-group .form-control {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  height: 35px;
  border: solid 1px #d7dcdd;
  box-shadow: 0 0 3px 0 rgba(217, 217, 217, 0.5);
  border-radius: 2px;
  padding: .5rem 1rem;
  outline: none;
}
.profile-modal .user-change-password .form-group .form-control.invalid {
  border: solid 1px #ff0000 ;
}