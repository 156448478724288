.left {
  float: left !important;
}
.right {
  float: right !important;
}
.filter-strip-holder {
  height: auto;
  background-color: #e7eaea;
  overflow-y: auto;
  width: 100%;
}
.filter-strip-holder .filter-strip {
  margin: 0;
  padding: 0 1.5rem;
}
.filter-strip-holder .filter-strip li {
  position: relative;
  display: inline-block;
  padding: 0.3rem 3rem 0.3rem 1rem;
  background-color: #e6ebeb;
  border: solid 1px #c7cecf;
  border-radius: 100px;
  color: #525E61;
  margin: 5px;
}
.filter-strip-holder .filter-strip li .remove {
  display: inline-block;
  width: 11px;
  height: 11px;
  position: relative;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}
/* .filter-strip-holder .filter-strip li .remove:before {
  content: '';
  width: 11px;
  height: 1px;
  background: #525E61;
  display: inline-block;
  position: absolute;
  top: 5px;
  left: 0;
}
.filter-strip-holder .filter-strip li .remove:after {
  content: '';
  position: absolute;
  height: 11px;
  width: 1px;
  background: #525E61;
  left: 5px;
  top: 0;
} */
/*Dashboard css*/
html,
body {
  overflow: hidden;
}
.dash-board-container {
  background-color: #d7dcdd;
  width: 100%;
  float: left;
  height: 100%;
  /*max-height: 600px;
    overflow: scroll;*/
}
.dash-board-container .dash-board {
  width: 100%;
  float: left;
  height: 100%;
}
.dash-board-container .dash-board h2 {
  font-weight: 300;
  color: #2facb2;
  border-bottom: solid 1px #f5f5f5;
  margin: 0;
  padding: 10px;
}
.dash-board-container .dash-board select {
  pointer-events: visible;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  height: 30px;
  border: none;
  box-shadow: 0 0 3px 0 rgba(217, 217, 217, 0.5);
  width: 100px;
  margin: -5px;
  float: right;
  color: #414c4e;
}
.dash-board-container .dash-board ul li {
  display: flex;
}
.dash-board-container .dash-board ul li span {
  flex: 1;
  text-align: right;
}
.dash-board-container .dash-board .dash-board-left {
  width: 60%;
  float: left;
  height: 89%;
}
.dash-board-container .dash-board .dash-board-left .priority-alerts {
  width: 60%;
  float: left;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 18px 0 0px 18px;
  padding: 10px;
  height: 100%;
  min-height: 300px;
  overflow-y: scroll;
}
.dash-board-container .dash-board .dash-board-left .priority-alerts ul {
  list-style: none;
}
.dash-board-container .dash-board .dash-board-left .priority-alerts ul li {
  opacity: 0.66;
  border-radius: 5px;
  background-color: #eff2f3;
  padding: 15px;
  margin: 5px 0;
  font-size: 19px;
  font-weight: 300;
  text-align: left;
  color: #525e61;
  line-height: 20px;
  cursor: pointer;
}
.dash-board-container .dash-board .dash-board-left .priority-alerts ul li.active {
  background-color: #2FACB2;
  color: #ffffff;
}
.dash-board-container .dash-board .dash-board-left .priority-alerts ul li:hover {
  background-color: #2FACB2;
  color: #ffffff;
}
.dash-board-container .dash-board .dash-board-left .priority-alerts ul li label {
  width: 80%;
  display: inline-block;
  word-break: break-word;
}
.dash-board-container .dash-board .dash-board-left .priority-alerts ul li span {
  font-weight: 300;
  float: right;
  color: #166a8f;
  font-size: 28px;
  line-height: 20px;
}
.dash-board-container .dash-board .dash-board-left .alerts-breakdown {
  width: 36%;
  float: right;
  margin: 18px 0;
  height: 100%;  
  background-color: #ffffff;
  border-radius: 15px;
}
.dash-board-container .dash-board .dash-board-left .alerts-breakdown h2 span {
  font-size: 14px;
}
.dash-board-container .dash-board .dash-board-left .alerts-breakdown .a-box {
  background: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  /* overflow-y: auto; */
  padding: 10px;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
}
.dash-board-container .dash-board .dash-board-left .alerts-breakdown .a-box .tree-node-list .alert-count {
  float: right;
  color: #166a8f;
  position: relative;
  top: .4rem;
}
.dash-board-container .dash-board .dash-board-left .alerts-breakdown .a-pointer {
  border: solid 10px transparent;
  border-right-color: #fff;
  position: absolute;
  margin: 15px 0 0 -20px;
}

.dash-board-container .dash-board .dash-board-center {
  width: 20%;
  height: 100%;
  float: left;
}
/* .dash-board-container .dash-board .dash-board-center .priority-alerts {
  width: 95%;
  float: left;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 18px 0 0px 18px;
  padding: 10px;
  min-height: 300px;
  overflow-y: scroll;
}
.dash-board-container .dash-board .dash-board-center .priority-alerts ul {
  list-style: none;
}
.dash-board-container .dash-board .dash-board-center .priority-alerts ul li {
  opacity: 0.66;
  border-radius: 5px;
  background-color: #eff2f3;
  padding: 15px;
  margin: 5px 0;
  font-size: 19px;
  font-weight: 300;
  text-align: left;
  color: #525e61;
  line-height: 20px;
  cursor: pointer;
}
.dash-board-container .dash-board .dash-board-center .priority-alerts ul li.active {
  background-color: #2FACB2;
  color: #ffffff;
}
.dash-board-container .dash-board .dash-board-center .priority-alerts ul li:hover {
  background-color: #2FACB2;
  color: #ffffff;
}
.dash-board-container .dash-board .dash-board-center .priority-alerts ul li label {
  width: 80%;
  display: inline-block;
  word-break: break-word;
}
.dash-board-container .dash-board .dash-board-center .priority-alerts ul li span {
  font-weight: 300;
  float: right;
  color: #166a8f;
  font-size: 28px;
  line-height: 20px;
} */
/* .dash-board-container .dash-board .dash-board-center .alerts-breakdown {
  margin: 18px 0;
  height: 100%;
}
.dash-board-container .dash-board .dash-board-center .alerts-breakdown h2 span {
  font-size: 14px;
}
.dash-board-container .dash-board .dash-board-center .alerts-breakdown .a-box {
  background: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow-y: auto;
  padding: 10px;
  height: 89%;
}
.dash-board-container .dash-board .dash-board-center .alerts-breakdown .a-box .tree-node-list .alert-count {
  float: right;
  color: #166a8f;
  position: relative;
  top: .4rem;
}
.dash-board-container .dash-board .dash-board-center .alerts-breakdown .a-pointer {
  border: solid 10px transparent;
  border-right-color: #fff;
  position: absolute;
  margin: 85px 0 0 -20px;
} */




.dash-board-container .dash-board .dash-board-right {
  width: 40%;
  float: right;
  height: 100%;
}
.dash-board-container .dash-board .dash-board-right .headbox {
  font-weight: 300;
  color: #2facb2;
  border-bottom: solid 1px #f5f5f5;
  margin: 0;
  padding: 10px;
  font-size: 21px;
  float: left;
  width: 100%;
}
.dash-board-container .dash-board .dash-board-right .headbox .heading-text {
  float: left;
}
.dash-board-container .dash-board .dash-board-right .headbox .icon-notification {
  float: left;
}
.dash-board-container .dash-board .dash-board-right .headbox .icon-acc {
  background: url('../common/icons/accomplishment.svg') no-repeat;
  content: '';
  float: left;
  height: 28px;
  width: 32px;
}
.dash-board-container .dash-board .dash-board-right .recent-alerts {
  float: left;
  width: 90%;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
  margin: 18px;
  min-height: 180px;
}
.dash-board-container .dash-board .dash-board-right .recent-alerts ul {
  clear: both;
  overflow-y: auto;
  padding: 5px;
  margin: 0;
}
.dash-board-container .dash-board .dash-board-right .recent-alerts ul li {
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  color: #414c4e;
  margin-bottom: 15px;
}
.dash-board-container .dash-board .dash-board-right .recent-alerts ul li span {
  float: right;
  font-size: 16px;
  font-weight: 300;
  text-align: right;
  color: #166a8f;
}
.dash-board-container .dash-board .dash-board-right .accomplishments {
  float: left;
  width: 90%;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
  margin: 20 18px;
  /* min-height: 180px; */
  height: 42.5%;
  overflow-y: scroll;
}
.dash-board-container .dash-board .dash-board-right .accomplishments ul {
  overflow-y: auto;
  padding: 5px;
}
.dash-board-container .dash-board .dash-board-right .accomplishments ul li {
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  color: #414c4e;
  margin-bottom: 15px;
}
.dash-board-container .dash-board .dash-board-right .accomplishments ul li span {
  float: right;
  font-size: 16px;
  font-weight: 300;
  text-align: right;
  color: #166a8f;
  cursor: pointer;
}
.dash-board-container .dash-board .dash-board-right .accomplishments ul .accoplish-cursor {
  cursor: default;
}
.left-txt {
  width: 87%;
  cursor: pointer;
}

.react-spinner {
    display: inline-block;
    position:relative;
    left:40%;
}

@media screen and (max-width: 1200px) {
  .dash-board-container .dash-board .dash-board-center .alerts-breakdown .a-box {
    background: white;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    overflow-y: auto;
    padding: 10px;
    height: 89%;
}

.dash-board-container .dash-board .dash-board-left {
    width: 40%;
    float: left;
    height: 89%;
}

.dash-board-container .dash-board .dash-board-right .accomplishments {
  float: left;
  width: 90%;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
  margin: 20 18px;
  /* min-height: 180px; */
  height: 41.5%;
  overflow-y: scroll;
}

}

.notificationPosFix {
  top: 15px;
  left: 745px;
  position: fixed !important;
}

.notificationPosFixProd {
  top: 15px;
  left: 405px;
  position: fixed !important;
}


/* message section */
/* */

.message-icon-dashboard {
  background: url('../common/icons/mDash.png') no-repeat;
  background-size: 35px;
  background-position: center;
  width: 35px;
  float: left;
  height: 30px;
  margin-right: 5px;
  font-size: 16px;
  text-align: center;
  line-height: 25px;
}

.message-items {
  display: flex;
  font-size: 14px !important;
  flex-wrap: wrap;
  cursor: pointer !important;
}

/* .message-items:hover {
  background-color: #2facb25e;
} */

.style-number {
  flex-basis: 20%;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-name {
  flex-basis: 45%;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sender-name {
  flex-basis: 25%;
  text-align: center;
}

.sent-time {
  flex-basis: 10%;
  color: #166a8f;
}

.message-desc {
  flex-basis: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #afb6b8;
}
