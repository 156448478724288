#cw-container {
    width: 100%;
    height: 100%;
    color: #8c8d8e;

    *:focus {
        outline: 0 none;
        box-shadow: none;
    }

    .cw-details {
        height: 170px;

        .cw-top-section {
            margin: 10px 50px;

            .cw-6 {
                width: 50%;
                float: left;
                text-align: end;

                .row-small {
                    width: 48%;
                    display: inline-block;
                    margin-bottom: 5px;

                    .cw-input {
                        width: 62.5%;
                    }
                }
            }

            .cw-3 {
                width: 20%;
                float: left;
                text-align: end;
                .cw-input {
                    width: 50%;
                }
                .cw-copyStatus {
                    display: inherit;
                    color: #6999ca;
                    margin-top: 40px;
                    font-weight: bold;
                }
                .cw-copyStatus:nth-child(2) {
                    margin-left: 5px;
                    font-style: italic;
                }
            }

            .cw-input {
                height: 25px;
                width: 78%;
            }

            .cw-label {
                width: 20%;
            }

            .cw-input-small {
                height: 25px;
            }

            .cw-label:after {
                content: ':';
                margin-right: 3px;
            }

            .row {
                margin-bottom: 5px;
            }
        }
    }
    .cw-body {
        height: calc(100% - 170px - 120px);

        .cw-right-section {
            float: left;
            width: 61%;
            height: 100%;
            overflow-y: scroll;

            // added to override default Jqte editor height 140px
            .jqte_editor, .jqte_source {
                min-height: 12.5em;
                max-height: 12.5em;
            }

            .cw-info-label {
                font-weight: bold;
                display: block;
                margin-bottom: 10px;
            }

            .row {
                margin-bottom: 20px;
            }

            .copy-notes {
                width: 100%;
                height: 6em;
                resize: unset;
            }

            .cw-reject {
                display: block;
                margin-left: auto;
                margin-right: auto;
                height: 35px;
                width: 100px;
                border: 1px solid #555;
                cursor: pointer;
                border-radius: 7px;

                &:hover {
                    background: #f55418;
                    color: #fff;
                }

            }
        }

        .cw-left-section {
            float: left;
            width: 38%;
            height: 100%;
            padding: 0 20px;

            .tabs {
                height: 100%;

                .tabs-nav {
                    margin: 0px;
                    li {
                        display: inline-block;
                        margin-left: 15px;
                    }
                    li:first-child {
                        display: inline-block;
                        margin: 0;
                    }
                }

                .activeTab {
                    background-color: #ecfcfd;
                    border-bottom: none !important;
                    position: relative;
                    top: 1px;
                }

                .tab-items {
                    width: 140px;
                    height: 25px;
                    line-height: 20px;
                    text-align: center;
                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;
                    cursor: pointer;
                    color: #8c8d8e;
                    font-weight: bolder;
                    border-left: 1px solid;
                    border-right: 1px solid;
                    border-top: 1px solid;
                }

                .tabItems {
                    border: 1px solid #8c8d8e;
                    overflow-y: scroll;
                    height: calc(100% - 60px);

                    .row:first-child {
                        text-align: end;
                        margin-top: 5px;
                    }

                    .cw-copyStatus {
                        display: inline;
                        color: #6999ca;
                        font-weight: bold;
                    }

                    .cw-copyStatus:nth-child(2) {
                        margin-left: 5px;
                        font-style: italic;
                        padding-right: 3px;
                    }

                    .cw-pi-section-heading {
                        display: block;
                        color: #000;
                        font-weight: bold;
                        font-size: 13px;
                        margin-left: 5px;
                    }

                    .input-sections {
                        display: flex;
                        margin-bottom: 5px;
                        width: 98%;

                        .cw-pi-label {
                            place-self: flex-end;
                            flex-basis: 40%;
                            line-height: 25px;
                            font-weight: bold;
                            text-align: start;
                            padding-left: 15px;
                        }
                        .cw-pi-input {
                            height: 25px;
                            flex-basis: 70%;
                        }
                    }

                    .large-input {
                        .cw-pi-label {
                            place-self: baseline;
                        }
                        .cw-pi-input {
                            height: 70px;
                            resize: none;
                        }
                    }

                    .input-checkbox-sections {
                        font-weight: bold;
                        display: flex;
                        width: 98%;

                        .cw-pi-input:first-child {
                            margin-right: 2%;
                        }
                        .cw-pi-input {
                            cursor: pointer;
                            -webkit-appearance: none;
                            appearance: none;
                            border-radius: 1px;
                            border: 1px solid #d7dcdd;
                            box-sizing: border-box;
                            position: relative;
                            box-sizing: content-box;
                            width: 30px;
                            height: 25px;
                            &:checked{
                                background-color: #6999ca;
                            }

                            &::before {
                                position: absolute;
                                left: 0;
                                top: 40%;
                                height: 50%;
                                width: 2px;
                                background-color: #fff;
                                content: "";
                                transform: translateX(10px) rotate(-45deg);
                                transform-origin: left bottom;
                            }

                            &::after {
                                position: absolute;
                                left: 0;
                                bottom: 3px;
                                height: 2px;
                                width: 80%;
                                background-color: #fff;
                                content: "";
                                transform: translateX(11px) rotate(-45deg);
                                transform-origin: left bottom;
                            }

                            &:focus{
                                outline: 0 none;
                                box-shadow: none;
                            }
                        }
                        .cw-pi-label {
                            flex-basis: 45%;
                            padding-left: 15px;
                            line-height: 30px;
                        }
                    }

                    .image-container {
                        display: flex;
                        height: 100%;
                        .thumbnails {
                            flex-basis: 25%;
                            overflow-y: scroll;
                            overflow-x: hidden;
                            img {
                                max-width: 100px;
                                max-height: 100px;
                                cursor: pointer;
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                                padding: 5px;
                                width: auto;
                                height: auto;
                            }
                        }
                        .main-images {
                            flex-basis: 75%;
                            height: 25em;
                            img {
                                margin-left: auto;
                                margin-right: auto;
                                padding: 5px;
                                display: block;
                                max-width: 290px;
                                max-height: 305px;
                                width: auto;
                                height: auto;
                                padding: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.cw-footer {
    height: 120px;
    background-color: #e0eeee;
    display: flex;
    justify-content: flex-end;

    .cw-pi-footer {
        margin-right: 140px;
    }

    .cw-footer-button:disabled {
        color: #d4d9d9;
    }

    .cw-footer-button {
        height: 30px;
        border: 2px solid #d4d9d9;
        border-radius: 50px;
        margin-top: 10px;
        margin-right: 10px;
        flex-basis: 150px;
        transition-duration: 0.3s;

        cursor: pointer;
        .fa {
            margin-right: 10px;
        }
    }

    .approve {
        order: 1;
        .fa {
            color: #01b050;
        }
    }
    .reject {
        order: 2;
        .fa {
            color: #c41713;
        }
    }
    .submit {
        order: 3;
        margin-left: 165px;
        .fa {
            color: #00b0ef;
        }
    }
    .save {
        order: 4;
        .fa {
            color: #4aaae2;
        }
    }
    .cancel {
        order: 5;
        .fa {
            color: #fd1a16;
        }
    }

    .cw-pulled {
        flex-basis: 120px;
        font-size: 10px;
    }

    .cw-reduce-margin {
        margin-left: 130px !important;
    }
}

.tabItems::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px !important;
}

.cw-right-section::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px !important;
}

#copywritingAgGrid .ag-body-viewport-wrapper .ag-layout-normal.ag-body-viewport {
    height: 100%;
}

#copywritingAgGrid .ag-root-wrapper {
    width: 95%;
}

.copywriting-grid-footer {
    background-color: #e0eeee;

    .copywriting-grid-footer-button {
        background-color: #e9f3f3;
        border-radius: 20px;
        margin-left: 25%;
        margin-top: 14px;
        color: #000;
        font-size: 12px;
        border: 2px solid #d4d9d9;
        padding: 3px 30px;
        cursor: pointer;

        .footer-arrow {
            color: #29406a;
            font-size: 20px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.cw-center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100px;
    max-height: 50px;
    height: auto;
    width: auto;
}
