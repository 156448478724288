.creative-scan-action {
    .modal-content {
        display: flex;

        .content-right, .content-left {
            flex-basis: 50%;
        }

        .content-left {
            margin-right: 30px;

            .creative-footer {
                display: flex;
                justify-content: space-between;
                margin-top: 70px;

                .footer-btn {
                    margin: 0;
                }
            }
        }

        .top-label {
            display: block;
            margin: 5px 0;
        }
    }
}

.creative-scan-actions {
    padding: 0px;

    .record-options {
        ul {
            width: 110px;
            padding: 0;
            margin: 5px;
        }
    }
}

.photo-direction-col {
    .message-editor-header{
        .title {
            text-align: center;
            font-weight: bold;
        }

        .details {
            display: inline-flex;
            font-size: 10px;
            margin: 6px 0px;

            .style {
                width: 30%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                span {
                    color: #b6b6b6;
                }
            }

            .prodname {
                font-weight: bold;
            }
        }
    }

    .message-editor-body {
        .input-message {
            width: 100%;
            height: 50px;
            resize: none;
        }

        .message-section {
            .body-text {
                width: 100%;
            }

            .date-created {
                width: 50%;
                display: inline-block;
                color: #b6b6b6;
                font-style: italic;
            }

            .from-user {
                width: 50%;
                display: inline-block;
                color: #b6b6b6;
                text-align: right;
            }
        }
    }
}

.creative-footer {
    .record-creation-holder {
        padding: 0;
    }
}

.ag-cell-wrapper.ag-row-group {
    align-items: center !important;
}
