.help-bug-container {
    display: inline-block;

    .fa {
        font-size: 12px;
        color: #18b9c2;
    }

    .help-bug-content {
        position: absolute;
        z-index: 999;
        width: 210px;
        border: 1px solid #8fcdcf;
        background-color: white;
        border-radius: 15px;
        font-size: 12px;

        .help-bug-text {
            padding: 0px 5px;
            color: #000;
            height: 230px;
            overflow: auto;
        }

        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 6px;
            height: 6px;
        }
    }
}

.help-bugs-page-header {
    position: relative;
    top: 28px;
    right: 20px;
}

// Position for all the sections

.drawer-title {
    .help-bug-content {
        left: 70px;
    }
}

.help-bugs-page-header {
    .help-bug-content {
        right: 250px;
    }
}
