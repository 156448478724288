.right-content {
    margin-left: 100px;
    transition: all .3s ease;
}

/* added to change color of activateItemsOnWebsite */
.activateItemsOnWebsite {
    color: #18b9c2 !important;
}

.right-content-container .drawer-title {
    margin: 1.5rem 0 1.5rem 1.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: 21px;
    color: #18b9c2;
}
.right-content-container .drawer-title .close-btn {
    padding: 0.7rem 0;
    display: block;
    position: relative;
    float: right;
    cursor: pointer;
}
.right-content-container.drawer-open .drawer {
    width: 300px;
}
.right-content-container.drawer-open .drawer .puller {
    background-color: #FFF;
}
.right-content-container.drawer-open .content-wrapper {
    width: calc(100% - 300px - 15px) !important;
    margin-left: 300px;
}
.right-content-container.drawer-open .pane-arrow {
    top: 50%;
    left: -3px;
    position: relative;
    display: block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid #10b7bf;
    -webkit-transform: rotate(-90deg) !important;
    -moz-transform: rotate(-90deg) !important;
    -ms-transform: rotate(-90deg) !important;
    -o-transform: rotate(-90deg) !important;
    transform: rotate(-90deg) !important;
}
.right-content-container:not(.drawer-open) .left-container {
    display: none;
}
.right-content-container .drawer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: initial;
    width: 15px;
    transition: width 0.1s;
    z-index: 0; /* added so that left panel shows well with ag-grid */
}
.right-content-container .drawer .puller {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fafafa;
    box-shadow: 2px 0px 2px #d2d2d2;
    cursor: pointer;
    height: 100%;
}
.right-content-container .drawer .puller .pane-arrow {
    top: 50%;
    left: -3px;
    position: relative;
    display: block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid #10b7bf;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
.right-content-container .drawer .search-refine-holder .drawer-title {
    margin: 1.5rem 0 0.5rem 1.5rem;
}
.right-content-container .drawer .search-refine-holder .drawer-content {
    padding: 0rem 1.5rem 1rem;
    display: block;
}
.right-content-container .drawer .search-refine-holder .drawer-content .section-title {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    letter-spacing: 1.2px;
    font-size: 14px;
    text-transform: uppercase;
    color: #AEB6B8;
    margin: .5rem 0;
}
.right-content-container .drawer .search-refine-holder .drawer-content .show-deleted-content {
    display: inline-block;
    margin-bottom: 2rem;
    float: left;
}
.right-content-container .drawer .search-refine-holder .drawer-content .show-deleted-content .form-check-label {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
}
.right-content-container .drawer .search-refine-holder .drawer-content .show-deleted-content .form-check-label span.from-checkbox {
    background: url('../common/icons/Check box.svg') no-repeat;
    content: '';
    display: inline-block;
    height: 14px;
    width: 15px;
    position: relative;
    left: -1rem;
    top: .2rem;
}
.right-content-container .drawer .search-refine-holder .drawer-content .show-deleted-content .form-check-label .form-control-check:checked + span.from-checkbox {
    background: url('../common/icons/selected.svg') no-repeat;
}
.right-content-container .drawer .search-refine-holder .drawer-content .show-deleted-content .form-control-check {
    position: relative;
    left: -10rem;
    visibility: hidden;
}

.right-content-container .drawer .search-refine-holder .drawer-content .filter-section {
    display: inline-block;
    width: 100%;
    margin-bottom: 1rem;
    overflow-y: auto;
    height: 20%;
}

.right-content-container .drawer .search-refine-holder .drawer-content .custom-filter {
    border-top: 1px solid #d7dcdd !important;
    overflow-y: auto;
    width: 100%;
    height: 41%;
}


.right-content-container .drawer .search-refine-holder .drawer-content .custom-filter button#addCriteria {
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.right-content-container .drawer .search-refine-holder .drawer-content .custom-search {
    height: auto;
    padding: 10px;
    border: 1px solid #BDC3C7;
    background-color: #edefef;
}

.right-content-container .drawer .search-refine-holder .drawer-content .custom-search .section-title {
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 16px;
    color: #18b9c2;
}

.right-content-container .drawer .search-refine-holder .drawer-content .search-footer {
    width: 100%;
    height: 10%;
    padding: 1rem 0;
    text-align: center;
    background-color: #fff;
}

.right-content-container .drawer .search-refine-holder .drawer-content .search-footer button {
    padding: 12px 61px;
    text-transform: uppercase;
    background-color: #f15a29;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    letter-spacing: 1px;
    cursor: pointer;
}
.right-content-container .drawer .form-group {
    width: 100%;
    margin-bottom: .5rem;
}
.right-content-container .drawer .form-group .form-label {
    width: 100%;
    text-align: left;
    position: relative;
    display: block;
    margin-bottom: 2px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
    color: #525E61;
}
.right-content-container .drawer .form-group .form-check {
    margin-bottom: .5rem;
}
.right-content-container .drawer .form-group .form-check-label {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
    padding-left: 1rem;
}
.right-content-container .drawer .form-group .form-check-label span.from-checkbox {
    background: url('../common/icons/Check box.svg') no-repeat;
    content: '';
    display: inline-block;
    height: 14px;
    width: 15px;
    position: relative;
    left: -2rem;
    top: .2rem;
}
.right-content-container .drawer .form-group .form-check-label .form-control-check:checked + span.from-checkbox {
    background: url('../common/icons/selected.svg') no-repeat;
}
.right-content-container .drawer .form-group .form-control-check {
    position: relative;
    left: -10rem;
    visibility: hidden;
}
.right-content-container .drawer .form-group .form-control {
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
    height: 30px;
    border: solid 1px #D7DCDD;
    box-shadow: 0 2px 3px 0 rgba(217, 217, 217, 0.5);
    border-radius: 2px;
    padding: .5rem 1rem;
    background-color: #fff;
}

.right-content-container .drawer .form-group .form-control.invalid {
    border: solid 1px #ff0000;
}
.right-content-container .drawer .form-group .form-control.error {
    border-color: #ff9191;
    box-shadow: 0 0 5px #ff9191;
    -webkit-transition: box-shadow 0.2s;
    -moz-transition: box-shadow 0.2s;
    -ms-transition: box-shadow 0.2s;
    -o-transition: box-shadow 0.2s;
    transition: box-shadow 0.2s;
}
.right-content-container .drawer .form-group .form-fluid-select {
    width: 100%;
    border: solid 1px #D7DCDD;
    box-shadow: 0 0 3px 0 rgba(217, 217, 217, 0.5);
    border-radius: 2px;
    padding: .5rem 1rem;
}
.right-content-container .drawer .form-group .form-control-auto-width {
    width: auto;
}
.right-content-container .drawer.no-filter .search-refine-holder .drawer-content .custom-filter {
    border-top: none;
}
.right-content-container .content-wrapper {
    display: inline-block;
    width: calc(100% - 15px);
    margin-left: 15px;
}

/* was inside of creative.css*/

.right-content .right-content-container .drawer.disabled {
    background-color: #D7DCDD;
    cursor: not-allowed;
}
.right-content .right-content-container .drawer.disabled .puller {
    background-color: #D7DCDD;
    cursor: not-allowed;
}
.right-content .right-content-container .drawer.presentation-drawer {
    right: 0;
    width: 15px;
    background: #fff;
    z-index: 999;
}
.right-content .right-content-container .drawer.presentation-drawer .puller {
    left: 0;
    right: auto;
}
.right-content .right-content-container .drawer.presentation-drawer .puller .pane-arrow {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    left: 0;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content {
    display: none;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content {
    padding: 0rem 2rem 1rem;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content a.save,
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content a.clear {
    text-transform: uppercase;
    color: #555;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content a.save:hover,
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content a.clear:hover {
    color: #000;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .saved-list {
    margin-bottom: 1.5rem;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .saved-list .grid-view-dropdown {
    display: block;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .saved-list .grid-view-dropdown .selected-presentation {
    height: 3rem;
    line-height: 3rem;
    padding-right: 2.5rem;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 1.5rem;
    position: relative;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .saved-list .grid-view-dropdown .selected-presentation:after {
    right: 1rem;
    top: 1.1rem;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .saved-list .grid-view-dropdown .availabel-list {
    width: 100%;
    top: 3rem;
    left: 0;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .saved-list-actions label {
    font-size: 1.2rem;
    text-transform: uppercase;
    float: left;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .saved-list-actions .save {
    font-size: 1.2rem;
    float: right;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container {
    border: 1px solid #ccc;
    background-color: #f7f7f7;
    margin: 1rem 0;
    padding-top: 1rem;
    position: relative;
    min-height: 250px;
}

@media only screen and (min-height: 800px) {
    .right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container {
        min-height: 400px;
    }
}

.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .clear {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.2rem;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .element-list {
    list-style: none;
    max-height: 205px;
    overflow-y: auto;
    margin: 0;
}

@media only screen and (min-height: 800px) {
    .right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .element-list {
        min-height: 350px;
        max-height: 350px;
    }
}

.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .element-list .list-item {
    padding: 1rem;
    font-size: 1.3rem;
    text-transform: uppercase;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .footer {
    position: absolute;
    width: 100%;
    left: 0;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .footer .total {
    text-align: center;
    width: 50%;
    font-size: 1.2rem;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .footer .selected {
    display: block;
    text-align: center;
    width: 100%;
    font-size: 1.2rem;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-container .footer .total {
    float: right;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .selected-items-actions button {
    display: block;
    margin: 0 auto;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .panel-bottom {
    position: absolute;
    left: 2rem;
    right: 2rem;
    bottom: 1.5rem;
    max-height: 300px;
    overflow-y: auto;
    z-index: 10;
    background-color: #f7f7f7;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .panel-bottom .panel-accordions {
    margin-bottom: 1.5rem;
    max-height: 180px;
    overflow-y: auto;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .panel-bottom .panel-accordions .panel {
    margin: 0;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .panel-bottom .panel-accordions .panel .panel-content {
    padding-top: 0;
}
.right-content .right-content-container .drawer.presentation-drawer .presentation-panel-content .drawer-content .panel-bottom button {
    display: block;
    margin: 0 auto;
}
.right-content .right-content-container .drawer.presentation-drawer-open {
    width: 300px;
}
.right-content .right-content-container .drawer.presentation-drawer-open .puller .pane-arrow {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
.right-content .right-content-container .drawer.presentation-drawer-open .presentation-panel-content {
    display: block;
}
.right-content .right-content-container .content-wrapper {
    margin-right: 15px;
    width: calc(100% - 15px - 15px);
}
.right-content .right-content-container .content-wrapper.presentation-drawer-open {
    width: calc(100% - 15px - 300px);
    margin-right: 300px;
}
.right-content .right-content-container .content-wrapper.presentation-drawer-open .footer {
    width: calc(100% - 100px - 15px - 300px);
}
.right-content .right-content-container .content-wrapper.publication .publication-treegrid {
    float: left;
    width: 100%;
}
.right-content .right-content-container .content-wrapper.publication .publication-treegrid .header-checkbox {
    margin: 0;
}
.right-content .right-content-container .content-wrapper.publication .product-footer .footer-content .gray-button {
    text-transform: capitalize;
    margin-right: 1.5rem;
    font-size: 1.4rem;
    padding: 0.8rem 1.5rem;
}
.right-content .right-content-container .content-wrapper.publication .product-footer .footer-content .orange-button {
    text-transform: uppercase;
    font-size: 1.4rem;
    padding: 0.8rem 1.5rem;
}
.right-content .right-content-container .content-wrapper.publication .product-footer .total-row-count {
    line-height: 50px;
    padding-left: 3rem;
}
.right-content .right-content-container .content-wrapper.publication .picture-packet-modal .modal-container .modal-holder {
    width: 70%;
    z-index: 1000;
}
.right-content .right-content-container .content-wrapper.publication .picture-packet-modal .modal-container .modal-content .packet-details {
    width: 40%;
    display: inline-block;
    vertical-align: top;
}
.right-content .right-content-container .content-wrapper.publication .picture-packet-modal .modal-container .modal-content .packet-details .form-group {
    margin: 1.5rem 0;
}
.right-content .right-content-container .content-wrapper.publication .picture-packet-modal .modal-container .modal-content .packet-details .form-group label {
    display: block;
    margin-bottom: 0.5rem;
}
.right-content .right-content-container .content-wrapper.publication .picture-packet-modal .modal-container .modal-content .packet-details .form-group .form-control {
    width: 100%;
    margin: 0;
}
.right-content .right-content-container .content-wrapper.publication .picture-packet-modal .modal-container .modal-content .packet-preview {
    width: 60%;
    display: inline-block;
    vertical-align: top;
    padding-left: 2rem;
    overflow-y: scroll;
}

.footer .clearfix {
    text-align:center;
}

/* right puller shadow*/
.presentation-drawer.drawer .puller {
    box-shadow: -1px 0px 2px #d2d2d2;
}
