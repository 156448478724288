@import "../common/stylesheets/variables.less";
@import "../common/stylesheets/mixins.less";

.holder{
  margin: auto;
  width: 510px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  .transform(translateY(-50%));
}

.copyright-content{
  font-size: 1.2rem;
  color: @gray;
  margin-top: 4rem;
}

.login-form-holder{
    background: #fff;
    border-radius: 16px;

    .login-header{
      text-align: center;
      margin: 0;
      font-size: 20px;
      padding: 4rem 1rem 3rem;
      border-radius: 5px 5px 0 0 ;
    }
    .login-welcome-msg{
        font-size: 1.8rem;
    }
    a{
      cursor: pointer;
    }
    .login-form-container{
      &:not(.forgot-view){

        .forgot-view{
          display: none;
        }
      }

      &.forgot-view{
        .login-view{
          display: none;
        }
        }
      color: @gray;
      padding: 1rem 8rem 2rem;
      border-radius: 0 0  5px 5px;
    }
    .remember-me-holder{
      padding-top: 2.4rem;
      overflow:hidden;
    }
    .forgot-pass-link {
      display: inline-block;
      font-size: 1.2rem;
      &:hover{
        text-decoration: underline;
      }
    }
    .cancel-link{
      font-size: 1.2rem;
      margin-top: 4rem;
      display: inline-block;
      text-align: center;
      width: 100%;
      margin-bottom: 4rem;

      &:hover{
        text-decoration: underline;
      }
      .icon-close{
        width: 1rem;
        height: 1rem;
        background-size: 100% 100%;
        margin-right: 1rem;
      }
    }
    .login-button{
      &:disabled{
        background: @lightGray4;
        border-color: @lightGray4;
        color: #000;
        cursor: not-allowed;
      }
      width: 100%;
      color: #fff;
      text-transform: uppercase;
      background: @lightOrange;
      padding: 1.3rem;
      border: 1px solid @lightOrange;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 1rem;
      .transition(background 0.2s);

      &:hover{
        background: @lightOrangeHover;
      }
    }

    .login-fields{
      padding: 0;
      margin-bottom: 1.5rem;
      font-weight: 300;
      position: relative;
      input{
        border-radius: 3px;
        width: 100%;
        padding: 1.3rem 0.5rem;
        padding-left: 12%;
        border: 1px solid @lightGray3;
        .placeholder({color:#cbcecf})
      }

      .login-label{
        width: 12%;
        height: 44px;
        padding: 1.3rem;
        vertical-align: top;
        display: inline-block;
        position: absolute;
      }

      & + .login-fields{
        &:not(.login-fields){
          padding-left: 0;
        }

      }

    }
    .button-holder{
      margin-top: 1rem;
    }
}

.login-container{
  background: @lightGray3;
  height: 100%;
  position: fixed;
  width: 100%;
  overflow: auto;
}
.error{
    color: red;
    margin-bottom: 0;
    font-weight: bold;
}
