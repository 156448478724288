/* .container {
    overflow: visible;
} */
.popup {
    position: fixed;
    width: 300px;
    height: 300px;
    top: 50px;
    background-color: rgb(128, 198, 242, 0.5);
}

.grid-images {
    margin: auto;
    display: block;
    width: fit-content;
}

.samples-grid-reprint .modal-holder {
    width: 50% !important;
}

.samples-footer-left {
    display: inline-block;
    margin-left: 15px;
}