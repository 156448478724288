/*.fieldLength {
    
    .form-label {
        padding: 1rem;
        display: inline-block;
        min-width: 48%;
        max-width: 48%;
    }

    .form-control {
        width: 50%;
        margin-top: 7px;
        vertical-align: top;
    }
    .error-message{
        text-align: left;
        margin-left: 6px;
    }
}

.emailGrid {
    .add-edit-popup.active > .back{
        cursor: pointer;
        display: inline-block;
    }
}
*/
