@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);@font-face {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
/* CSS */
.icon-samples {
  background: url('./app/common/icons/samples.png') no-repeat 22px;
  content: '';
  display: block;
  height: 28px;
  width: 100%;
  background-size: 35px;
}
.icon-samples.active {
  background: url('./app/common/icons/samples-white.png') no-repeat 28px;
}
.icon-product {
  background: url('./app/common/icons/Product Icon.svg') no-repeat 28px;
  content: '';
  display: block;
  height: 28px;
  width: 100%;
}
.icon-product.active {
  background: url('./app/common/icons/Product Icon-white.svg') no-repeat 28px;
}
.icon-globe {
  background: url('./app/common/icons/Globe Icon.svg') no-repeat 28px;
  content: '';
  display: block;
  height: 28px;
  width: 100%;
}
.icon-globe.active {
  background: url('./app/common/icons/Globe Icon-white.svg') no-repeat 28px;
}
.icon-marketing {
  background: url('./app/common/icons/Marketing Icon.svg') no-repeat 28px;
  content: '';
  display: block;
  height: 28px;
  width: 100%;
}
.icon-marketing.active {
  background: url('./app/common/icons/Marketing Icon-white.svg') no-repeat 28px;
}
.icon-creative {
  background: url('./app/common/icons/Creative Icon.svg') no-repeat 28px;
  content: '';
  display: block;
  height: 30px;
  width: 100%;
}
.icon-creative.active {
  background: url('./app/common/icons/Creative Icon-white.svg') no-repeat 28px;
}
.icon-publication {
  background: url('./app/common/icons/Publication Icon.svg') no-repeat 28px;
  content: '';
  display: block;
  height: 28px;
  width: 100%;
}
.icon-publication.active {
  background: url('./app/common/icons/Publication Icon-white.svg') no-repeat 28px;
}
.icon-admin {
  background: url('./app/common/icons/Admin Icon.svg') no-repeat;
  background-position-x: 50%;
  content: '';
  display: block;
  height: 28px;
  width: 100%;
}
.icon-admin.active {
  background: url('./app/common/icons/Admin Icon-white.svg') no-repeat;
}
.icon-profile {
  background: url('./app/common/icons/Profile Icon.svg') no-repeat;
  content: '';
  display: inline-block;
  height: 23px;
  width: 28px;
}
.icon-setting {
  background: url('./app/common/icons/Setting Icon.svg') no-repeat;
  content: '';
  display: inline-block;
  height: 23px;
  width: 28px;
}
.icon-help {
  background: url('./app/common/icons/Help Icon.svg') no-repeat;
  content: '';
  display: inline-block;
  height: 23px;
  width: 28px;
}
.icon-signout {
  background: url('./app/common/icons/Signout Icon.svg') no-repeat;
  content: '';
  display: inline-block;
  height: 23px;
  width: 28px;
}
.icon-notification {
  background: url('./app/common/icons/Notification Icon.svg') no-repeat;
  content: '';
  display: inline-block;
  height: 28px;
  width: 32px;
}
.icon-expand {
  background: url('./app/common/icons/Expand Icon.svg') no-repeat;
  content: '';
  display: inline-block;
  height: 23px;
  width: 28px;
}
.icon-collapse {
  background: url('./app/common/icons/Collapse Icon.svg') no-repeat;
  content: '';
  display: inline-block;
  height: 23px;
  width: 28px;
}
.icon-close {
  background: url('./app/common/icons/Close Button.svg') no-repeat;
  content: '';
  display: inline-block;
  height: 23px;
  width: 28px;
}
.icon-plus {
  background: url('./app/common/icons/Plus.svg') no-repeat;
  content: '';
  display: inline-block;
  height: 20px;
  width: 20px;
}
.icon-minus {
  background: url('./app/common/icons/Minus.svg') no-repeat;
  content: '';
  display: inline-block;
  height: 7px;
  width: 20px;
  margin-top: 9px;
}
.icon-minus-blue {
  background: url('./app/common/icons/Minus Blue.svg') no-repeat;
  content: '';
  display: inline-block;
  height: 7px;
  width: 20px;
  margin-top: 9px;
}
.icon-checkbok-border {
  background: url('./app/common/icons/Check box.svg') no-repeat;
  content: '';
  display: inline-block;
  height: 14px;
  width: 15px;
}
.icon-checkbok-checked {
  background: url('./app/common/icons/selected.svg') no-repeat;
  content: '';
  display: inline-block;
  height: 14px;
  width: 15px;
}
.icon-info {
  background: url('./app/common/icons/Info icon.svg') no-repeat;
  content: '';
  display: inline-block;
  height: 28px;
  width: 15px;
}
.icon-expand-grid {
  background: url('./app/common/icons/Expand Grid.svg') no-repeat;
  content: '';
  display: inline-block;
  height: 12px;
  width: 12px;
}
.left {
  float: left !important;
}
.right {
  float: right !important;
}
/* Start: FAQ help content */
.modal-container.help-model .modal-holder {
  width: 890px;
}
/* End: FAQ help content */

.dropdown-menu {
  position: absolute;
  float: right;
  margin-top: 0;
  right: auto;
  left: 100px;
  display: none !important;
  width: 216px;
  height: auto;
  top: 64px;
}
.dropdown-menu .arrow {
  border: none !important;
  width: 17px;
  height: 100px;
  position: absolute;
  overflow: hidden;
  left: 0;
}
.dropdown-menu .arrow:after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 19px;
  left: 8px;
  box-shadow: 0 0px 2px 0 #c0c0c0;
}
.dropdown-menu ul {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0px 4px 0 #c0c0c0;
  height: 100%;
  margin-top: 0!important;
  margin-left: 1.7rem;
  padding: 2rem;
}
.dropdown-menu ul li {
  text-align: left;
}
.dropdown-menu ul li a {
  display: block;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #414c4e;
  text-decoration: none;
  padding: 1rem 0;
}
.dropdown-menu ul li a i {
  vertical-align: middle;
}
.dropdown-menu ul li a span {
  margin-left: 0.8rem;
}
.menuList li {
  text-align: center;
}
.menuList li a {
  display: block;
}

.panel {
  border: 1px solid #D7DCDD;
  border-bottom: none;
  margin: 0 1.6rem;
}
.panel .panel-heading {
  padding: .8rem;
  cursor: pointer;
  min-height: 30px;
}
.panel .panel-heading .accordion-expand {
  float: right;
}
.panel .panel-heading .accordion-expand .icon-plus {
  margin-top: -4px;
}
.panel .panel-heading .accordion-expand .icon-minus {
  margin: 0;
}
.panel .panel-heading.active {
  background-color: #eff2f3;
}
.panel .panel-heading h4 {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1.3rem;
  color: #414c4e;
}
.panel .panel-heading h4 a {
  float: left;
}
.panel .panel-content {
  display: none;
}
.panel .panel-content.is-open {
  display: inherit;
  padding: 1rem;
  border-top: 1px solid #D7DCDD;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1.3rem;
  color: #166A8F;
}
.panel .panel-content ul {
  margin: 0;
}
.panel .panel-content ul li {
  padding-top: .8rem;
}
.panel .panel-content ul li a {
  text-decoration: none;
  color: #166A8F;
  cursor: pointer;
}
.panel:last-child {
  border-bottom: 1px solid #D7DCDD;
}
/*
* Do not modify the Component css for individual requirement
* Component css is Generic
* Do not use !important for any css rule written inside this file
*/
html {
  font-size: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
body {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #FFF;
}
body::-webkit-scrollbar {
  width: 6px;
  background-color: #FFF;
}
body::-webkit-scrollbar-thumb {
  background-color: #9b9b9b;
}
body .jqx-scrollbar-thumb-state-normal-light {
  width: 6px !important;
  left: 4px !important;
  background-color: #9b9b9b !important;
}
body .jqx-scrollbar-thumb-state-normal {
  width: 6px !important;
  left: 4px !important;
  background-color: #9b9b9b !important;
}
body .jqx-scrollbar-thumb-state-normal-horizontal-light {
  height: 6px !important;
  top: 4px !important;
  background-color: #9b9b9b !important;
}
body .jqx-scrollbar-thumb-state-normal-horizontal {
  height: 6px !important;
  top: 4px !important;
  background-color: #9b9b9b !important;
}
body label {
  user-select: none;
}
/* making all the font-weight bold to 400 ,
*  as the project has ROBOTO regular with 400
*/
h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-weight: 400;
}
/*---------------------------Start: Components -------------------------------*/
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.pos-relative {
  position: relative;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.hide {
  display: none;
}
.pointer {
  cursor: pointer;
}
.vertical-center-msg {
  color: #525E61;
  text-align: center;
  margin-top: 15%;
  font-size: 30px;
}
.mt-20 {
  margin-top: 2rem;
}
.strike-through,
.jqx-grid-cell-hover-light.strike-through {
  text-decoration: line-through !important;
  text-decoration-color: red;
}
.strike-through:hover,
.jqx-grid-cell-hover-light.strike-through:hover {
  text-decoration: line-through;
  text-decoration-color: red;
}
.deleted,
.jqx-grid-cell-hover-light.deleted {
  opacity: 0.7;
  color: #ff6d6d !important;
}
/*---------------------------End: Components ---------------------------------*/
.text-msg {
  display: inline-block;
  font-size: 1.2rem;
  margin-top: .5rem;
}
.text-red {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: red;
}
/*-------------------------- Start: Error Messages ----------------------------------*/
.error-msg {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: red;
  font-size: 12px;
  text-align: left;
  display: block;
  margin-top: .2rem;
}
.error-message {
  position: relative;
  display: block;
  font-size: 12px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #ff0000;
}
.border-error {
  border-color: red !important;
}
/*-------------------------- End: Error Messages ----------------------------------*/
/*---------------------------Start: Buttons ---------------------------------*/
.orange-button {
  background: #F15A29;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  border: 0;
  color: #fff;
  cursor: pointer;
}
.orange-button:disabled {
  background: #D7DCDD;
  color: #808080;
  cursor: not-allowed;
}
.warning-button {
  display: inline-block;
  color: #fff;
  padding: 0.6rem 1.5rem;
  border: 1px solid #dd6b55;
  border-radius: 5px;
  background: #dd6b55;
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.warning-button:disabled {
  background: #D7DCDD;
  color: #eee;
  border: 1px solid #D7DCDD;
}
.warning-button:not(:disabled):not(.active):hover {
  background: #d46752;
  color: #fff;
}
.warning-button.active {
  background: #dd6b55;
  color: #fff;
}
.gray-button {
  display: inline-block;
  color: #555;
  padding: 0.6rem 1.5rem;
  border: 1px solid #555;
  border-radius: 5px;
  background: transparent;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  -o-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.gray-button:disabled {
  background: #D7DCDD;
  color: #eee;
  border: 1px solid #D7DCDD;
  cursor: not-allowed;
}
.gray-button:not(:disabled):not(.active):hover {
  background: rgba(85, 85, 85, 0.5);
  color: #eee;
}
.gray-button.active {
  background: #555;
  color: #fff;
}
.gray-button:disabled {
  background: #D7DCDD !important;
  color: #eee;
  border: 1px solid #D7DCDD !important;
}
.button-link {
  border: none;
  background: #fff;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #525E61;
}
/*------------------------------END: Buttons ---------------------------------*/
/*------------------------------Start: Form input text-------------------------*/
.form-group {
  margin-bottom: 1rem;
}
.form-group .form-label {
  display: inline-block;
  margin-bottom: .5rem;
}
.form-control {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  height: 35px;
  border: solid 1px #D7DCDD;
  box-shadow: 0 0 3px 0 rgba(217, 217, 217, 0.5);
  border-radius: 2px;
  padding: .5rem 1rem;
}
.form-control.invalid {
  border: solid 1px #ff0000;
}
.form-control.error {
  border-color: #ff9191;
  box-shadow: 0 0 5px #ff9191;
  -webkit-transition: box-shadow 0.2s;
  -moz-transition: box-shadow 0.2s;
  -ms-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
/*-------------------------- End: Form input text--------------------------*/
/*-------------------------- Start: Custom checkbox --------------------------*/
.blue-checkbox {
  position: relative;
  display: inline-block;
  width: 14px;
  height: 14px;
}
.blue-checkbox.wt {
  width: auto;
  height: auto;
  padding-left: 16px;
}
.blue-checkbox input {
  position: absolute;
  left: 0;
  width: 14px;
  height: 14px;
  z-index: 4;
  opacity: 0;
}
.blue-checkbox input:not(:checked) + label {
  position: absolute;
  left: 0;
  width: 14px;
  height: 14px;
  z-index: 3;
  border: 1px solid #2FACB2;
  border-radius: 3px;
}
.blue-checkbox input:disabled + label {
  opacity: 0.5;
}
.blue-checkbox input:checked + label {
  position: absolute;
  left: 0;
  width: 14px;
  height: 14px;
  z-index: 3;
  border: 1px solid #2FACB2;
  border-radius: 3px;
  background: #2FACB2;
}
.blue-checkbox input:checked + label:after {
  content: '\2713';
  font-size: 12px;
  color: #fff;
  top: 0px;
  position: absolute;
  left: 2px;
}
.lightgray-checkbox {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
}
.lightgray-checkbox.wt {
  width: auto;
  height: auto;
  padding-left: 18px;
}
.lightgray-checkbox input {
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  z-index: 4;
  opacity: 0;
}
.lightgray-checkbox input:not(:checked) + label {
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  z-index: 3;
  border: 1px solid #D4D8D9;
}
.lightgray-checkbox input:checked + label {
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  z-index: 3;
  border: 1px solid #D4D8D9;
  background: #D4D8D9;
}
.lightgray-checkbox input:checked + label:after {
  content: '\2713';
  font-size: 12px;
  color: #fff;
  top: 0px;
  position: absolute;
  left: 2px;
}
.form-radio-label + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  transition: .28s ease;
}
/*--------------------------- End: Custom checkbox ---------------------------*/
/*--------------------------- Start: Custom Radio ---------------------------*/
.blue-radio {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
}
.blue-radio.wt {
  width: auto;
  height: auto;
  padding-left: 20px;
}
.blue-radio input {
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 4;
  opacity: 0;
}
.blue-radio input:not(:checked) + label {
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 3;
  border: 2px solid #2FACB2;
  border-radius: 100%;
}
.blue-radio input:checked + label {
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 3;
  border: 2px solid #2FACB2;
  border-radius: 100%;
}
.blue-radio input:checked + label:after {
  content: '';
  font-size: 12px;
  color: #fff;
  top: 3px;
  background: #2FACB2;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  left: 3px;
}
/*--------------------------- End: Custom Radio ---------------------------*/
/*------------------------------ Start: 404 ----------------------------------*/
.notFound {
  background: #f2f2f2;
  padding: 5% 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
/*------------------------------End: 404 -------------------------------------*/
/*------------------------------Start: Column Grid ----------------------------------*/
.container {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
}
.container .cont-row {
  padding-top: 1rem;
}
.row {
  display: inline-block;
  width: 100%;
}
.row .from-checkbox,
.row .form-control-radio {
  top: 1.1rem !important;
  position: relative;
}
.col-4 {
  width: 33.33333333%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  float: left;
}
.col-6 {
  width: 50%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  float: left;
}
.col-8 {
  width: 66.666667%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  float: left;
}
.col-12 {
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  float: left;
}
.clearfix:after {
  content: '';
  display: block;
  clear: both;
}
/*------------------------------End: Column Grid ----------------------------------*/
/*------------------------------Start: Footer--------------------------------------*/
.footer .footer-content {
  padding: 1rem 2rem;
  display: inline-block;
}
.footer .footer-content button {
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.footer .footer-content button:disabled {
  cursor: default;
}
.footer .footer-content .footer-btn {
  margin-right: 1.5rem;
}
/*-------------------------------Overflow Scroll-----------------------------------*/
.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #FFF;
}
.scroll::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  background-color: #FFF;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: #9b9b9b;
}
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .scroll {
    scrollbar-face-color: #9b9b9b;
    scrollbar-arrow-color: #FFF;
  }
}
/*--------------------------------------Start: TreeView ---------------------------------------*/
.categoryTree {
  width: 100%;
  clear: both;
}
.tree-node {
  margin: .5rem 0;
  clear: both;
}
.tree-node .tree-node-list {
  cursor: pointer;
  word-wrap: break-word;
  color: #414c4e;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  text-transform: capitalize;
}
.tree-node .tree-node-list.active {
  color: #15567c;
}
.tree-node .tree-node-list .icon-plus,
.tree-node .tree-node-list .icon-minus-blue,
.tree-node .tree-node-list .icon-minus {
  float: left;
}
.tree-node .tree-node-list .no-expand {
  float: left;
  height: 7px;
  width: 20px;
  margin-top: 9px;
}
.tree-node .tree-node-list.selected {
  color: #008000;
  font-weight: 600;
}
.tree-node .tree-node-list .category {
  position: relative;
  top: .4rem;
  margin-right: .5rem;
}
.tree-node .tree-node-list .category.last {
  margin-left: 2rem;
}
.tree-node .tree-node-list .category.active {
  color: #008000;
  font-weight: 600;
}
.tree-node .item-value.active label span {
  color: #008000;
  font-weight: 600;
}
.tree-node .item-value .hover-effect {
  position: absolute;
  padding: 1rem;
  margin: 0 1rem;
  width: 91%;
  left: 0;
  margin-top: -2rem;
  z-index: -1;
  border-radius: 3px;
}
.tree-node .item-value label {
  margin-left: -1.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #414c4e;
  text-transform: capitalize;
  padding-left: 2.3rem;
}
.tree-node .item-value input[type="checkbox"] {
  visibility: hidden;
}
.tree-node .tree-node-item {
  display: none;
}
/* .tree-node .tree-node-item .level2 .item-value,
.tree-node .tree-node-item .level2 .tree-node-list {
  padding-left: 2rem;
}
.tree-node .tree-node-item .level3 .item-value,
.tree-node .tree-node-item .level3 .tree-node-list {
  padding-left: 4rem;
}
.tree-node .tree-node-item .level4 .item-value,
.tree-node .tree-node-item .level4 .tree-node-list {
  padding-left: 6rem;
}
.tree-node .tree-node-item .level5 .item-value,
.tree-node .tree-node-item .level5 .tree-node-list {
  padding-left: 8rem;
} */

.tree-node .tree-node-item > *{padding: 0 0 0 10px;}

.tree-node .tree-node-item.is-open {
  display: block;
}
/*--------------------------------------End: TreeView ---------------------------------------*/
/*--------------------------------------Start: Loader------------------------------------------------*/
@-webkit-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@-webkit-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@-moz-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@-ms-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@-moz-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@-webkit-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@-o-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
.uil-ripple-css {
  background: none;
  position: fixed;
  left: 0;
  width: 200px;
  height: 200px;
  margin: auto;
  vertical-align: middle;
  right: 0;
  top: 0;
  bottom: 0;
  transform: scale(0.49);
}
.uil-ripple-css div {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  width: 0;
  height: 0;
  opacity: 0;
  border-radius: 50%;
  border-width: 12px;
  border-style: solid;
  -ms-animation: uil-ripple 2s ease-out infinite;
  -moz-animation: uil-ripple 2s ease-out infinite;
  -webkit-animation: uil-ripple 2s ease-out infinite;
  -o-animation: uil-ripple 2s ease-out infinite;
  animation: uil-ripple 2s ease-out infinite;
}
.uil-ripple-css div:nth-of-type(1) {
  border-color: #2FACB2;
}
.uil-ripple-css div:nth-of-type(2) {
  border-color: rgba(158, 158, 158, 0.57);
  -ms-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
}
.loader {
  position: fixed;
  display: none;
  background: rgba(255, 255, 255, 0.92);
  height: 100%;
  width: 100%;
  z-index: 999999999;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
}
/*--------------------------------------End: Loader------------------------------------------*/
/*--------------------------------------Start: Dropdown, Dropup--------------------------------------------*/
.dropup {
  float: left;
}
.dropup ul {
  display: none;
  position: absolute;
  bottom: 100%;
  width: 82px;
  background: #fff;
  margin: 0;
  padding: .5rem 0;
  box-shadow: 0 0 5px #555;
}
.dropup ul li {
  padding: .5rem;
  cursor: pointer;
}
.dropup ul li:hover {
  background-color: #EFF2F3;
}
.dropup.active ul {
  display: block;
}
/*--------------------------------------End: Dropdown, Dropup--------------------------------------------*/
/*--------------------------------------Start: Grid Common Sections--------------------------------------*/
.grid-empty-message {
  color: #525E61;
  text-align: center;
  margin-top: 15%;
  font-size: 30px;
}
/*--------------------------------------End: Grid Common Sections--------------------------------------*/
/*--------------------------------------Start: Global Margin-------------------------------------------*/
.row .margin-top3 {
  top: .3rem !important;
}
/*--------------------------------------End: Global Margin-------------------------------------------*/
.pd-hl {
  position: absolute;
  width: 150px;
  display: none;
  z-index: 99991;
  background: #bfeaeb;
  padding: 0.5rem 0;
  text-align: center;
}
.pd-hl ul {
  margin: 0;
  text-align: left;
}
.pd-hl ul li {
  padding: 0.5rem 1.5rem;
  color: #525E61;
  cursor: pointer;
}
.pd-hl ul li:hover {
  background: #f2f8f8;
}
.pd-hl ul li.active {
  background: #f2f8f8;
}
.pd-hl ul li.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.pd-hl ul li[data-val="filter"] {
  position: relative;
}
.pd-hl ul li[data-val="filter"]:after {
  content: '';
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #555555;
  position: absolute;
  top: 12px;
  right: 2px;
  transform: rotate(-90deg);
}
.pd-hl ul li[data-val="filter"].active::after {
  transform: rotate(90deg);
}
.modal-container:not(.open) {
  display: none !important;
}
.modal-container.open {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 999998;
}
.modal-container .modal-holder {
  margin: 10em auto;
  background: #fff;
  height: auto !important;
  max-height: inherit;
  width: 462px;
  border-radius: 16px;
}

.modal-container .modal-holder .modal-holder {
  margin-top: 18em;
}

.modal-container .modal-holder .modal-header {
  padding: 1.5rem 3rem;
  font-size: 1.9rem;
  background: #2FACB2;
  color: #fff;
  border-radius: 16px 16px 0 0;
  position: relative;
}
.modal-container .modal-holder .modal-header .close {
  position: absolute;
  right: 2rem;
  cursor: pointer;
}
.modal-container .modal-content {
  padding: 2rem;
}
.modal-container .modal-content.overflow-auto {
  overflow: auto;
  height: 370px;
}
.modal-container .modal-content select {
  pointer-events: visible;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  height: 30px;
  border: solid 1px #d7dcdd;
  box-shadow: 0 0 3px 0 rgba(217, 217, 217, 0.5);
  width: 42%;
  /* padding: .5rem 1rem; */
  /* margin: 5px; */
}
.modal-container .modal-content .jqx-fill-state-normal {
  font-size: 14px !important;
  box-shadow: rgba(217, 217, 217, 0.5) 0px 0px 3px 0;
  border-color: #d7dcdd;
  background-color: #fff;
  text-indent: 10px;
}
.modal-container.confirm .modal-holder {
  width: 400px;
  margin: 15% auto;
  border-radius: 2px;
}
.modal-container.confirm .modal-holder .modal-content .confirm-warning-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: #525E61;
}
.modal-container button {
  margin-right: 1rem;
  margin-left: 1rem;
}
/*add copy model popup*/
.modal-container.addCopyModel .modal-holder {
  width: 633px;
}
.modal-container.addCopyModel .modal-holder .form-container {
  max-height: 400px;
  overflow-y: auto;
  padding: 0 .5rem;
}
.modal-container.addCopyModel .modal-holder .form-container .form-group {
  width: 100%;
  margin-bottom: 1rem;
}
.modal-container.addCopyModel .modal-holder .form-container .form-group .form-label {
  width: 100%;
  text-align: left;
  position: relative;
  display: block;
  margin-bottom: 2px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
  line-height: 2;
  color: #525E61;
}
.modal-container.addCopyModel .modal-holder .form-container .form-group .form-control {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  height: 35px;
  border: solid 1px #D7DCDD;
  box-shadow: 0 0 3px 0 rgba(217, 217, 217, 0.5);
  border-radius: 2px;
  padding: .5rem 1rem;
}
.modal-container.addCopyModel .modal-holder .form-container .form-group .form-control.invalid {
  border: solid 1px #ff0000;
}
.modal-container.addCopyModel .modal-holder .form-container .form-group .form-control.error {
  border-color: #ff9191;
  box-shadow: 0 0 5px #ff9191;
  -webkit-transition: box-shadow 0.2s;
  -moz-transition: box-shadow 0.2s;
  -ms-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
.modal-container.addCopyModel .modal-holder .modal-footer {
  margin: 3.3rem 0 1rem;
}
/*jqxeditor*/
.jqx-widget-header {
  border: none;
}
.jqx-widget-header .jqx-fill-state-normal {
  background: #ffffff !important;
  border: none !important;
}
.jqx-widget-header .jqx-fill-state-normal .jqx-fill-state-hover {
  background: #ffffff !important;
  border: none !important;
}
.jqx-widget-header .jqx-fill-state-normal .jqx-fill-state-pressed {
  background: #ffffff !important;
  border: none !important;
}
/*------------------------------Start: form modal --------------------------*/
.modal-form .form-container {
  max-height: 400px;
  overflow-y: auto;
  padding: 0 .5rem;
}
.modal-form h4 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  margin: 0;
  margin-top: 1rem;
  color: #2FACB2;
  display: inline-block;
}
.modal-form hr {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.modal-form .form-group {
  width: 100%;
  margin-bottom: 1rem;
}
.modal-form .form-group .form-label {
  width: 100%;
  text-align: left;
  position: relative;
  display: block;
  margin-bottom: 2px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
  line-height: 2;
  color: #525E61;
}
.modal-form .form-group .form-check {
  margin-bottom: .5rem;
}
.modal-form .form-group .form-check-label {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  padding-left: 1rem;
}
.modal-form .form-group .form-check-label span.from-checkbox {
  background: url('./app/common/icons/Check box.svg') no-repeat;
  content: '';
  display: inline-block;
  height: 14px;
  width: 15px;
  position: relative;
  left: -2rem;
  top: .2rem;
}
.modal-form .form-group .form-check-label span.from-checkbox.disabled {
  background: url('./app/common/icons/Checkbox Disabled.svg') no-repeat;
}
.modal-form .form-group .form-check-label .form-control-check:checked + span.from-checkbox {
  background: url('./app/common/icons/selected.svg') no-repeat;
}
.modal-form .form-group .form-control-check {
  position: relative;
  left: -10rem;
  visibility: hidden;
}
.modal-form .form-group .form-control,
.right-content-container .drawer .form-group .form-control-auto-width,
.modal-form .form-group .form-control-auto-width {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  height: 35px;
  border: solid 1px #D7DCDD;
  box-shadow: 0 0 3px 0 rgba(217, 217, 217, 0.5);
  border-radius: 2px;
  padding: .5rem 1rem;
}
.modal-form .form-group .form-control.texrarea-fld {
  height: 60px !important;
}
.modal-form .form-group .form-control.invalid {
  border: solid 1px #ff0000;
}
.modal-form .form-group .form-control.error {
  border-color: #ff9191;
  box-shadow: 0 0 5px #ff9191;
  -webkit-transition: box-shadow 0.2s;
  -moz-transition: box-shadow 0.2s;
  -ms-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
.modal-form .form-group .form-fluid-select {
  width: 100%;
  border: solid 1px #D7DCDD;
  box-shadow: 0 0 3px 0 rgba(217, 217, 217, 0.5);
  border-radius: 2px;
  padding: .5rem 1rem;
}
.modal-form .form-group .form-control-auto-width {
  width: auto;
}
.modal-form .addNewGroup .form-group .form-check-label span.from-checkbox {
  top: 1.2rem;
  left: -1rem;
}
.modal-form .addNewGroup .form-group .form-check-label .form-control-radio {
  position: relative;
  top: 1.2rem;
}
.modal-form .form-subtitle {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 12px;
}
.modal-form .error-message {
  position: relative;
  display: block;
  top: 121%;
  font-size: 12px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #ff0000;
}
.capitalize {
  text-transform: uppercase;
}
/*------------------------------End: form modal --------------------------*/

.setting-modal .modal-footer {
  margin: 3.3rem 0 1rem;
  text-align: center;
}
.setting-modal .modal-footer [type="button"],
.setting-modal .modal-footer [type="submit"] {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1.4rem;
  text-transform: uppercase;
  min-width: 115px;
  border-radius: 4px;
  border: solid 1px #555555;
  background-color: #fff;
  cursor: pointer;
  padding: 1rem 1.2rem;
  margin-left: 1.5rem;
}
.setting-modal .modal-footer [type="button"]:hover,
.setting-modal .modal-footer [type="submit"]:hover {
  background-color: #555555;
  color: #fff;
}
.hide {
  display: none!important;
}
/*----------------------------- Start: Notification --------------------------*/
.notification-holder {
  position: relative;
}
.notification-holder .notification-body {
  padding: 0 0.5rem;
  position: absolute;
  top: 100%;
  right: -0.5rem;
  width: 400px;
  height: 300px;
  overflow: auto;
  z-index: 99991;
}
.notification-holder .notification-body:not(.open) {
  display: none;
}
.notification-holder .notification-body .tab-selector {
  text-align: right;
}
.notification-holder .notification-body .tab-selector li {
  display: inline-block;
  background: #fff;
  cursor: pointer;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  -ms-transition: background 0.2s;
  -o-transition: background 0.2s;
  transition: background 0.2s;
}
.notification-holder .notification-body .tab-selector li.active {
  background: #2FACB2;
  color: #fff;
}
.notification-holder .notification-body .tab-holder {
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0 2px 5px #ccc;
  max-height: 260px;
  overflow: auto;
}
.notification-holder .notification-body .tab-holder ul > li {
  cursor: pointer;
}
.notification-holder .notification-body .tab-holder .p-tab:not(.active) {
  display: none;
}
.notification-holder .notification-body .tab-holder .g-tab:not(.active) {
  display: none;
}
.notification-holder .notification-body .tab-holder .g-tab ul {
  padding: 0 17px;
}
.notification-holder .notification-body .tab-holder .g-tab ul li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
.notification-holder .notification-body .tab-holder .g-tab ul li:last-child {
  border-bottom: 1px solid transparent;
}
/*----------------------------- ENd: Notification ----------------------------*/
/*-------------------------- Start:  View Fields drop down -------------------------*/
.list-viewer-holder {
  position: absolute;
  background: #fff;
  right: 0;
  top: 100%;
  width: 200px;
  z-index: 99991;
  border-radius: 0 0 3px 3px;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.list-viewer-holder .search-field-holder {
  margin: 1rem;
}
.list-viewer-holder .search-field-holder input {
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 2.5rem;
  border: 1px solid #EFF2F3;
  color: #525E61;
  background-color: #EFF2F3;
}
.list-viewer-holder .list-scrollable {
  max-height: 300px;
  overflow-y: auto;
}
.list-viewer-holder ul li {
  padding: 0.5rem 1rem;
  border-top: 1px solid #D7DCDD;
  font-size: 12px;
  cursor: default;
}
/*-------------------------- ENd: View Fields drop down ----------------------------*/
/*-------------------------- Start: header filters ----------------------------*/
.header-filters {
  background: #fff;
  color: #7B868A;
  border-radius: 25px;
  margin-top: 0.5rem;
  margin-right: 2rem;
  padding: 0 1.5rem;
}
.header-filters > div {
  padding: 1rem 1rem;
  text-transform: capitalize;
  display: inline-block;
  position: relative;
}
.header-filters > div + div:before {
  content: '|';
  position: relative;
  left: -1rem;
}
.header-filters .view-fields {
  user-select: none;
  cursor: pointer;
}
.header-filters .view-fields.open {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.header-filters .view-fields.open:before {
  color: transparent;
}
.header-filters .view-fields.open + div:before {
  color: transparent;
}
.header-filters .view-fields.open .mask {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  padding: 1rem;
  background: #fff;
  z-index: 1002;
  color: #414c4e;
  font-weight: 500;
}
.header-filters .view-fields:not(.open) .mask {
  display: none;
}
.header-filters .view-fields:not(.open) .list-viewer-holder {
  display: none;
}
.header-filters .find {
  cursor: pointer;
}
.based-on-me {
  position: relative;
  width: 220px;
}
.based-on-me .dropdown-el {
  min-width: 12em;
  position: absolute;
  display: inline-block;
  margin-right: 1em;
  min-height: 2em;
  max-height: 2em;
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
  color: #7b868a;
  z-index: 0;
  outline: none;
  border: .06em solid transparent;
  border-radius: 1em;
  background-color: #ffffff;
  transition: 0.3s all ease-in-out;
}
.based-on-me .dropdown-el input {
  display: block;
}
.based-on-me .dropdown-el label {
  display: block;
  height: 2em;
  line-height: 2em;
  padding-left: 1em;
  padding-right: 3em;
  cursor: pointer;
  position: relative;
  transition: 0.3s drpdown-color ease-in-out;
}
.based-on-me .dropdown-el .selected-label {
  display: block;
  border-top: none;
  position: absolute;
  margin-top: 0 !important;
  border-top: none !important;
  top: 0;
}
.based-on-me .dropdown-el::after {
  content: "";
  position: absolute;
  right: 0.8em;
  top: 0.9em;
  border: 0.3em solid #3694d7;
  border-color: #3694d7 transparent transparent transparent;
}
.based-on-me .dropdown-el.active::after {
  transform: rotate(-180deg);
  top: .55em;
}
.based-on-me .dropdown-el .dropdownl-list {
  display: block;
}
.based-on-me .dropdown-el .dropdownl-list.expanded {
  display: block;
  border: 1px solid #ffffff;
  background: #fff;
  color: #7b868a;
  border-radius: .25em;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 5px 3px;
  max-height: 200px;
  overflow: auto;
  margin-top: 5px;
}
.based-on-me .dropdown-el .dropdownl-list.expanded ul {
  border-top: 1px solid #cccccc;
}
.based-on-me .dropdown-el .dropdownl-list.expanded ul:first-child {
  border: none;
}
.based-on-me .dropdown-el .dropdownl-list.expanded label {
  border-top: .06em solid #d9d9d9;
}
.based-on-me .dropdown-el .dropdownl-list.expanded label:hover {
  color: #3694d7;
}
.based-on-me .dropdown-el .dropdownl-list.expanded input:checked + label {
  color: #7b868a;
}
/*-------------------------- End: header filters ----------------------------*/
.notifier-container {
  position: fixed;
  top: 1rem;
  left: 19%;
  z-index: 999999;
}
.notifier-container.overlay-container {
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  z-index: 999999;
}
.notifier-container .notification {
  position: relative;
  padding: 1rem 5rem 1rem 2rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  min-width: 400px;
  max-width: 500px;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  line-height: 1.4;
  word-wrap: break-word;
}
.notifier-container .notification.success {
  background-color: #dfebe5;
  color: #434343;
}
.notifier-container .notification.success .notification-message {
  cursor: pointer;
}
.notifier-container .notification.danger {
  background-color: #fa7183;
  color: #fff;
}
.notifier-container .notification.danger.overlay {
  margin: 25vh auto;
  text-align: center;
}
/* new notification style - suraj */
.notifier-container .notification.userInform {
  color: #000;
  background-color: #e1eeee;
  border-color: #000;
}
.notifier-container .notification.userInform.overlay {
  margin: 25vh auto;
  text-align: center;
}
/* new notification style - suraj */
.notifier-container .notification.warn {
  background-color: #fa7183;
  color: #fff;
}
.notifier-container .notification.info {
  background-color: #dce9f3;
  color: #666;
}
.notifier-container .notification .close-notification {
  background: url('./app/common/icons/Close Button.svg') no-repeat;
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  right: 5px;
  top: 33%;
  cursor: pointer;
}
body #main-wrapper [type="button"],
body #main-wrapper [type="submit"],
body #main-wrapper [type="text"],
body #main-wrapper [type="email"],
body #main-wrapper [type="password"],
body #main-wrapper [type="radio"],
body #main-wrapper [type="checkbox"],
body #main-wrapper select,
body #main-wrapper button {
  outline: none;
}
body #main-wrapper.fullscreen .left-nav-content {
  left: -100px !important;
  transition: all .3s ease;
}
body #main-wrapper.fullscreen .right-content {
  margin-left: 0px;
  transition: all .3s ease;
}
body #main-wrapper.fullscreen .right-content .right-content-container header .page-header-logo {
  display: block;
  margin: 1.6rem 2.5rem;
}
body #main-wrapper.fullscreen .right-content .right-content-container header .page-header-logo img {
  width: 110px;
}
body #main-wrapper.fullscreen .right-content .right-content-container .footer {
  width: calc(100% - 15px);
  left: 0;
}
body #main-wrapper.fullscreen .right-content .right-content-container.drawer-open header {
  height: 64px;
}
body #main-wrapper.fullscreen .right-content .right-content-container.drawer-open .footer {
  width: calc(100% - 300px);
}

.content-wrapper .footer {
  position: fixed;
  bottom: 0;
  width: calc(100% - 100px - 15px);
  height: 50px;
  z-index: 0;
  background-color: #e1eeee;
  left: 115px;
}

.footer {
  z-index: -5;
}

.jqx-grid {
  position: relative;
  z-index: 0;
}

.content-wrapper.drawer-open {
  margin-left: 300px;
  transition: all .3s ease;
}
.content-wrapper.drawer-open header {
  transition: all .3s ease;
}
.content-wrapper.drawer-open .footer {
  width: calc(100% - 100px - 300px);
  left: 400px;
}
.anchorDisabled {
  color: #cccccc !important;
}
select {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  height: auto;
  border: solid 1px #D7DCDD;
  box-shadow: 0 0 3px 0 rgba(217, 217, 217, 0.5);
  border-radius: 2px;
  padding: .5rem 1rem;
}
.text-transform-none {
  text-transform: none;
}
.multi-select {
  min-height: 100px;
  width: 100%;
}
.mb-10 {
  margin-bottom: 10px;
}
.modal-form .form-container.no-scrollbar-bottom {
  overflow: hidden;
}
form.addNewGroup .jqx-widget.jqx-dropdownlist-state-normal.jqx-rc-all.jqx-fill-state-normal.jqx-default {
  width: 100% !important;
}

/*
 * SALVAGED FROM LEGACY normalize.css
 */
fieldset {
  padding: 0;
  border: 0;
}

/*
 * SALVAGED FROM LEGACY normalize.css
 */
* {
  font-family: 'Roboto',sans-serif;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

/*
 * SALVAGED FROM LEGACY normalize.css
 */
ul {
  list-style-type: none;
  padding: 0;
}
