.version-notification-container {
  position: fixed;
  top: 16px;
  right: 32px;
  background: rgba(255, 255, 255, 0.92);
  text-align: center;
  z-index: 400;
  left: 32px;
  border-radius: 5px;
  padding: 16px;
  font-weight: bold;
  box-shadow: 4px 4px 8px 0px #0000001c;
  border: 1px solid #b3b3b3;

  .button {
    width: auto;
    color: #fff;
    text-transform: uppercase;
    background: #F15A29;
    padding: 0.5rem 1.3rem;
    border: 1px solid #F15A29;
    border-radius: 5px;
    cursor: pointer;
    /* margin-bottom: 1rem; */
    transition: background 0.2s;
    margin-left: 1rem;
  }

  .button:hover {
    background: #FF3D00;
  }
}
