@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);.left {
  float: left !important;
}
.right {
  float: right !important;
}
.packet-details .record-creation-holder {
  position: relative;
  display: inline-block;
  padding: 1rem 11.1rem;
}
.packet-details .record-creation-holder .record-action {
  cursor: pointer;
  user-select: none;
  position: relative;
  padding: 8px 44px 8px 45px;
}
#bottomRight {
  visibility: hidden !important;
}
.sortbyDropDown {
  position: absolute;
  top: 0px;
}
.packet-details .record-creation-holder .record-action:not(.open) + .record-options {
  display: none;
}
.packet-details .record-creation-holder .record-action:after {
  content: '';
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #555555;
  position: absolute;
  top: 12px;
  right: 2px;
}
.packet-details .record-creation-holder .record-action.open:after {
  content: '';
  border-top: 0px;
  border-bottom: 5px solid #ffffff;
}
.packet-details .record-creation-holder .record-options {
  position: absolute;
  bottom: 100%;
  z-index: 999;
  background: #fff;
  -webkit-box-shadow: 0 0 5px #525E61;
  -moz-box-shadow: 0 0 5px #525E61;
  box-shadow: 0 0 5px #525E61;
}
.packet-details .record-creation-holder .record-options ul {
  width: 250px;
}
.packet-details .record-creation-holder .record-options ul li {
  padding: 0.5rem 1rem;
  color: #525E61;
  cursor: pointer;
}
.packet-details textarea {
  outline: none;
}
.preview-container .preview-holder {
  position: relative;
  height: 100%;
  overflow-y: hidden;
}
.preview-container .preview-holder .preview {
  height: 100%;
}
.preview-container .pagination li {
  float: left;
  margin-right: 10px;
}
.preview-container .pagination li a {
  cursor: pointer;
  outline: none;
}
.preview-container .pagination li.active a {
  font-weight: bold;
  outline: none;
}
.preview-container .pagination li.disabled {
  font-weight: normal !important;
}
.preview-container .pagination li.previous {
  font-weight: bold;
}
.preview-container .pagination li.next {
  font-weight: bold;
}
.error-message.text-left {
  text-align: left !important;
}
.packet-preview .confirm.open .modal-holder {
  max-width: 600px;
}

.grid-view-dropdown {
  position: relative;
  display: inline-block;
}
.grid-view-dropdown .selected-view,
.grid-view-dropdown .selected-presentation {
  user-select: none;
  cursor: pointer;
  padding-right: 4rem;
}
.grid-view-dropdown .selected-view:not(.open) + .availabel-list,
.grid-view-dropdown .selected-presentation:not(.open) + .availabel-list {
  display: none;
}
.grid-view-dropdown .selected-view:after,
.grid-view-dropdown .selected-presentation:after {
  content: '';
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #2FACB2;
  position: absolute;
  top: 0.6rem;
  right: 0;
}
.grid-view-dropdown .selected-view.open:after,
.grid-view-dropdown .selected-presentation.open:after {
  content: '';
  border-top: 0px;
  border-bottom: 5px solid #2FACB2;
}
.grid-view-dropdown .availabel-list {
  position: absolute;
  width: 200px;
  top: calc(100% + 2rem);
  background: #fff;
  border-radius: 8px;
  z-index: 1001;
  left: -2rem;
  -webkit-box-shadow: 0 2px 4px 0 #c0c0c0;
  -moz-box-shadow: 0 2px 4px 0 #c0c0c0;
  box-shadow: 0 2px 4px 0 #c0c0c0;
}
.grid-view-dropdown .availabel-list ul {
  margin: 0;
  max-height: 400px;
  overflow: auto;
}
.grid-view-dropdown .availabel-list ul li {
  position: relative;
  border-bottom: 1px solid #f4f6f7;
  padding: 1rem 2rem;
  cursor: pointer;
}
.grid-view-dropdown .availabel-list ul li.new-view {
  color: #15567c;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
}
.grid-view-dropdown .availabel-list ul li.personal-view {
  background-color: #e8ecec;
}
.grid-view-dropdown .availabel-list ul li:hover .icon-close {
  display: block;
}
.grid-view-dropdown .availabel-list ul li .icon-close {
  display: inline-block;
  width: 11px;
  height: 11px;
  position: relative;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  right: 1.5rem;
  background: transparent;
  top: 35%;
  display: none;
}
.grid-view-dropdown .availabel-list ul li .icon-close:before {
  content: '';
  width: 11px;
  height: 1px;
  background: #525e61;
  display: inline-block;
  position: absolute;
  top: 5px;
  left: 0;
}
.grid-view-dropdown .availabel-list ul li .icon-close:after {
  content: '';
  position: absolute;
  height: 11px;
  width: 1px;
  background: #525e61;
  left: 5px;
  top: 0;
}
.grid-view-dropdown .availabel-list ul li.selected {
  cursor: default;
  opacity: 0.7;
}
.grid-view-dropdown .availabel-list ul li:last-child {
  border-bottom: 0px;
}
@font-face {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
.left {
  float: left !important;
}
.right {
  float: right !important;
}
.jqx-widget-light {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #555;
}
.jqx-widget-content-light {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #555;
}
.jqx-widget-header-light {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  background: #fff;
  color: #555;
}
/*Rounded Corners*/
/*top-left rounded Corners*/
.jqx-rc-tl-light {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
}
/*top-right rounded Corners*/
.jqx-rc-tr-light {
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}
/*bottom-left rounded Corners*/
.jqx-rc-bl-light {
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
/*bottom-right rounded Corners*/
.jqx-rc-br-light {
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
/*top rounded Corners*/
.jqx-rc-t-light {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}
/*bottom rounded Corners*/
.jqx-rc-b-light {
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
/*right rounded Corners*/
.jqx-rc-r-light {
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
/*left rounded Corners*/
.jqx-rc-l-light {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
/*all rounded Corners*/
.jqx-rc-all-light {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.jqx-widget-light,
.jqx-widget-header-light,
.jqx-fill-state-normal-light,
.jqx-widget-content-light,
.jqx-fill-state-hover-light,
.jqx-fill-state-pressed-light {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
}
.jqx-widget-content-light {
  background-color: #ffffff;
  border-color: #dddddd;
}
.jqx-widget-header-light {
  color: #525e61;
  background-color: #e0eded;
  border-color: #dddddd;
  *zoom: 1;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.jqx-widget-header-light span {
  padding: 0rem 1rem;
}
.jqx-widget-header-light span span {
  padding: 0;
}
.jqx-button-light,
.jqx-fill-state-normal-light {
  color: #555;
  background: #fafafa;
  border-color: #dddddd;
  border-radius: 0;
  *zoom: 1;
}
.jqx-fill-state-normal-light {
  text-align: center;
}
.jqx-tree-grid-indent {
  vertical-align: middle !important;
}
.jqx-fill-state-hover-light {
  color: #373a3c;
  border-color: #b2b2b2;
  border-color: rgba(0, 0, 0, 0.3);
  background-color: #f8f9f9;
}
.jqx-fill-state-pressed-light {
  color: #fff !important;
  background-color: #1ca8dd;
  border-color: #1ca8dd;
  *background-color: #fff;
}
.jqx-fill-state-hover-light,
.jqx-fill-state-focus-light {
  color: #333333;
  text-decoration: none;
}
.jqx-button-light {
  color: #555;
  border-color: #ddd;
  *zoom: 1;
}
.jqx-button-light.jqx-fill-state-hover {
  *zoom: 1;
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.jqx-button-light.jqx-fill-state-pressed {
  color: #fff;
  background: #1ca8dd;
  border-color: #1ca8dd;
  *zoom: 1;
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.jqx-dropdownlist-state-normal-light,
.jqx-dropdownlist-state-hover-light,
.jqx-dropdownlist-state-selected-light,
.jqx-scrollbar-button-state-hover-light,
.jqx-scrollbar-button-state-normal-light,
.jqx-scrollbar-button-state-pressed-light,
.jqx-scrollbar-thumb-state-normal-horizontal-light,
.jqx-scrollbar-thumb-state-hover-horizontal-light,
.jqx-scrollbar-thumb-state-pressed-horizontal-light,
.jqx-scrollbar-thumb-state-normal-light,
.jqx-scrollbar-thumb-state-pressed-light,
.jqx-tree-item-hover-light,
.jqx-tree-item-selected-light,
.jqx-tree-item-light,
.jqx-menu-item-light,
.jqx-menu-item-hover-light,
.jqx-menu-item-selected-light,
.jqx-menu-item-top-light,
.jqx-menu-item-top-hover-light,
.jqx-menu-item-top-selected-light,
.jqx-slider-button-light,
.jqx-slider-slider-light {
  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}
.jqx-primary-light {
  color: #1ca8dd  !important;
  background: #fff  !important;
  border-color: #1ca8dd!important;
  text-shadow: none !important;
}
.jqx-primary-light.jqx-dropdownlist-state-normal-light,
.jqx-primary-light.jqx-slider-button-light,
.jqx-primary-light.jqx-slider-slider-light,
.jqx-primary-light.jqx-combobox-arrow-normal-light,
.jqx-primary-light.jqx-combobox-arrow-hover-light,
.jqx-primary-light.jqx-action-button-light,
.jqx-primary-light:hover,
.jqx-primary-light:focus,
.jqx-primary-light:active,
.jqx-primary-light.active,
.jqx-primary-light.disabled,
.jqx-primary-light[disabled] {
  color: #fff  !important;
  background: #1ca8dd  !important;
  border-color: #1ca8dd !important;
  text-shadow: none !important;
}
.jqx-fill-state-pressed-light.jqx-primary-light,
.jqx-primary-light:active,
.jqx-primary-light.active {
  color: #fff  !important;
  background-color: #1ca8dd  !important;
  border-color: #1ca8dd!important;
  text-shadow: none !important;
}
.jqx-success-light {
  color: #5cb85c  !important;
  background: #fff  !important;
  border-color: #5cb85c!important;
  text-shadow: none !important;
}
.jqx-success-light.jqx-dropdownlist-state-normal-light,
.jqx-success-light.jqx-slider-button-light,
.jqx-success-light.jqx-slider-slider-light,
.jqx-success-light.jqx-combobox-arrow-normal-light,
.jqx-success-light.jqx-combobox-arrow-hover-light,
.jqx-success-light.jqx-action-button-light,
.jqx-success-light:hover,
.jqx-success-light:focus,
.jqx-success-light:active,
.jqx-success-light.active,
.jqx-success-light.disabled,
.jqx-success-light[disabled] {
  color: #fff  !important;
  background: #5cb85c  !important;
  border-color: #5cb85c!important;
  text-shadow: none !important;
}
.jqx-fill-state-pressed-light.jqx-success-light,
.jqx-success-light:active,
.jqx-success-light.active {
  text-shadow: none !important;
  color: #fff  !important;
  background: #5cb85c  !important;
  border-color: #5cb85c!important;
}
.jqx-inverse-light {
  text-shadow: none !important;
  color: #666  !important;
  background: #fff  !important;
  border-color: #cccccc!important;
}
.jqx-inverse-light.jqx-dropdownlist-state-normal-light,
.jqx-inverse-light.jqx-slider-button-light,
.jqx-inverse-light.jqx-slider-slider-light,
.jqx-inverse-light.jqx-combobox-arrow-hover-light,
.jqx-inverse-light.jqx-combobox-arrow-normal-light,
.jqx-inverse-light.jqx-action-button-light,
.jqx-inverse-light:hover,
.jqx-inverse-light:focus,
.jqx-inverse-light:active,
.jqx-inverse-light.active,
.jqx-inverse-light.disabled,
.jqx-inverse-light[disabled] {
  text-shadow: none !important;
  color: #666  !important;
  background: #cccccc  !important;
  border-color: #cccccc!important;
}
.jqx-fill-state-pressed-light.jqx-inverse-light,
.jqx-inverse-light:active,
.jqx-inverse-light.active {
  text-shadow: none !important;
  color: #666  !important;
  background: #cccccc  !important;
  border-color: #cccccc!important;
}
.jqx-danger-light {
  text-shadow: none !important;
  color: #d9534f  !important;
  background: #fff  !important;
  border-color: #d9534f!important;
}
.jqx-danger-light.jqx-dropdownlist-state-normal-light,
.jqx-danger-light.jqx-slider-button-light,
.jqx-danger-light.jqx-slider-slider-light,
.jqx-danger-light.jqx-combobox-arrow-hover-light,
.jqx-danger-light.jqx-combobox-arrow-normal-light,
.jqx-danger-light.jqx-action-button-light,
.jqx-danger-light:hover,
.jqx-danger-light:focus,
.jqx-danger-light:active,
.jqx-danger-light.active,
.jqx-danger-light.disabled,
.jqx-danger-light[disabled] {
  text-shadow: none !important;
  color: #fff  !important;
  background: #d9534f  !important;
  border-color: #d9534f!important;
}
.jqx-fill-state-pressed-light.jqx-danger-light,
.jqx-danger-light:active,
.jqx-danger-light.active {
  text-shadow: none !important;
  color: #fff  !important;
  background: #d9534f  !important;
  border-color: #d9534f!important;
}
.jqx-warning-light {
  text-shadow: none !important;
  color: #f0ad4e  !important;
  background: #fff  !important;
  border-color: #f0ad4e!important;
}
.jqx-warning-light.jqx-dropdownlist-state-normal-light,
.jqx-warning-light.jqx-slider-button-light,
.jqx-warning-light.jqx-slider-slider-light,
.jqx-warning-light.jqx-combobox-arrow-hover-light,
.jqx-warning-light.jqx-combobox-arrow-normal-light,
.jqx-warning-light.jqx-action-button-light,
.jqx-warning-light:hover,
.jqx-warning-light:focus,
.jqx-warning-light:active,
.jqx-warning-light.active,
.jqx-warning-light.disabled,
.jqx-warning-light[disabled] {
  text-shadow: none !important;
  color: #fff  !important;
  background: #f0ad4e  !important;
  border-color: #f0ad4e!important;
}
.jqx-fill-state-pressed-light.jqx-warning-light,
.jqx-warning-light:active,
.jqx-warning-light.active {
  text-shadow: none !important;
  color: #fff  !important;
  background: #f0ad4e  !important;
  border-color: #f0ad4e!important;
}
.jqx-info-light {
  text-shadow: none !important;
  color: #5bc0de  !important;
  background: #fff  !important;
  border-color: #5bc0de!important;
}
.jqx-info-light.jqx-dropdownlist-state-normal-light,
.jqx-info-light.jqx-slider-button-light,
.jqx-info-light.jqx-slider-slider-light,
.jqx-info-light.jqx-combobox-arrow-hover-light,
.jqx-info-light.jqx-combobox-arrow-normal-light,
.jqx-info-light.jqx-action-button-light,
.jqx-info-light:hover,
.jqx-info-light:focus,
.jqx-info-light:active,
.jqx-info-light.active,
.jqx-info-light.disabled,
.jqx-info-light[disabled] {
  color: #fff  !important;
  background: #5bc0de  !important;
  border-color: #5bc0de!important;
  text-shadow: none !important;
}
.jqx-fill-state-pressed-light.jqx-info-light,
.jqx-info-light:active,
.jqx-info-light.active {
  text-shadow: none !important;
  color: #fff  !important;
  background: #5bc0de  !important;
  border-color: #5bc0de!important;
}
.jqx-fill-state-pressed-light {
  background-image: none;
  outline: 0;
}
.jqx-grid-cell-pinned-light {
  background: #f1fafd;
}
.jqx-grid-cell-light {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0.8rem 1.2rem;
  color: #525e61;
  box-sizing: border-box;
}
.jqx-grid-column-menubutton-light {
  background-color: transparent;
  border-color: #ddd !important;
}
.jqx-cell-light {
  font-size: 14px;
}
.jqx-calendar-row-header-light,
.jqx-calendar-top-left-header-light {
  background-color: #f0f0f0;
  border: 0px solid #f2f2f2;
}
.jqx-calendar-column-header-light {
  background-color: #FFF;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #e9e9e9;
}
.jqx-expander-header-light {
  padding-top: 10px;
  padding-bottom: 10px;
}
.jqx-ribbon-header-vertical-light,
.jqx-widget-header-vertical-light {
  background: #f0f0f0;
  background: -ms-linear-gradient(left, #f8f8f8 0%, #eaeaea 100%);
  background: linear-gradient(left, #f8f8f8 0%, #eaeaea 100%);
}
.jqx-scrollbar-state-normal-light {
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
}
.jqx-scrollbar-thumb-state-normal-light,
.jqx-scrollbar-thumb-state-normal-horizontal-light {
  background: #f5f5f5;
  border-color: #b3b3b3;
}
.jqx-scrollbar-thumb-state-hover-light,
.jqx-scrollbar-thumb-state-hover-horizontal-light {
  background: #e6e6e6;
  border-color: #b3b3b3;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.jqx-progressbar-light {
  background: #f7f7f7 !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.jqx-progressbar-value-light,
.jqx-splitter-collapse-button-horizontal-light {
  background: #1ca8dd;
}
.jqx-splitter-collapse-button-vertical-light,
.jqx-progressbar-value-vertical-light {
  background: #1ca8dd;
}
.jqx-scrollbar-thumb-state-pressed-light,
.jqx-splitter-splitbar-vertical-light,
.jqx-splitter-splitbar-horizontal-light,
.jqx-scrollbar-thumb-state-pressed-horizontal-light,
.jqx-scrollbar-button-state-pressed-light {
  background: #d9d9d9;
  border-color: #b3b3b3;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.jqx-grid-column-sortdescbutton-light,
jqx-grid-column-filterbutton-light,
.jqx-grid-column-sortascbutton-light {
  background-color: transparent;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #ddd;
}
.jqx-menu-vertical-light {
  background: #ffffff;
  filter: none;
}
.jqx-checkbox-check-checked-light {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAcklEQVQY02NgwA/YoJgoEA/Es4DYgJBCJSBeD8SboRinBiYg7kZS2IosyQ/Eakh8LySFq4FYHFlxGRBvBOJYqMRqJMU+yApNkSRAeC0Sux3dfSCTetE0wKyXxOWhMKhTYIr9CAUXyJMzgLgBagBBgDPGAI2LGdNt0T1AAAAAAElFTkSuQmCC');
}
.jqx-checkbox-hover {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.jqx-combobox-content-light {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.25);
}
.jqx-grid-bottomright-light,
.jqx-panel-bottomright-light,
.jqx-listbox-bottomright-light {
  background-color: #f8f8f8;
}
.jqx-window-light,
.jqx-tooltip-light {
  box-shadow: 0 4px 23px 5px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0, 0, 0, 0.15);
}
.jqx-docking-light .jqx-window-light {
  box-shadow: none;
}
.jqx-docking-panel-light .jqx-window-light {
  box-shadow: none;
}
.jqx-radiobutton-light {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  background-repeat: no-repeat;
  background: none;
}
.jqx-radiobutton-light-light,
.jqx-radiobutton-hover-light {
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background-repeat: no-repeat;
}
.jqx-radiobutton-check-checked-light {
  filter: none;
  background: #1ca8dd;
  background-repeat: no-repeat;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.jqx-radiobutton-check-indeterminate-light {
  filter: none;
  background: #999;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.jqx-radiobutton-check-indeterminate-disabled-light {
  filter: none;
  background: #999;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.jqx-slider-track-horizontal-light,
.jqx-slider-track-vertical-light {
  border-color: #ddd;
  background: #f0f0f0;
}
.jqx-slider-button-light {
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
/*applied to a list item when the item is selected.*/
.jqx-listitem-state-hover-light,
.jqx-menu-item-hover-light,
.jqx-tree-item-hover-light,
.jqx-calendar-cell-hover-light,
.jqx-grid-cell-hover-light,
.jqx-menu-vertical-light .jqx-menu-item-top-hover-light,
.jqx-input-popup-light .jqx-fill-state-hover-light,
.jqx-input-popup-light .jqx-fill-state-pressed-light {
  color: #525e61 !important;
  border-color: #e3f5fb;
  text-decoration: none;
  background-color: #f8f9f9;
  background-repeat: repeat-x;
  outline: 0;
  background: #f8f9f9;
  /* Old browsers */
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-position: 0 0;
}
.jqx-scheduler-cell-hover-light {
  border-color: #e3f5fb !important;
  background: #f0f0f0 !important;
}
.jqx-grid-table-dark {
  font-size: 14px;
}
.jqx-listitem-state-selected-light,
.jqx-menu-item-selected-light,
.jqx-tree-item-selected-light,
.jqx-calendar-cell-selected-light,
.jqx-grid-cell-selected-light,
.jqx-menu-vertical-light .jqx-menu-item-top-selected-light,
.jqx-grid-selectionarea-light,
.jqx-input-button-header-light,
.jqx-input-button-innerHeader-light {
  color: #000 !important;
  background-color: #eff2f3;
  *background-color: #eff2f3;
  background-repeat: repeat-x;
  border-color: #e9e9e9 !important;
  background: #eff2f3;
  /* Old browsers */
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.jqx-scheduler-cell-selected-light {
  border-color: #1ca8dd !important;
  background: #1ca8dd !important;
}
.jqx-grid-cell-light .jqx-button-light,
.jqx-grid-cell-light .jqx-button-light.jqx-fill-state-hover-light,
.jqx-grid-cell-light .jqx-button-light.jqx-fill-state-pressed-light {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}
.jqx-popup-light {
  border: 1px solid #ddd;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}
.jqx-grid-column-sortascbutton-light,
.jqx-expander-arrow-bottom-light,
.jqx-window-collapse-button-light,
.jqx-menu-item-arrow-up-light,
.jqx-menu-item-arrow-up-selected-light,
.jqx-menu-item-arrow-top-up-light,
.jqx-icon-arrow-up-light,
.jqx-icon-arrow-up-hover-light,
.jqx-icon-arrow-up-selected-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-widget-light .jqx-grid-group-expand-light,
.jqx-grid-group-expand-light,
.jqx-grid-column-menubutton-light,
.jqx-grid-column-sortdescbutton-light,
.jqx-expander-arrow-top-light,
.jqx-window-collapse-button-collapsed-light,
.jqx-menu-item-arrow-down-light,
.jqx-menu-item-arrow-down-selected-light,
.jqx-menu-item-arrow-down-light,
.jqx-icon-arrow-down-light,
.jqx-icon-arrow-down-hover-light,
.jqx-icon-arrow-down-selected-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-tabs-arrow-left-light,
.jqx-menu-item-arrow-left-selected-light,
.jqx-menu-item-arrow-top-left,
.jqx-icon-arrow-left-light,
.jqx-icon-arrow-down-left-light,
.jqx-icon-arrow-left-selected-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-widget-light .jqx-grid-group-collapse-light,
.jqx-grid-group-collapse-light,
.jqx-tabs-arrow-right-light,
.jqx-menu-item-arrow-right-selected-light,
.jqx-menu-item-arrow-top-right-light,
.jqx-icon-arrow-right-light,
.jqx-icon-arrow-right-hover-light,
.jqx-icon-arrow-right-selected-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-menu-item-arrow-left-selected-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-menu-item-arrow-right-selected-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-input-button-content-light {
  font-size: 10px;
}
.jqx-widget .jqx-grid-column-header {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.jqx-widget .jqx-grid-cell,
.jqx-widget .jqx-grid-column-header,
.jqx-widget .jqx-grid-group-cell {
  border-color: #e9e9e9;
}
.jqx-grid-cell .jqx-combobox-content-light,
.jqx-grid-cell .jqx-input-light {
  border-color: #e9e9e9;
  color: #555555;
  background-color: #ffffff;
  padding: 0.8rem 1rem;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.jqx-combobox-content-light,
.jqx-combobox-light,
.jqx-combobox-state-normal-light {
  border-color: #ddd;
}
.jqx-combobox-content-focus-light,
.jqx-combobox-state-focus-light,
.jqx-fill-state-focus-light,
.jqx-numberinput-focus-light {
  outline: none;
  border-color: #959595;
}
input[type="text"].jqx-input-light,
input[type="password"].jqx-input-light,
input[type="text"].jqx-widget-content-light,
input[type="textarea"].jqx-widget-content-light,
textarea.jqx-input-light {
  font-size: 14px;
  padding: 0px;
  padding-left: 3px;
  padding-right: 3px;
  resize: none;
}
input[type="text"].jqx-input-light:-moz-placeholder,
input[type="text"].jqx-widget-content-light:-moz-placeholder,
input[type="textarea"].jqx-widget-content-light:-moz-placeholder,
textarea.jqx-input-light:-moz-placeholder {
  color: #999999;
}
input[type="text"].jqx-input-light:-webkit-input-placeholder,
input[type="text"].jqx-widget-content-light:-webkit-input-placeholder,
input[type="textarea"].jqx-widget-content-light:-webkit-input-placeholder,
textarea.jqx-input-light:-webkit-input-placeholder {
  color: #999999;
}
input[type="text"].jqx-input-light:-ms-input-placeholder,
input[type="text"].jqx-widget-content-light:-ms-input-placeholder,
input[type="textarea"].jqx-widget-content-light:-ms-input-placeholder,
textarea.jqx-input-light:-ms-input-placeholder {
  color: #999999;
}
.jqx-grid-cell .jqx-combobox-content-light,
.jqx-grid-cell .jqx-input-light {
  border-color: #ddd;
  color: #555555;
  background-color: #ffffff;
  width: 100% !important;
  height: 100% !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.jqx-combobox-content-light,
.jqx-combobox-light,
.jqx-combobox-state-normal-light {
  border-color: #ddd;
}
.jqx-combobox-content-focus-light,
.jqx-combobox-state-focus-light,
.jqx-fill-state-focus-light,
.jqx-numberinput-focus-light {
  outline: none;
  border-color: #1ca8dd;
}
.jqx-popup-light.jqx-fill-state-focus-light {
  outline: none;
  border-color: #ddd !important;
}
.jqx-datetimeinput-content,
.jqx-datetimeinput-container {
  overflow: visible !important;
}
.jqx-slider-rangebar-light {
  border-color: #1ca8dd;
  background: #1ca8dd;
}
.jqx-grid-cell-light.jqx-grid-cell-selected-light > .jqx-grid-group-expand-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-grid-cell-light.jqx-grid-cell-selected-light > .jqx-grid-group-collapse-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-grid-cell-light.jqx-grid-cell-selected-light > .jqx-grid-group-collapse-rtl-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-grid-cell-light.jqx-grid-cell-selected-light > .jqx-grid-group-expand-rtl-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-tabs-title-selected-top-light,
.jqx-tabs-selection-tracker-top-light {
  border-color: #ddd;
  border-bottom: 1px solid #fff;
  filter: none;
  background: #fff;
  color: #333 !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.jqx-tabs-title-selected-bottom-light,
.jqx-tabs-selection-tracker-bottom-light {
  border-color: #ddd;
  border-top: 1px solid #fff;
  filter: none;
  background: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.jqx-tabs-title-hover-top-light,
.jqx-tabs-title-hover-bottom-light,
.jqx-tabs-header-light {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.jqx-window-collapse-button-light {
  margin-top: 0px;
}
.jqx-window-collapse-button-collapsed-light {
  margin-top: 0px;
}
.jqx-layout-light {
  background-color: #cccccc;
}
.jqx-kanban-column-header-collapsed-light {
  background: -moz-linear-gradient(0deg, #f8f8f8 0%, #eaeaea 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #f8f8f8), color-stop(100%, #eaeaea));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(0deg, #f8f8f8 0%, #eaeaea 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(0deg, #f8f8f8 0%, #eaeaea 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(0deg, #f8f8f8 0%, #eaeaea 100%);
  /* ie10+ */
  background: linear-gradient(90deg, #f8f8f8 0%, #eaeaea 100%);
  /* w3c */
}
.jqx-calendar-light td {
  font-size: 14px;
}
.jqx-grid-cell-light.jqx-grid-cell-selected-light > .jqx-grid-group-expand-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-grid-cell-light.jqx-grid-cell-selected-light > .jqx-grid-group-collapse-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-grid-cell-light.jqx-grid-cell-selected-light > .jqx-grid-group-collapse-rtl-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-grid-cell-light.jqx-grid-cell-selected-light > .jqx-grid-group-expand-rtl-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-grid-group-collapse-light {
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.jqx-grid-group-collapse-rtl-light {
  padding-right: 0px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.jqx-grid-group-expand-light,
.jqx-grid-group-expand-rtl-light {
  padding-right: 0px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.jqx-icon-arrow-first-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-icon-arrow-last-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-icon-arrow-first-hover-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-icon-arrow-last-hover-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-icon-arrow-first-selected-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-icon-arrow-last-selected-light {
  background-repeat: no-repeat;
  background-position: center;
}
.jqx-tree-grid-collapse-button-light {
  margin-top: 1px;
}
.jqx-primary .jqx-icon-arrow-right-hover-light,
.jqx-warning .jqx-icon-arrow-right-hover-light,
.jqx-danger .jqx-icon-arrow-right-hover-light,
.jqx-success .jqx-icon-arrow-right-hover-light,
.jqx-info .jqx-icon-arrow-right-hover-light {
  box-sizing: border-box;
}
.header-checkbox {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #ddd;
  padding: 0 !important;
  position: absolute;
  z-index: 4;
  margin-left: 1.4rem;
}
.header-checkbox.checked {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAcklEQVQY02NgwA/YoJgoEA/Es4DYgJBCJSBeD8SboRinBiYg7kZS2IosyQ/Eakh8LySFq4FYHFlxGRBvBOJYqMRqJMU+yApNkSRAeC0Sux3dfSCTetE0wKyXxOWhMKhTYIr9CAUXyJMzgLgBagBBgDPGAI2LGdNt0T1AAAAAAElFTkSuQmCC);
  background-position: center;
  float: left;
  outline: none;
  background-repeat: no-repeat;
}
#creativeGrid .product-image.empty {
  width: 120px;
  background-color: white;
}
#creativeGrid .product-image img {
  width: 30px;
}
#creativeGrid .SampleModal .modal-holder {
  width: 75%;
}
#creativeGrid .Sample-Modal-Container {
  position: relative;
}
#creativeGrid .Sample-Modal-Container .Sample-SimpleModal {
  position: absolute;
  z-index: 2000;
  width: auto;
  bottom: 90px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 1%;
  left: 25%;
  box-shadow: 2px 2px 5px #555;
  display: none;
}
#creativeGrid .Sample-Modal-Container .Sample-SimpleModal.movesample {
  left: 58%;
}
#creativeGrid .Sample-Modal-Container .Sample-SimpleModal .field-data {
  margin: 10px 0 0;
  text-align: center;
  border-top: 1px solid #999;
  padding-top: 10px;
}
#creativeGrid .Sample-Modal-Container .Sample-SimpleModal h3 {
  color: #555;
}
#creativeGrid .Sample-Modal-Container .Sample-SimpleModal .cancel {
  text-decoration: underline;
  cursor: pointer;
}
#creativeGrid .Sample-Modal-Container .Sample-SimpleModal .sample-btn {
  word-wrap: break-word;
  background: #555;
  text-decoration: none;
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
  font-size: 13px;
  display: inline-block;
  font-size: 15px;
  text-align: center;
  border-radius: 10px;
  color: #fff;
}
#creativeGrid .Sample-Modal-Container .Sample-SimpleModal .sample-btn.generate-card {
  width: 120px;
}
#creativeGrid .Sample-Modal-Container .Sample-SimpleModal input {
  width: 50px;
}
#creativeGrid .Sample-Modal-Container .Sample-SimpleModal.open {
  display: block;
}
#creativeGrid .Sample-Modal-Container .Sample-SimpleModal select {
  padding: 5px;
}
.modal-container .modal-content {
  padding: 1rem 3rem;
}
.modal-container .modal-holder {
  height: auto !important;
  max-height: inherit;
  width: 462px;
  border-radius: 16px;
}
.modal-container .modal-holder .modal-header {
  border-radius: 16px 16px 0 0;
  text-align: left;
  padding: 1.5rem 3rem;
}
.modal-container .modal-holder .modal-footer {
  margin: 3.3rem 0 1rem;
  text-align: center;
}
.modal-container .modal-holder .modal-footer [type="button"],
.modal-container .modal-holder .modal-footer [type="submit"] {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 1.4rem;
  text-transform: uppercase;
  cursor: pointer;
  padding: 1rem 1.2rem;
  margin-left: 1.5rem;
}
.product-pictures .thumbnail-holder {
  width: 22%;
  float: left;
  max-height: 400px;
  overflow-y: auto;
}
.product-pictures .thumbnail-holder ul {
  margin: 0;
}
.product-pictures .thumbnail-holder ul li {
  margin-bottom: 1rem;
}
.product-pictures .thumbnail-holder ul li .thumbnail-image {
  width: 80px;
}
.product-pictures .thumbnail-holder ul li img {
  width: 90%;
  height: auto;
}
.product-pictures .thumbnail-holder ul li .image-checkbox {
  position: relative;
  margin-left: -12px;
  float: right;
}
.product-pictures .main-image {
  display: inline-block;
  margin: 0;
  position: relative;
  width: 78%;
}
.product-pictures .main-image .image-container {
  text-align: center;
}
.product-pictures .main-image .image-container img {
  width: 90%;
}
.product-pictures .main-image .image-container .image-checkbox {
  float: right;
}
.product-pictures .main-image .images-action {
  text-align: center;
}
.product-pictures .main-image .comment-section {
  width: 100%;
}
.product-pictures .main-image .comment-section .texrarea-fld {
  height: 60px !important;
}
.footer .footer-content {
  padding: 1rem 2rem;
  display: inline-block;
}
.footer .footer-content button {
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.footer .footer-content .footer-btn {
  margin-right: 1.5rem;
}
.footer .footer-row-details {
  display: inline-block;
  position: relative;
  margin-top: 18px;
  margin-left: 15px;
}
.footer .footer-row-details span {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #166A8F;
}
.footer .footer-row-details .row-count {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.vertical-image-holder-for-creative .rowHolder .thumbnail-holder {
  display: inline-block;
}
.vertical-image-holder-for-creative .rowHolder .thumbnail-holder ul {
  margin: 0;
  height: 200px;
}
.vertical-image-holder-for-creative .rowHolder .thumbnail-holder ul li {
  cursor: pointer;
  max-height: 60px;
  margin-bottom: 10px;
}
.vertical-image-holder-for-creative .rowHolder .thumbnail-holder ul li img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border: 1px solid #cccccc;
}

.vertical-image-holder-for-creative .rowHolder .main-image {
  display: inline-block;
  width: 70%;
}

.vertical-image-holder-for-creative .rowHolder .main-image .image-container {
  text-align: center;
}
.vertical-image-holder-for-creative .rowHolder .main-image .image-container img {
  width: 90%;
  height: 230px;
  max-height: 230px;
  object-fit: contain;
}
.vertical-image-holder-for-creative .rowHolder .main-image .image-container .image-checkbox {
  float: right;
}

.creative-image-popup .images-action .gray-button{
  margin-right: 5px !important;
  margin-top: 10px;
}
