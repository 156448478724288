/* Adding less formate after changing .css file to.less */
.filter-actions {
    clear: both;
    #apply-filter, #save-filter {
        width: 45%;
        padding: 8px 15px;
        width: 45%;
    }
    #save-filter {
        width: 50%;
        margin-right: 5%;
        background-color: #1aa3b1;
    }
}

/* old code but still in use */
/* written in CSS */
/* if you make changes to below code, please change the block to less */
/* added new css for search filter dropdown - suraj */

.leftDrawer-li {
    white-space: nowrap;
    cursor: pointer;
    padding-left: 10px;
    border-bottom: 1px solid #ccc;
    background: #ffff;
    height: 25px;
    line-height: 25px;
}

.leftDrawer-ul {
    display: none;
}

.leftDrawer-ul-shown {
    padding: 0 !important;
    margin: 0px;
    background: #ffff;
    overflow: auto;
    height: auto !important;
    max-height: 200px !important;
    position: fixed;
    z-index: 9999;
    width: 150px !important;
}

.leftDrawer-bsul-shown{
    padding: 0 !important;
    margin: 0px;
    background: #ffff;
    overflow: auto;
    height: auto !important;
    max-height: 120px !important;
    position: initial;
    z-index: 9999;
    width: 120px !important;
}

.leftDrawer-ul-input {
    width: 100%;
    height: 30px;
}

.leftDrawer-li:hover {
    background: #f2f2f2;
}

.showSelectedFilterCF {
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.scrollFixDropdown {
    overflow-y: inherit !important;
}

ul.menuList li {
    transition-duration: 1s;
    transition-property: margin, padding;
}


// setting padding for left icons based on screen height
// TODO - important should be removed
// JIRA - SP-2425
.left-nav-content {
    .main-nav,.footer-nav{
        ul.menuList,ul.footer-menuList{
            li {
                @media only screen and (max-height: 760px)  {
                    padding-bottom: 5px !important;
                    padding-top: 5px !important;
                }
                @media only screen and (max-height: 700px) {
                    padding-bottom: 0 !important;
                    padding-top: 5px !important;
                }
                @media only screen and (max-height: 596px) {
                    padding: 1.1rem !important;
                    span {
                        display: none !important;
                    }
                }
                padding: 1.1rem;
            }
        }
    }
}


.right-content-container {
    .drawer {
        .search-refine-holder {
            .drawer-content {
                .custom-search {
                    @media only screen and (max-height: 760px) {
                        height: 20%;
                    }
                    ul.form-control {
                        bottom: 100%;
                        position: absolute;
                    }
                }
            }
        }
    }
}

@media only screen and (max-height: 700px) {
    .right-content-container .drawer .search-refine-holder .drawer-content .custom-filter {
        height: calc(100% - 65%) !important;
    }

    .left-nav-content .menuList {
        margin: 0px;
    }
    .left-nav-content .main-nav .logo, .left-nav-content .footer-nav .logo {
        padding-bottom: 5px;
    }

    .left-nav-content .main-nav ul.menuList li:last-child a, .left-nav-content .footer-nav ul.menuList li:last-child a, .left-nav-content .main-nav ul.footer-menuList li:last-child a, .left-nav-content .footer-nav ul.footer-menuList li:last-child a {
        margin-bottom: 5px;
    }
}

@media only screen and (max-height: 550px) {
    .menuList li span {
        display: none !important;
    }
    .left-nav-content .main-nav ul.menuList li, .left-nav-content .footer-nav ul.menuList li, .left-nav-content .main-nav ul.footer-menuList li, .left-nav-content .footer-nav ul.footer-menuList li {
        padding-bottom: 5px !important;
    }
    .search-refine-holder.product {
        font-size: 12px !important;
    }

    .right-content-container .drawer .search-refine-holder .drawer-content .custom-filter {
        height: calc(100% - 70%) !important;
    }

    .search-refine-holder.product h4 {
        font-size: 12px !important  ;
    }

    .search-refine-holder.product label {
        font-size: 12px !important  ;
    }

    .search-refine-holder.product .drawer-title {
        font-size: 14px !important  ;
    }
    .right-content-container .drawer .search-refine-holder .drawer-content .filter-section {
        margin-bottom: 0px !important;
    }

    .right-content-container .drawer .search-refine-holder .drawer-content .custom-filter {
        margin-bottom: 0px !important;
        margin-top: 5px !important;
    }

    .right-content-container .drawer .search-refine-holder .drawer-content .search-footer {
        position: relative;
    }

}

/* accomplishment scroll */
.scrollFixAdmin {
    overflow-y: auto;
    height: 90%;
}

.left-container {
    .search-footer {
        height: auto !important;

        #save-filter:disabled {
            background-color: #9FD8DE;
        }

        #apply-filter:disabled {
            background-color: #F9BAA5;
        }
    }

    .custom-search {
        border-top: 3px solid #1aa3b1 !important;

        .custom-filter-content {
            .row:nth-child(1) {
                .col-6:nth-child(1):after {
                    content: '=';
                    position: absolute;
                    bottom: 9px;
                    right: 2px;
                    font-size: 20px;
                    font-weight: bold;
                }
            }

            .search-text {
                display: inherit;
                position: relative;
                left: -10px;
                font-size: 12px;
                width: 200px;
                letter-spacing: -1px;
            }

            #apply-search{
                padding: 7px 25px;
                text-transform: uppercase;
                background-color: #f15a29;
                color: #fff;
                border: none;
                border-radius: 4px;
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
                letter-spacing: 1px;
                cursor: pointer;

                &:disabled {
                    background-color: #F9BAA5;
                }
            }
        }

        .keep-search-value {
            display: inline-block;
            margin-bottom: 2rem;
            float: left;
        }
        .keep-search-value .form-check-label {
            font-family: 'Roboto', sans-serif;
            font-weight: 300;
            font-size: 14px;
        }
        .keep-search-value .form-check-label span.from-checkbox {
            background: url('../common/icons/Check box.svg') no-repeat;
            content: '';
            display: inline-block;
            height: 14px;
            width: 15px;
            position: relative;
            left: -1rem;
            top: .2rem !important;
        }
        .keep-search-value .form-check-label .form-control-check:checked + span.from-checkbox {
            background: url('../common/icons/selected.svg') no-repeat;
        }
        .keep-search-value .form-control-check {
            position: relative;
            left: -10rem;
            visibility: hidden;
        }
    }
}
