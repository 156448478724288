.notification-content {
    border: 1px solid #666;
    border-radius: 15px;
    background-color: #fa7183;
    position: fixed;
    left: 40%;
    top: 10%;
    z-index: 4;
    padding: 15px;
    font-size: 12px;   
    text-align: center;
}

.notification-content button {
    border-radius: 15px;
    background-color: #fa7183;
    margin-top: 5px;
    padding: 5px
}