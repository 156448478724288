@import url(../../node_modules/ag-grid-community/dist/styles/ag-grid.css);
@import url(../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css);
@import url(../product2/ag-theme-balham.css);

.right-content {
    margin-left: 100px;
    transition: all .3s ease;
}

.sidenav {
    height: 100%;
    width: 100px;
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    background-color: 'white';
    overflow-x: hidden;
    padding-top: 20px;
}

.pageHeader {
    height:64px;
}

.modal-container video{
    width: 100%;
}

.sweet-loading {
    width: calc(100% - 115px);
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 115px;
    right: 0;
    margin: auto;
    background-color: rgba(255,255,255,0.5);
}

.css-jxiqlq {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: relative;
}

.dashboard-fix {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
}

.sidenavFix {
    z-index: 1 !important;
}

.notificationPosFix2 {
    top: 15px;
    left: 460px;
    position: fixed !important;
}

.notificationPosFixProd2 {
    top: 15px;
    left: 690px;
    position: fixed !important;
}

.mobile-nav-container {
    display: none;
}

#myGrid {
    height: calc(100% - 155px);
}

/* RESPONSIVE CSS */

@media screen and (max-width: 600px) {
    .login-container .holder {
        margin: auto !important;
        width: 90% !important;
    }

    .login-form-holder .login-form-container {
        padding: 10px !important;
    }

    .sidenav {
        display: none;
    }

    .mobile-nav-container {
        display: flex;
        height: 80px;
        width: 100%;
        justify-content: space-evenly;
        border-top: 1px solid #6f429e;
    }

    .mobile-items {
        width: 18%;
        font-size: 16px;
        text-align: center;
        color: #6b3b9b;
        cursor: pointer;
    }

    .mobile-items a{
        text-decoration: none;
        color: #6b3b9b;
        cursor: pointer;
    }

    .mobile-items i {
        background-size: 40px;
        height: 50px;
        background-position: center;
    }

    .mobile-items .fa-barcode {
        font-size: 50px;
    }

    .right-content-container.drawer-open .drawer {
        background-color: #fff;
    }

    .alerts-breakdown,
    .dash-board-right,
    .notification-holder,
    .view-recent-messages,
    .total-row-count,
    .record-delete-holder:nth-child(2),
    .expand-screen-parent,
    .help-bugs-page-header,
    .filter-strip-holder .filter-strip,
    .footer {
        display: none;
    }

    .dash-board-container .dash-board .dash-board-left {
        width: 90%;
        float: none;
        height: 95%;
    }

    .dash-board-container {
        height: 100%;
    }

    .dash-board-container .dash-board .dash-board-left .priority-alerts {
        width: 100%;
    }

    .right-content {
        margin-left: 0px;
        height: 100%;
    }

    #rightContent {
        height: 100%;
    }

    .content-wrapper header .page-header-logo {
        display: inline;
    }

    /* sets the modal inside the screen on the grid */
    .ag-modal-holder {
        width: 90% !important;
    }

    /* confirm popup on grid */
    .modal-container.confirm .modal-holder {
        width: 80%;
    }

    .right-content .right-content-container .drawer.presentation-drawer {
        z-index: 0;
    }

    .right-content-container .drawer {
        z-index: 1;
        background-color: #fff;
    }

    .right-content-container.drawer-open .drawer,
    .right-content .right-content-container .drawer.presentation-drawer-open {
        width: calc(100% - 15px);
    }

    .mobile-scan-actions .modal-container .modal-holder {
        width: 100%;
    }

    .mobile-scan-actions .modal-container.open {
        background: transparent;
        top: -50px;
        overflow: hidden;
        width: 100%;
        height: 90%;
    }

    .mobile-scan-actions .modal-container .modal-holder .modal-header {
        border-radius: 0px;
    }

    .mobile-scan-actions {
        width: 100%;
        height: 85%;
    }

    .mobile-scan-actions .close {
        display: none;
    }

    .mobile-sign-out {
        position: fixed;
        top: 20px;
        right: 30px;
        font-size: 20px;
        color: #18b9c2;
        cursor: pointer;
    }

    .right-content-container .pageHeader {
        height: 100px;
    }

    .right-content .right-content-container .content-wrapper {
        height: calc(100% - 100px - 80px);
    }

    #myGrid {
        height: 93%;
    }

    .collapse .navbar-collapse .header-filters {
        display: block;
        width: 100%;
        margin: 0px;
        padding: 0px;
    }

    .content-wrapper header .navbar-collapse {
        margin: 0px;
        float: left;
        background-color: #ecefef;
        width: 100%;
    }

    .collapse .header-filters {
        margin: 0px;
        padding: 0px;
        width: 100%;
        margin-left: 25%;
    }
}
